//create schedule
.fx-container-create-schdule {

  //remove left right padding on mob screens
  .MuiGrid-spacing-xs-2 {
    @media (max-width: 999px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

  }

  .fx-select-grid {
    padding-right: 1rem !important;
    align-items: flex-start;

    @media (max-width: 999px) {
      padding-right: 0 !important;
    }

    .MuiDivider-root {
      margin: 1rem 0 !important;
      background-image: linear-gradient(to left, $devider-border-color 20%, rgba(255, 255, 255, 0) 0%);
      background-position: left;
      background-size: 6px 1.5px;
      background-repeat: repeat-x;
      left: 0;
      width: 100%;
      background-color: transparent;
    }

    .Mui-disabled {
      background-color: $disabled-color;
    }

    .fx-select-grid-description {
      margin-top: 0.2rem;
    }

    .fx-select-grid-link-description {
      text-align: center;
      margin-bottom: 4px;
    }

    .fx-select-grid-label {
      margin-bottom: 4px;
      color: $tab-color;
    }

    .fx-select-grid-value {
      margin-bottom: 1.125px;
      font-weight: 500;
      font-family: $font;
    }

    .fx-link {
      text-align: center;
      color: $theme-color;
      text-decoration: none;
      font-weight: 700;
      font-size: 0.9375rem;
      font-family: $font-inter;
    }
  }

  .fx-select-grid>div {
    background-color: $grey-background;
    padding: 24px;
    border-radius: 6px;
  }

  .fx-select-grid:after {
    position: relative;
    content: "";
    border-top: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-left: 1rem solid $body-background;
    // transform: translateY(-50%);
    top: -50%;
    left: 100%;
  }


  //right block form area
  &-middle-scroll {
    @media (min-width: 600px) {
      padding: 0.5rem 3rem 0 3rem;

      >div {
        // only for large screens
        padding-bottom: 1.5rem
      }
    }

    max-height: 75vh;
    overflow-y: scroll;

    // add padding on first div
    >div>div:first-child {
      @media (max-width: 600px) {
        padding-right: 0;
      }
    }

    // apply bottom padding on mob
    @media (max-width: 600px) {
      >div {
        padding-bottom: 1.5rem;
      }
    }

  }

  &-middle-scroll::-webkit-scrollbar {
    height: 0;
    display: none;
    width: 0 !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &-header{
    h2{
      font-family: $font;
      font-size: $font-size-h2;
      line-height: 2.125rem;
      font-weight: 500;
      color: $label-color-secondary;
    }
  }
  &-left-layout {
    padding: 1.5rem 2.5rem 2.5rem 2.5rem;
    border: 2px solid #ECF0F2;
    border-radius: 0.625rem;
    background: $white;
    max-height: fit-content;


    .fx-schedule-menu {
      .fx-schedule-tab-title p {
        color: $disabled-gray-color;
        font-weight: 600;
        text-transform: capitalize;
        font-size: $font-size-h3;
      }

      .fx-schedule-tab-narration h6 {
        color: $disabled-gray-color;
        text-transform: none;
      }

      .fx-schedule-tab-view {
        .fx-selected {
          border-bottom: none;
        }

      }

      .fx-schedule-tab-container-grid {
        border-bottom: 2px solid #ECF0F2;

        &.fx-selected {
          .fx-schedule-tab-title p {
            color: $theme-color;
          }

          .fx-schedule-tab-narration h6 {
            color: $label-color-4;
          }

          .fx-schedule-tab-view-grid {
            background: $grey-background;
          }
        }

        &.fx-selected:nth-child(2) .fx-schedule-tab-narration {
          padding-right: 1.4rem;
        }

        &.fx-selected:first-child .fx-schedule-tab-narration {
          padding-right: 0;
        }

        &.fx-selected:last-child .fx-schedule-tab-narration {
          padding-right: 0;
        }

        &:not(.fx-schedule-tab-container-grid:last-child) {
          .fx-schedule-tab-view-grid {
            margin-right: 0.7rem;
          }
        }

        &:first-child {
          border-bottom-left-radius: 0 !important;
        }

        &:last-child {
          border-bottom-right-radius: 0 !important;
        }

        .fx-schedule-tab-view-grid {
          height: 100%;
          border: 2px solid #ECF0F2;
          border-bottom: none;
          border-top-left-radius: 0.625rem;
          border-top-right-radius: 0.625rem;
          button{
            opacity: 1 !important;
          }
        }


        &.fx-not-selected {
          .fx-schedule-left-menu-left-column svg path {
            stroke: $disabled-gray-color;
          }
          border-right: none;
          background: $white;
        }

        &.fx-selected:first-child {
          .fx-schedule-tab-view-grid {
            margin-right: 0;
          }
        }

        &.fx-selected:first-child + .fx-not-selected {
          .fx-schedule-tab-view-grid {
            margin-left: 0.7rem;
          }
        }

        &.fx-selected:nth-child(2) {
          .fx-schedule-tab-view-grid {
            margin-right: 0;
          }
        }

        &.fx-selected:nth-child(2) + .fx-not-selected {
          .fx-schedule-tab-view-grid {
            margin-left: 0.7rem;
          }
        }
      }

      padding-bottom: 0;
    }

    &-content {
      background: $grey-background;
      border: 2px solid #ECF0F2;
      border-bottom-left-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
      border-top: none;
      padding: 2.8rem;

      > div {
        padding-bottom: 1.5rem
      }

    }
  }

  &-right-layout {
    .fx-container-create-schdule-summery {
      position: fixed;
      width: inherit;
      margin-right: 4rem;

      @media (max-width: 999px) {
        margin-right: 1rem;
      }

      //calculation is based on max-with of 'fx-passport-content-main > div' , which is 1440px
      //and mui grid sizes
      @media (min-width: 600px) {
        //1440px * 41.666667%
        max-width: 600px;
      }
      @media (min-width: 900px) {
        //1440px * 33.333333%
        max-width: 480px;
      }
    }
  }

  //using this in mob check deposit
  &-gen {
    padding-top: 1.5rem;

    @media (min-width: 999px) {
      >div:nth-of-type(odd) {
        // only for large screens
        padding-bottom: 1.5rem;
        padding-right: 1.5rem;
      }
    }

    // add padding on first div
    >div>div:first-child {
      @media (max-width: 999px) {
        padding-right: 0;
      }
    }

    // apply bottom padding on mob
    @media (max-width: 999px) {
      >div>div {
        padding-bottom: 1.5rem;
      }
    }

  }

  //right side summery
  &-summery {
    background: $white;
    border: 2px solid $border-color;
    border-radius: 0.625rem;
    padding: 1.75rem;

    &-title {
      padding-bottom: 1.125rem;
    }

    &-content {
      font-family: $font;
      font-size: 0.875rem;
      color:$label-color-secondary;

      >div {
        align-items: center;
        padding: 0.615rem;

        >div:first-child {
          font-weight: 400;
        }

        >div:last-child {
          font-weight: 600;
          text-align: end;
          overflow-wrap: anywhere;
        }
      }

      >div:nth-of-type(odd) {
        background-color: $grey-background;
        border-radius: 6px;
      }

    }

    h3 {
      font-family: $font;
      font-weight: 500;
      font-size: $font-size-h3;
      color: $label-color-secondary;
      letter-spacing: 0.5px;
    }
  }

  //image container
  .fx-schedule-imagecontainer {
    padding: 1.75rem ;
    //  border: 2px dashed $input-border-color;
    min-height: 20.625rem;
    border-radius: 0.5rem;
    background-color: $white;
    text-align: center;
    margin-top: 1.125rem;

    &-descr {
      margin: 0.75rem 0 0.25rem;
      font-weight: 600;
      font-size: 1.0625rem;
      color:$label-color-secondary;
    }

    &-type {
      font-weight: 400;
      font-size: 0.8125rem;
      color: $devider-border-color;

    }

    &-imageBox {
      border: 1.5px dashed $input-border-color;
      padding: 0.75rem;
    }
  }
}


.fx-label {
  font-family: $font-regular !important;
  font-size: 14px !important;
}

.fx-label-sec {
  font-family: $font-regular !important;
  font-size: 14px !important;
  color: $label-sec;
}


.fx-label-small {
  font-size: 10px !important;
  color: $placeholder-color;
}


.fx-divider {
  height: 2rem;
  width: 0.125rem;
}

.fx-divider:after {
  content: '';
  display: block;
  width: 1px;
  bottom: 0;
  height: 100%;
  background-image: linear-gradient(to top, $devider-border-color 20%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 1px 6px;
  background-repeat: repeat-y;

}

.fx-schedule-image-front-back {
  .MuiToggleButtonGroup-root {
    border-radius: 0.5rem 0 0 0.5rem;
    padding-bottom: 1rem;

    .fx-link {
      color: $vertical-divider-border-color !important;
      background-color: $paper-background !important;

      span {
        font-weight: 500;
        font-size: 0.875rem;
        font-family: $font-inter-medium;
      }
    }

    .fx-link,
    .fx-link-sel {
      line-height: 1rem;
      height: 2.5rem;
      min-width: 1rem !important;
      padding: 0.625rem 0.78125rem !important;
      border: none;

    }

    .fx-link:hover {
      color: $theme-color;
    }

    .fx-link-sel {
      color: $theme-color;
      background-color: $button-lite-background;

      span {
        font-weight: 900;
        font-size: 0.875rem;
        font-family: $font-inter-black;
      }
    }

    .fx-link-sel:hover {
      opacity: 0.7;
    }
  }

  &-container {
    padding: 2rem;
    background-color: $paper-background;
  }
}

.fx-container-info-schdule {
  .fx-select-grid {
    padding: 8px 12px 32px 12px;
  }

  .fx-select-grid>div {
    background-color: $debit-background;
    padding: 24px 30px 24px 30px;
    border-radius: 6px;
  }

  h5 {
    font-family: $font-bold !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    color: $black-color !important;
    padding-bottom: 8px;
  }

  h6 {
    font-family: $font !important;
    font-size: 11px !important;
    font-weight: 400 !important;
    padding-bottom: 16px;
    color: $light-gray-color;
  }
}

.fx-edit-page-bread-crumbs {
  padding: 16px 0 2px 0;
}


//create schedule top block
.fx-schedule-create-tab-container {
  margin: 1rem 0 0.75rem 0 !important;
  border: 1px solid $border-color;
  border-radius: 0.5625rem;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 0.4rem;

  &-button-grid {
    @media (max-width: 900px) {
      padding-left: 8px;
    }
  }

  >button:not(:last-child) {
    margin-right: 0.8rem;
  }

  .MuiTypography-body1 {
    font-weight: 700;
    font-size: $font-size-h4;
    margin-top: 6px;
    display: block;
    padding-right: 16px;
    font-family: $font-inter-bold;
    color: $label-color-secondary;
  }

  .fx-link {
    line-height: 1rem;
    font-weight: 500;
    font-size: 1rem;
    color: $label-color-secondary;
    &:hover {
      color: #0c339c;
    }
  }

  .fx-link,
  .fx-link-sel {
    line-height: 1rem;
    min-width: 1rem !important;
    padding: 0.625rem 0.78125rem !important;

    span {
      font-size: 0.875rem;
      font-family: $font;
    }
  }

  .fx-link-sel {
    color: $white;
    background-color: $theme-color;
  }

  .fx-link-sel:hover {
    opacity: 0.7;
  }

}

//dynamic comp for check remittance template
.fx-dynamic-field-div {
  background: $debit-background;

  >div {
    padding: 1rem;
  }

  hr {
    padding: 1rem;
  }
}

//fx-repeat payment starts here
.fx-repeat-payment {
  &-repeat {
    box-sizing: content-box;

    >div:first-child {
      padding-right: 1rem;
    }
  }

  &-week {
    .fx-button {
      height: 3rem;
      width: 3.5rem;
      border-radius: 0;
    }
  }

  &-year {
    .fx-button {
      height: 3rem;
      width: 2.75rem;
      padding: 0.1rem !important;
      border-radius: 0;
      min-width: 2.75rem;
    }
  }

  &-month {
    .fx-button {
      height: 3rem;
    }
  }
}
//fx-repeat payment ends here

//using in schedule create left side
.fx-schedule-left-menu {
  background: $grey-background;
  border-radius: 8px;
  padding: 1.5rem;
  box-sizing: border-box;
  @media (max-width: 900px) {
    flex-direction: row;
  }
  a {
    text-decoration: none;
    h6{
      color: $label-color-secondary;
      font-weight: 500;
    }
  }

  a>div {
    padding: 1rem;
  }

  &-sel {
    >div {
      background: $white !important;
      /* Card Shadow */
      box-shadow: -0.5625rem 0.5625rem 0.9375rem rgba($label-color-secondary, 0.04) !important;
      border-radius: 8px !important;
    }
  }

  &-left-column {
    svg {
      height: 1.5rem;
      width: 1.5rem;
      color: $theme-color;

      path {
        stroke: $theme-color;
        fill: $white;
      }
    }
  }

  &-right-column {
    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
  &-button {
    @media (max-width: 900px) {
      flex-direction: column;
    }
  }
}

//schedule select menu for small screens
.fx-schedule-mobile-menu {
  background: $grey-background;
  border-radius: 8px;
  padding: 1.5rem;
  box-sizing: border-box;
  margin-bottom: 1.5rem;

  b {
    color: $label-color-secondary;
    font-weight: 500;
    text-transform: none;
  }
  .fx-schedule-mobile-menu-icon-button {
     width: 100%;
    display: flex;
    justify-content: space-between;
    background: $white;
    box-shadow: -0.5625rem 0.5625rem 0.9375rem rgba($label-color-secondary, 0.04);
    border-radius: 0.5rem;
    .fx-schedule-button-icon {
      svg {
        color: $theme-color;

        path {
          stroke: $theme-color;
        }
      }
    }
   }
}

.fx-schedule-mobile-options-menu {
  .MuiMenu-paper {
    margin-top: 0.2rem;
  }
  svg {
    path {
      stroke: $theme-color;
    }
  }

  li {
    text-transform: none !important;
  }
}

.fx-create-schedule-subheading {
  color: $label-color-secondary;
}

.fx-schedule-card-general {
  padding: 0;
  background-color: $grey-background;
  border:none !important;
  box-shadow:none !important;
  @media (max-width: 999px) {
    padding-left: 0.5rem;
    padding-top: 0;
  }

  .fx-card-header {
    h3 {
      font-family: $font-regular;
    }
  }

  .fx-footer {
    .fx-link {
      font-family: $font;
      font-size: 13px;
      font-weight: normal;
      line-height: 1;
      color: #1b4b72;
    }
  }

  .MuiDivider-root {
    margin: 1.25rem 0;
    background-color: rgba($label-sec, 0.1) !important;
    height: 1px !important;
    width: 100%;
  }

}

/* schedule check image selection */
.fx-schedule-check-image {
  svg {
    width: 0.75rem;
    height: 0.75rem;
    color: $label-sec !important;
    path {
      stroke: $label-sec !important;
    }
  }

  .MuiInputBase-root {
    padding: 0.4rem !important;
  }

  .MuiChip-root {
    margin: 0.5rem;
    border-radius: 0.563rem;
    background-color: $border-color;
    padding: 0.1rem 0.2rem 0.1rem 0.1rem;

    .MuiChip-label {
      font-size: 0.9rem;
      font-family: $font !important;
      font-weight: 400;
      color: $label-sec;
    }

  }
}

.fx-mobile-no-display{
  @media (max-width: 600px) {
    display: none;
  }
}

.fx-splitfunding{
  &-general {
    height: 1.75rem;
  }

  &-split {
    padding: 0;
  }

  &-condition{
    padding: 1.75rem 1.5rem;
  }

  &-fields{
     > input{
       height: 3.5rem !important;
     }
  }

  .MuiAccordionDetails-root{
    margin:0;
    padding: 0;
  }
  .MuiAccordionSummary-root.Mui-expanded{
    margin:0;
    padding: 0;
    min-height: 2.25rem !important;
  }
  .MuiAccordionSummary-root{
    margin:0;
    padding: 0;
  }
  .MuiAccordionSummary-content.Mui-expanded{
    margin:0;
    padding: 0;
    margin-bottom: 2rem;
  }

  &-tooltip{
    margin-left: 0 !important;
  }

}

.fx-invoice-details-main-grid{
  overflow: auto;
  max-height: 53vh;
  scrollbar-width: none;
}

.fx-invoice-items-table-headers{
  background-color: $disabled-color;
}

.fx-invoice-details-amount{
  margin-top: 0.15rem !important;
}

.fx-invoice-details{
  &-address{
    height: fit-content;
    margin-top: 1rem;
  }

  &-amounts-table{
    width: inherit;
    margin-left: 1.7rem;
    margin-top: 0.7rem;
  }
}

.fx-add-invoice-item-link{
  color: $theme-color;
}

.fx-collect-card-advanced-options{
  width: inherit !important;

  &-checkbox {
    .MuiSvgIcon-root {
      height: 1.9rem !important;
      width: 1.9rem !important;
    }
  
    .MuiTypography-root {
      font-size: 1rem !important;
    }
  }

}

.fx-additional-card-info-accordian{

  width: inherit;


  &-header-section{
    background-color: $disabled-color;
    border-radius: 1rem;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    margin: 0 !important;
  }

  .MuiAccordionSummary-content.Mui-expanded{
    margin: 0 !important;
  }
}

.fx-advanced-options-card-collect{
  overflow: auto;
  max-height: 83vh;
  scrollbar-width: none;
}

.fx-data-saved-label{
  display: flex;
  gap: 0.5rem;
  border: 0.0625rem solid $border-color;
  border-radius: 0.1875rem;
  padding: 0.25rem 0.625rem 0.25rem 0.5rem;
  width: fit-content !important;

  &-icon{
    height: 1.2rem !important;
  }
}

.fx-check-note{
  padding-left: 2rem;
}

.fx-container-tax-wire-checkbox{
  padding: 1rem 1.25rem !important;
}

.fx-atm-locator{
  svg{
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
}

.fx-transaction-modal-text {
  color: $text-color-secondary;
  font-weight: 500;
}