/* fx tab */
.fx-tab {
  font-size: 1rem !important;
  z-index: 1101;

  .MuiPaper-root {
    flex-grow: 1;
    box-shadow: none;
    background-color: transparent;
  }

  .MuiTabs-indicator {
    background-color: $theme-color;
    height: 0;
    // border: solid 2px $theme-color;
    // transition: none!important;
    border: none;
  }

  @media (min-width: 600px) {
    .MuiTabs-indicator:before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      bottom: -6px;
      left: 50%;
      transform: translateX(-8px) rotate(-45deg);
      border-radius: 0 0 0 0.25rem;
      background-color: $body-background;
      box-shadow: -0.125rem 0.125rem 0.1875rem -0.0625rem rgba(0, 0, 0, 0.08);
    }
  }

  .Mui-selected {
    color: $theme-color !important;
  ;
  }

  .MuiTab-root {
    text-transform: inherit;
    opacity: 1;
    color: $label-color-secondary;
    min-width: 0 !important;
    padding: 6px 18px !important;
    font-size: 13px;
    font-family: $font-inter;
    font-weight: 600;

  }

  div {
    overflow: visible !important; //this is for showing carret
  }

  .MuiTabs-fixed:before {
    position: absolute;
    content: "";
    width: 101vw;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    box-shadow: 0 0.25rem 0.625rem 0 $tab-box-shadow;

    @media (max-width: 600px) {
      width: 100vw;
    }
  }
}

/* fx tab ends here */



.fx-breadcrumb {
  font-family: $font-header;
  font-size: 20px;
  font-weight: 600;
  color: $input-value-color;
}


.fx-breadcrumbs {
  margin-bottom: 2rem !important;

  a,
  p {
    font-family: $font-inter-medium;
    font-weight: 500;
    color: $label-color-secondary;
    font-size: 0.875rem !important;
    line-height: 0.875rem;
  }

  a {
    text-decoration: none;
  }

  li {
    line-height: 0.875rem;
  }
}

.fx-div-top-breaker {
  margin: 0.7rem 0 0 0 !important;
  background-image: linear-gradient(to left, $devider-border-color 20%, rgba(255, 255, 255, 0) 0%);
  background-position: left;
  background-size: 6px 1.5px;
  background-repeat: repeat-x;
  width: 100vw;
  background-color: transparent;
}