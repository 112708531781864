/* authorised-user card */
.fx-authorised-user-card {
  svg {
    width: 0.75rem;
    height: 0.75rem;
  }

  svg.MuiChip-deleteIcon {
    color: $menu-sel-color;
  }

  .MuiInputBase-root {
    padding: 0.4rem !important;
  }

  .MuiChip-root {
    margin: 0.5rem;
    border-radius: 4px;
    background-color: $label-color;
    padding: 0.1rem 0.4rem 0.1rem 0.1rem;

    .MuiChip-label {
      font-size: $font-size-h5;
      font-family: $font-inter !important;
      font-weight: 600;
      color: $button-secondary-background-color;
      text-transform: uppercase;
    }

  }
}

.fx-add-user-address-container {
  border: 2px solid $border-color;
  border-radius: 0.625rem;
  padding: 1.75rem;
}

/* change password styles start */
.fx-passowrd-lastChanged-card ,.fx-passowrd-lastChanged-card .MuiTypography-body1 {
  font-family: $font !important;
  color: $label-color-secondary;
  padding-left: 0.3rem;
  padding-top: 1.5rem;
  font-weight: 400;
  font-size: 0.875rem;
}

.fx-passowrd-lastChanged-card-value {
  padding-left: 20px;
  color: $label-color-secondary;
  font-size: 0.875rem;
}
/* change password styles end */

/* Autocomplete styles */
.fx-autosearch-height {
  .MuiOutlinedInput-input {
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }
}
/* Autocomplete styles for chips*/
.fx-contact-user-card{
  svg {
    width: 1rem;
    height: 1rem;
    color: $white !important; 
  }
  .MuiOutlinedInput-root{
    padding: 0.4rem;
  }
  .MuiOutlinedInput-input {
   padding: 0 !important;
   width: 2rem;
   color: $white;
  }

  .MuiChip-root {
    margin: 0.1rem;
    border-radius: 0.3rem;
    background-color: $label-color;
    padding: 0;
    margin-top: 0.5rem;
    height: 2.5rem;
    margin-bottom: 1.2rem;
  
    .MuiChip-label {
      font-size: 0.9rem;
      font-family: $font-inter !important;
      font-weight: 600;
      color: $button-secondary-background-color;
      text-transform: uppercase;
    }
}
.fx-multiple-select-dropdown{
  .MuiChip-root {
    height: 1.7rem;
    margin: 0rem 0rem 0rem 0.7rem;
  }

  .fx-limit-tag-text{
    margin-left: 0.7rem;
  } 

  .MuiOutlinedInput-input{
    padding: 0rem  0.8125rem !important;
  }

  .MuiIconButton-root{
    transition: all 0.3s ease;
  }

  .MuiAutocomplete-endAdornment{
    padding-right: 0.6rem;
  } 
}
}