/*default layout specific scss */

body {
  font-family: $font;
  background-color: $body-background;
  font-size: 1rem;
  letter-spacing: 0.5px;
}
body:has(.fx-form-grid){
  background-color: $white;
}

body,
div,
span,
button,
input,
label,
table,
th,
td,
p,
select,
option,
ul,
li{
  font-family: $font ;
  font-size: 1rem;
  letter-spacing: normal;
}

input[type=date] {
  text-transform: uppercase;
}