
/* account details styles start */
.fx-header-status {
  height: auto;
  margin-left: 24px;
  // color: $status-green;
  // border: solid 0.5px $status-green;
  display: inline-block;
  padding: 0 2px;
  border-radius: 0.375rem;
}

.account-configuration-card-label {
  width: 101px !important;
  font-family: $font-regular !important;
  color: $input-value-color;

}

.account-configuration-card {
  margin-bottom: 10px;
  padding: 8px 16px 0 16px;

  .MuiButton-root:hover {
    background: transparent;
  }
}


.realisation-label {
  padding: 5px;
  margin-top: 5px !important;
  font-family: $font-regular !important;
  color: $input-value-color;
  margin-left: -5px !important;
}

.realisation-value {
  margin-top: 13px !important;
  font-family: $font-regular !important;
  color: $input-value-color;
  margin-left: 12px !important;

}

.realisation-divider {
  border-bottom: 1px solid #f3f3f3;
}

.account-settlement-button {
  background-color: $theme-color !important;
  color: $paper-background;
  height:40px;
  top:15px;

  svg {
    height: 18px;
    width: 18px;
  }
}

.fx-card-body-current-balance {
  border-radius: 0.375rem;
  font-family: $font;
  padding: 3px;

  h5 {
    font-size: 16px;
    color: $input-value-color;
  }

  .balance-amount {
    font-size: 30px;
    color: $input-value-color;
    font-family: $font-nunito !important;
  }
}

.fx-card-header-border {
  border-bottom: none !important;
}

.fx-account-current-balance-label {
  font-size: 18px !important;
  color: $input-value-color;
  font-family: $font-regular !important;
}

.fx-current-balance-card {
  font-size: 12px !important;
  font-family: $font-regular !important;
}
/* account details styles end */

// top buttons action area in list
.fx-action-block {
  @media (max-width: 600px) {
    &-divider {
      display: none;
    }
  }
}

.current-balance-amount {
  font-size: 35px !important;
  font-family: $font !important;
  color: $input-value-color;
}

.fx-current-balance-card-label {
  tr {
    td {
      font-family: $font-regular !important;
    }
  }
}

.account-config-auto-sweep {
  font-family: $font-regular;
  font-size: 14px;
}

.nacha-lablel-arrow {
  margin-left: 24px;
  width: 20px;
  height: 20px;
  margin-bottom: -15px;
}

//Mobile view
.fx-mobile-button-view {
  width: 40px !important;
  height: 40px !important;
  margin-top: 13px !important;

  svg {
    stroke: none !important;
    fill: $white !important;
  }
}


/* accounts info card ends here */
.fx-accounts-details-table {
  width: 100%;
  padding: 10px 0;
  background-color: $grey-table-background !important;

  tr th {
    text-align: left;
    font-family: $font-regular !important;
    color: $label-sec;
    font-size: 12px !important;
  }

  tr td {
    text-align: left;
    font-family: $font-regular !important;
    font-size: 14px !important;
    color: $input-value-color !important;
  }

  tr td p {
    text-align: left;
    font-family: $font-regular !important;
    font-size: 14px;
  }

  tr td:nth-of-type(1),
  tr th:nth-of-type(1) {
    padding-left: 20px !important;

  }

}

/* quick link styles start */
.fx-icon-quick-link-button {
  width: 57px;
  height: 57px;
  box-shadow: $quickLink-box-shadow;
  background-color: $background-color !important;

  svg {
    width: 22.1px;
    height: 22.1px;
    stroke: $white;
  }
}

.fx-icon-quickLink-svg-fill {
  svg {
    fill: $white;
  }
}

.fx-icon-quick-link-grid {
  .MuiTypography-body1 {
    font-size: 16px;
    font-family: $font-regular;
    color: $theme-color;
  }

  .MuiGrid-item {
    text-align: center;
  }
}

.fx-icon-quickLink-more-svg {
  svg {
    path {
      fill: $white !important;
    }
  }
}

.fx-quick-link-menu {
  margin-top: 55px !important;
  margin-left: -55px !important;
}
/* quick link styles end */

// width fixes for primary filter for approval-queue-list
.fx-approval-primary-filter {
  .fx-primary-filter {
    .MuiTextField-root {
      width: 20rem;
    }
  }
}

.fx-api-guide-icon{
  svg g path{
      stroke: $theme-color !important;
  }
}