.fx-top-header {
  border-bottom: solid 1px $top-area-border-bottom;
  padding: 0 !important;
  position: sticky;
  z-index: 1101;
  top: -1.25rem;
  background-color: $body-background;

  h3 {
    font-family: $font-header;
    font-size: 2.125rem;
    font-weight: 600;
    color: $theme-color-head;
  }
}

.fx-row-icon {
  text-align: center !important;
}

.fx-appbar-top {
  background: transparent !important;
  box-shadow: none !important;

  .MuiToolbar-root {
    button {
      svg {
        color: $theme-color;
      }
    }
  }
}

.fx-info-label-value {
  padding-left: 0.75rem;

  > div:nth-child(1) {
    // white-space: nowrap;
    padding-right: 1.5rem;
    padding-bottom: 0.75rem;
    color: rgba($label-color-secondary, 0.8) !important;
    font-weight: 500;
  }

  div:nth-child(2) {
    word-wrap: break-word;
    white-space: normal;
  }

  div:nth-child(1), div:nth-child(2) {
    font-family: $font;
    font-size: 1.15rem;
    color: $label-color-secondary
  }

  div,
  p {
    font-family: $font;
    font-size: 1.15rem;
    color: $label-color-secondary;
    font-weight: 500;
  }

  p {
    //limit showing too much chars
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
}

.fx-info-label-value-container {
  padding-bottom: 1rem;
  // margin-bottom: 1.5rem!important;

  h5 {
    background-color: $table-passport-th-background;
    border-radius: 0.375rem;
    padding: 0.5rem 0.75rem;
    margin-bottom: 1.25rem;
    color: rgba($label-color-secondary, 0.8);
    font-weight: 500;
    font-size: 1rem;
  }

  p {
    margin-right: 8px;
  }
}
.fx-container-box {
  padding-bottom: 0.5rem;
  margin-bottom: 1.0rem !important;
  background-color: $table-passport-th-background;
  svg{
    margin-left: 1.5rem;
    margin-top: 1.0rem;
  }
  h5 {
    margin-top: 0.5rem;
    border-radius: 0.375rem;
    color: rgba($label-color-secondary, 0.8);
    font-weight: 500;
    font-size:$font-size-h4 ;
  }
  .icon{
    margin-left: 0.5rem;
  }

}
.fx-info-container {
  margin-left: 1rem;
  padding: 1rem !important;
  border: 1px solid $border-color;
  background: transparent;

  h3 {
    font-family: $font;
    font-size: $font-size-h3;
    font-weight: 500;
  }

  h6 {
    color: $light-gray-color;
    padding-left: 0.5rem;
    font-size: 0.8125rem;
    font-family: $font-inter;
    font-weight: 400;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

@media only screen and (max-width: 780px) {
  html {
    font-size: 12px;
  }

  .fx-inline-flex {
    .fx-input-edit {
      width: 80%;
    }
  }

  .fx-table-layout-auto {
    table-layout: auto !important;

    th {
      white-space: nowrap;
    }
  }

  .fx-appbar-top-passport {
    padding: 0 !important;
  }

  .fx-main-grid {
    padding: 0 !important;
    margin: 0 0.5rem;
  }

  .fx-appbar-top-passport .MuiToolbar-gutters {
    padding: 0 0.5rem !important;
  }

  html body {
    background-color: $body-background;
  }

  .fx-container {
    .fx-container-left {
      padding-right: 0 !important;
      max-width: 100% !important;
      flex-basis: 100% !important;
    }
  }

  .fx-logo-passport-top {
    width: 3.33rem;
    height: 3.33rem;
    margin: 0 0.67rem;
  }

  .fx-table-generic {
    table-layout: auto !important;
  }

  .fx-card-header {
    white-space: nowrap;
  }

  .fx-home-top-buttons {
    button {
      margin-right: 0.67rem;
    }
  }

  .fx-breadcrumbs {
    margin: 1.33rem 0 0 2rem;
  }

  .fx-schedule-create-tab-container {
    > div {
      padding: 0.33rem 0 0.67rem 0.33rem;
    }

    p {
      margin: 0 0 0.67rem 0.67rem;
    }

    .fx-link-sel:before {
      // bottom:14px;
    }
  }
}

@media only screen and (max-width: 1280px) and (min-width: 780px) {
  html {
    font-size: 12px;
  }

  .fx-container .fx-container-left {
    padding-right: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;

  }

  .fx-main-grid {
    padding: 0 !important;
    // margin:1rem 2rem;
  }

  .fx-appbar-top-passport .MuiToolbar-gutters {
    padding: 0 2rem !important;
  }

}

.fx-currency {
  font-family: $font-nunito;
  font-size: 1rem;

  &-value {
    font-size: 1.5rem;
  }
}

.fx-currency * {
  font-family: $font-nunito !important;
  font-weight: 500;
}

.fx-digest-grid {
  .fx-currency {
    * {
      font-weight: 500 !important;
      font-size: 1.5rem;
    }

    &-value {
      font-size: $font-size-lg !important;
    }
  }
  

}

.fx-info-label-value {
  .fx-currency {
    font-size: 1rem !important;

    &-value {
      font-size: 1.5rem !important;
    }
  }
}

.fx-transaction-summery-currency {
  .fx-currency {
    font-size: 1.5rem !important;

    &-value {
      font-size: 2rem !important;
    }
  }
}

.fx-account-summary-card-center {
  .fx-currency {
    font-size: 1.75rem !important;
    font-weight: 600 !important;

    &-value {
      font-size: 2.5rem !important;
      font-weight: 600 !important;
    }
  }
}

.fx-amount-wrapper {
  @media (max-width: 600px) {
    margin-top: 10px;
  }
}


.fx-schedule-card-summary {
  padding: 0 !important;

  .fx-card-body {
    background-color: $white;
    padding: 0;
    // box-shadow: $card-box-shadow;

    .fx-debit-card-view-action{
      padding-top: 1rem;
    }

    border-radius: $border-card-radius;


    .fx-schedule-card-summary-view {
      background-image: url(../../assets/svg/debit-card-image.svg);
      color: white;
      border-radius: 1rem;
      padding: 2rem;

      &-virtual {
        font: 500 1rem $font;
        color: $white;
        text-transform: uppercase;
        letter-spacing: .5rem;
        position: relative;
        text-shadow: 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.2), 0 -0.125rem 0.1875rem rgba(255, 255, 255, .25);
      }

      &-virtual::after {
        content: "";
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: $white;
        width: 1.625rem;
        height: 0.0625rem;
        right: -2.125rem;
        box-shadow: 0 0.0625rem 0.0625rem rgba(0, 0, 0, .2), 0 -0.125rem 0.1875rem rgba(255, 255, 255, .25);

      }

      &-name {
        font: 600 1.125rem $font;
      }

      &-account {
        font: 400 1.5rem $font;
      }
    }
  }
}

.fx-virtual-card-view img{
  border : $border-card;
  border-radius: 1rem;
  height: 100%;
  width: 100%;
  max-height: 21rem;
}

.fx-virtual-card-detail{

  .fx-login-page-title h3{
    font-size: 2.3rem !important;
    font-weight: 500 !important;
    padding: 0.625rem 0 0 2rem;
  }

  .fx-virtual-card-view{
    height: 27rem;
    padding: 2rem 0;
  }



  .fx-virtual-card-summary-card{
    margin-bottom: 0;
    border : $border-card;
    border-radius: 1rem;
    padding:0;
    height: 21rem;
  }

  .fx-virtual-card-balance{
    padding: 7rem;
  }
}

/* Grid Listing css starts */
.fx-grid-list{
  .fx-padding-top-header{
    padding-top:0.6rem !important
  }
  h6{
    text-transform: uppercase;
    color: $light-gray-color;
    background-color: $table-passport-th-background;
    padding: 0.4rem;
    border-radius: 0.6rem;
    font-size: 1rem;
  }
  .fx-row-divider{
    padding-top: 1.5rem !important;
    transform: translate(0rem, 0.7rem );
  }
  &-content{
  h4,h3 {
    font-family: $font;
    letter-spacing: 0.5px;
    padding: 0.5rem;
  }
  
  h6 {
    font-family: $font-inter;
    letter-spacing: 1px;
    font-size: 0.75rem;
    font-weight: 600;
    color: $label-color-secondary;
    padding: 0.5rem;
  
  }

  h3 {
    font-size: 1.15rem;
    font-weight: 500;
    color: $label-color-secondary;
  }
  h4 {
    font-size: 1rem;
    font-weight: 400;
    color: $label-color-secondary;
  }
  h5 {
    font-size: 0.875rem;
    font-weight: 300;
    color: $label-color-secondary;
    font-family: $font;
    letter-spacing: 0.5px;
    padding: 0.3rem;
  }
  }
  &-method{
    margin-top: 0.35rem;
    .fx-status-filled {
      padding: 0 0.2rem !important;
    }
    span{
      mark{
        font-size: $font-size-h6;
      }
    }
  }
  &-status{
    span{
      mark{
        font-size: $font-size-h6;
      }
    }
  }
}

.fx-divider-grid-list{
  .MuiGrid-item{
    transform: translate(0rem, -0.6rem);
  }
}
.fx-amount-css{
    font-family: $font-inter;
    font-size: $font-size-h4 !important;
    font-style: italic;
    color: $light-gray-color;
    font-weight: 400 !important;
}

.fx-velocity-limits{
  &-balance{
    display: flex;
    align-items: baseline;
    font-style: italic;
  }
  &-faster-funding-card {
    &-header {
      font-weight: 700;
    }
    &-body{
      h4{
        font-size: $font-size-h4 !important;
      }
    }
    &-note{
      color: $disabled-button-color;
    }
    .fx-currency {
      * {
        font-weight: 500 !important;
        font-size: 1.25rem !important;
      }
    }
  }
}


.fx-container-criteria-summery {
  padding-top: 0 !important;
  padding-left: 0 !important;
  .fx-criteria-accordion{
    &-summary{
      .fx-condition {
        text-transform: uppercase;
        color: $text-color;
      }
      .fx-summary-title {
        font-size: $font-size-h2 !important;
      }
      .fx-count-container{
        border-radius: 2.5rem;
        background: $dark-grey-background;
        display: flex;
        height: 1.5rem;
        width: 1.5rem;
        padding: 0.83333rem;
        justify-content: center;
        align-items: center;
        color: $text-color;
      }
      .Mui-expanded {
        margin: 0;
      }
    }
    &-detail{
      background-color: $body-background;
      margin: 0.5rem;
      padding: 1.5rem;
      color: $text-color;
      h6{
        font-weight: 400;
      }
      &-label {
        color: $grey-text-color;
      }
      &-approver-chip {
        background-color: $dark-grey-background;
        color: $text-color;
        padding: 0.1rem 0.6rem;
        border-radius: 3px;
        height: fit-content;
        text-transform: uppercase;
        p {
          font-size: $font-size-h5 !important;
        }
      }
    }
  }
  .fx-card-header {
    >div {
      align-items: center;
    }

  }

  .fx-footer-approval-form{
    justify-content: start !important;
  }
}

#debit-card-actions-popup-modal-title-text{
  font-size: 1.5rem !important;
}

.fx-generate-api-token {
  &-sub-title {
    color: $grey-text-color;
    margin-bottom: 0.5rem;
  }
  &-alert {
    &-message {
      color: $grey-text-color;
    }

    svg {
      margin-right: 0.5rem;
    }
  }

  .fx-label-with-copy {
    margin-bottom: 0.5rem;
  }
}

.fx-customer-api-token-details-card-modal {
  .fx-modal-title-h3 {
    margin-bottom: 0.8rem;
  }

  .fx-modal-divider {
    padding: 0 !important;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .fx-customer-token{
    color: $text-color;
    &-row {
      padding: 0.5rem 1rem !important;
      border-radius: 0.5rem;
      &-gray {
        background-color: $grey-background;
      }
    }
  }
}
.fx-customer-list{
  &-label{
    padding-left: 0.4rem ;
    padding-right: 0.4rem;
  }
}
.fx-lead-draft-status{
  margin-top: 0.413rem;
  margin-right: 4.5rem
}
.fx-alerts-count{
  border-radius: 2.5rem;
  background: $theme-color;
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0.83333rem;
  justify-content: center;
  align-items: center;
  color: $white;
}
.fx-alerts-drawer{
  min-width: 32vw;
  &-header{
    position: fixed;
    z-index: 1;
    background: white;
    min-width: inherit;
    border-bottom: 0.08rem solid $border-color;
    padding: 1.75rem 1.75rem 0rem 1.75rem;
    &-text1{
      p{
        font-weight: 500;
        font-size: $font-size-h2;
      }
    }
    &-text2{
      p{
        font-weight: 600;
        font-size: $font-size-h3;
        text-wrap: nowrap;
      }
    }
  }
  &-body{
    position: absolute;
    top: 8.5rem;
    padding-bottom: 6.6rem;
    &-no-data{
      p{
        font-size: $font-size-h5;
        font-weight: 500;
        color: $disabled-gray-color;
      }
      padding: 15rem 8rem;
    }
  }
  &-footer-actions{
    padding: 1.75rem;
    z-index: 1;
    background: white;
    min-width: inherit;
    position: fixed;
    bottom: 0%;
    border-top: 0.08rem solid $border-color;
  }
}

.fx-alert-grid{
  min-width: 65rem;
  &-title{
    max-width: fit-content;
    p{
      font-size: $font-size-h3 !important;
      font-weight: 600 !important;
    }
  }
  &-description{
    max-width: 55rem;
    p{
      font-weight: 500 !important;
      font-size: $font-size-h4 !important;
      text-wrap: wrap !important;
    }
  }
}

.fx-btn-mark-read{
  background: none;
  color: $theme-color;
}

.fx-btn-mark-read-disabled{
  background: none;
  color: rgba($theme-color,0.5);
}

.fx-btn-alert-preferences{
  font-size: $font-size-h5;
  font-weight: 500;
  color: $text-color;
  border-radius: 0.5rem;
  background: $disabled-color;
}
.fx-btn-view-all-alerts{
  font-size: $font-size-h5;
  font-weight: 500;
  color: $text-color;
  border-radius: 0.5rem;
  background: $disabled-color;
}

.fx-alert-mobile-grid{
  &-title{
    max-width: fit-content;
    p{
      font-size: $font-size-h3 !important;
      font-weight: 600 !important;
    }
  }
  &-description{
    max-width: 50rem;
    p{
      font-weight: 500 !important;
      font-size: $font-size-h4 !important;
      text-wrap: wrap !important;
    }
  }
}

.fx-alert-component{
  padding: 1.25rem 1.75rem;
  max-width: 32vw;
  border-bottom: 0.08rem solid $border-color;
  &-header{
    border: none !important;
    padding-bottom: 1rem !important;
  }
  &-title{
    max-width: 35.3rem;
    p{
      font-size: $font-size-md !important;
      font-weight: 600 !important;
    }
  }
  &-description{
    max-width: 35.3rem;
    p{
      font-weight: 400 !important;
      font-size: $font-size-h3 !important;
      text-wrap: wrap !important;
      color: $text-color-secondary;
    }
  }
  &-action-button{
    float: right;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 4.17rem;
    background: $theme-color;
    padding: 1px 3px;
    cursor: pointer;

  }
  &-action-button:hover{
    box-shadow: 0px 0px 15px 0px $theme-hover-color;
  }
  &-date{
    padding-top: 1rem;
    font-weight: 500;
    color: $grey-text-color;
  }
}

.fx-read-alert-component{
  background: $background-gray-color;
  padding: 1.25rem 1.75rem;
  max-width: 32vw;
  border-bottom: 0.08rem solid $border-color;
  &-header{
    border: none !important;
    padding-bottom: 1rem !important;
  }
  &-title{
    max-width: 35.3rem;
    p{
      font-size: $font-size-md !important;
      font-weight: 600 !important;
    }
  }
  &-description{
    max-width: 35.3rem;
    p{
      font-weight: 400 !important;
      font-size: $font-size-h3 !important;
      text-wrap: wrap !important;
      color: $text-color-secondary;
    }
  }
  &-action-button{
    float: right;
  }
  &-date{
    padding-top: 1rem;
    font-weight: 500;
    color: $grey-text-color;
  }
}
.fx-container-funding-rules-criteria-summery{
  .fx-summary-title {
    font-size: $font-size-h2 !important;
  }
}

.fx-criteria{
  width: 100% !important;
}

.fx-add-button-theme-text{
  color: $theme-color !important;
}

.funding-rule-criteria-section {
  > div:not(:first-child){  
    padding-top: 2rem;
  }
}

.split-rule-acc-header{
  align-items: center !important;
  max-height: 2.5rem;
}

.fx-account-summary-currency {
  .fx-currency {
    * {
      font-size:  $font-size-h2 !important;
      font-weight: 500 !important;
    }

    &-value {
      font-weight: 600 !important;
      font-size:  $font-size-xl !important;
    }
  }
}

.fx-balance-summary-currency {
  .fx-currency {
    * {
      font-weight: 500 !important;
      font-size:  $font-size-h5 !important;
    }
    
    &-value {
      font-size:  $font-size-h3 !important;
      font-weight: 500 !important;
    }
  }
}

.funding-rule-info-icon{
  vertical-align: super !important; 
}
.fx-generate-api-token {
  &-sub-title {
    color: $grey-text-color;
    margin-bottom: 0.5rem;
  }
  &-alert {
    &-message {
      color: $grey-text-color;
    }

    svg {
      margin-right: 0.5rem;
    }
  }

  .fx-label-with-copy {
    margin-bottom: 0.5rem;
  }
}

.fx-customer-api-token-details-card-modal {
  .fx-modal-title-h3 {
    margin-bottom: 0.8rem;
  }

  .fx-modal-divider {
    padding: 0 !important;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .fx-customer-token{
    color: $text-color;
    &-row {
      padding: 0.5rem 1rem !important;
      border-radius: 0.5rem;
      &-gray {
        background-color: $grey-background;
      }
    }
  }
}
.fx-customer-list{
  &-label{
    padding-left: 0.4rem ;
    padding-right: 0.4rem;
  }
}



.fx-faster-funding-summary-header-columns{
  gap: 0.3125rem !important;
  align-items: center !important;
}

.fx-faster-funding-summary-header-info-icons{
  margin-left: -0.5rem !important;
  display: flex;
}

.fx-faster-funding-detail{
  display: flex;
  align-content: center;

  &-cards{
    border-right: 0.0625rem solid $border-color;
    padding-right: 1rem;
  }

  &-icons{
    display: inline-flex;
    margin-top: 0.25rem;
  }

  &-title{
    margin-left: -0.9rem;
  }
}

.avail-funds-popup-modal{
  padding: 0rem 1.3125rem 1.3125rem 1.3125rem !important;

  .avail-funds-modal-main-grid{
    .avail-funds-header-title{
      text-align: left;
      font-weight: 500;
      font-size: $font-size-h1 !important;
    }
    .sub-title-avail-funds-modal{
      color: $disabled-button-color !important;
    }
  }

  .avail-funds-modal-notes{
    color: $disabled-button-color !important;

    .avail-funds-description-text{
      display: inline-flex !important;
    }
  }
}

.fx-faster-fund-card{
  padding-top:1.1rem;
}

.fx-reserve-balance{
  padding-left: 0.3rem;
  display: inline-flex !important;

  &-grey{
    color: $disabled-button-color !important;

    &-aligned{
      margin-left: 0.3rem;
    }
  }
}

.fx-account-activity-header{
  padding-bottom: 0.438rem;
}

.fx-account-ledger-header{
   display:"flex";
}

.fx-account-list{
  &-icon{
    border-radius: 0.45rem;
    min-height: 4.5rem;
    min-width: 4.5rem;
    max-height: 4.5rem;
    max-width: 4.5rem;
    background-color: $disabled-color;
    margin: 0 0 1.75rem 0;  
    padding: 0;
  }
  &-heading{
    font-size: $font-size-h3;
    font-weight: 600;
    padding: 0 0 1.25rem 0; 
  }
  &-text{
    font-size: $font-size-h4;
    font-weight: 500;
    color: $text-color-secondary;
    padding: 0 0 1.75rem 0;
  }
  &-kyc{
    padding: 0;
    &-button{
      padding: 0;
      font-size: $font-size-h3 !important;
      font-weight: 500;
      color: $theme-color;
      &-svg{
        svg > path{
          fill: $theme-color;
        }
      }
    }
  }
}

.fx-customer-account-list{
  padding: 1.75rem;
  .fx-card-header{
    h3{
      font-size: $font-size-h1 !important;
      font-weight: 600 !important;
    }
  }
}
.fx-association-section{
  &-key-text{
    color: rgba($label-color-secondary, 0.8) !important;
  }
  &-value-text{
    color: $text-color !important;
  }
}



.fx-account-info-card{
  margin: 1.66667rem 0rem;
  &-heading{
    font-size: $font-size-h2;
    font-weight: 600;
    color: $theme-color;
  }
  &-inactive-heading{
    font-size: $font-size-h2;
    font-weight: 600;
    color: rgba($theme-color,0.5);
    svg{
      path{
        stroke: rgba($theme-color,0.5);
      }
    }
  }
  &-balance-text{
    font-weight: 500;
    font-size: $font-size-md;
    color: $disabled-gray-color;
  }
  &-credit-debit-text{
    font-weight: 500;
    font-size: $font-size-h4;
    color: $disabled-gray-color;
    text-transform: uppercase;
  }
  &-balance-amount{
    .fx-currency-value{
      font-weight: 600 !important;
      font-size: 2rem !important;
    }
  }
  &-credit-debit-amount{
    .fx-currency-value{
      font-weight: 600 !important;
      font-size: $font-size-h2 !important;
    }
  }
  &-subtext{
    h3{
      font-size: $font-size-h3;
      font-weight: 500;
      color: $disabled-gray-color;
    }
  }
  &-ppi{
    padding: 0.08333rem 0.5rem;
    background: $disabled-color;
    border-radius: 0.16667rem;
    .fx-link :hover{
      color: $grey-text-color;
    }
    h4{
      font-weight: 400;
      font-size: 1rem;
      color: $grey-text-color;
    }  
  }
}

.fx-set-primary-account{
  &-subtext{
    font-weight: 500 !important;
    font-size: $font-size-h4 !important; 
    color: $text-color-secondary !important;
    padding: 1.25rem 0 1rem 0;  
  }
  &-note{
    font-weight: 500 !important;
    font-size: $font-size-h5 !important;
    padding: 1.25rem 0 1rem 0; 
  }
}

.fx-treasure-home-card{
  background: $light-orange-background;
  margin-top: 0.5rem;
  padding: 1.75rem;
  &-left{
    height: 14.16667rem;
    svg{
      width: 21.25rem;
      height: 14.16667rem;
    }
  } 
  &-right{
    h2{
      font-size: $font-size-md;
      font-weight: 500;
    }
    &-subtext{
      color: $theme-color;
      margin: 1rem 0;
    }
    &-logo{
      margin-top: 0.5rem;
      svg{
        width: 7.5rem;
        height: 1.66667rem;
      }
    }
    &-heading{
      font-size: $font-size-md;
      font-weight: 500;
    }
    &-button{
      font-size: $font-size-md;
      font-weight: 400;
    }
  }
}

/** Webhooks **/
.create-webhook-form{

  padding: 1rem 0.4rem 0.4rem 0.4rem !important;

  &-basic-info-title{
    h4{
      font-size: $font-size-md !important;
      font-weight: 500;
    }
    margin-bottom: 1.5rem;
  }
}