/*accordion css*/
.fx-accordion {
  .fx-card-header {
    width: 100%;
    padding: 1.25rem;
    font-size: 0.875rem !important;
  }

  .fx-card-left {
    box-shadow: none;
  }

  .fx-group-checkbox {
    .MuiFormControlLabel-root {
      margin: auto;
    }
  }

  .MuiExpansionPanelSummary-root {
    padding: 0 !important;
    height: 3.375rem !important
  }
}
/*accordion css ends*/

.MuiAccordionSummary-content {
  margin: 0 !important;
}

.fx-container {

  .fx-container-left {
    padding-right: 2rem;
    max-width: 68%;
    flex-basis: 68%;
  }

  .fx-container-right {
    max-width: 32%;
    flex-basis: 32%;
  }
}

/*fx layout table*/
.fx-layout-table {
  width: 100%;
  border: 0;
  border-spacing: 0;

  .fx-left-card {
    width: 66%;
    vertical-align: top;
    padding: 1.25rem 1.25rem 0 0;
  }

  .fx-right-card {
    width: 33%;
    vertical-align: top;
    padding: 1.25rem 0 0 0;
  }
}

/*fx layout table eds here*/

.fx-layout-list,
.fx-data-list {
  padding: 0 0 0 0;

  .fx-card-general {
    margin-right: 0;
  }

}


/* fx card here */
.fx-card {
  @media (max-width: 600px) {
    padding: 1rem 1rem 1rem 1rem;
  }
  padding: 2.5rem;
  width: 100%;

  .fx-card-header {

    border-bottom: 1px solid $border-shadow-color;
    // height: 60px ;
    padding: 0 0 1.375rem 0;

    h3 {
      font-size: 1.25rem;
      font-family: $font-header;
      font-weight: 500;
      color: $theme-color-head;
      padding-top: 3px;
      max-width: 50vw;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 1.2;
      padding-bottom: 1px;

    }

    h4 {
      font-size: 1rem;
      font-family: $font;
      font-weight: 600;
      color: $label-color-secondary;
      max-width: 50vw;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .MuiGrid-container {
      width: auto;
      padding-top: 0;
    }

    .MuiGrid-root {
      +div {
        align-items: center;
      }
    }

    .MuiButton-startIcon {
      svg {
        path {
          fill: #0a73e9;
        }
      }
    }
    
    .fx-full-width{
      max-width: none;
    }
  }

  .fx-header-secondary-text {
    color: $text-color-secondary !important;
  }

  .fx-card-body {
    width: 100%;

    nav hr:last-child {
      background-color: transparent;
    }

    svg {
      color: $input-value-color;
    }

    .MuiTypography-subtitle1 {
      font-family: $font;
    }
  }

  .fx-card-body-no-border-no-shadow {
    .MuiPaper-rounded {
      box-shadow: none !important;
      border-radius: 0 !important;
    }
  }

  .account-info-button {
    color: $theme-color;
    border: 0.083rem solid $theme-color;
    padding: 0.33rem 1rem !important;
  }

  .balance-amount {
    font-size: 1.5rem;
    font-family: $font-nunito ;
    font-weight: 800;
    color: $label-color;
    margin-bottom: 0.5rem;
  }

  .fx-card-body-total-balance {
    background: $balance_background;
    font-family: $font ;
    color: $balance-color;
    border-radius: 0.375rem;

    @media (max-width: 600px) {
      padding: 0;
    }

    h3 {
      font-size: 1.5rem;
      font-family: $font ;
      color: $input-value-color;
    }

    .fx-digest-grid {
      height: 2.8rem;
      display: flex;
    }

    .fx-total-balance-grid {
      box-sizing: border-box;

      //add padding in balance main grids
      >div:not(:last-child) {
        padding-right: 1rem;

        @media (max-width: 600px) {
          padding-right: 0;
          padding-bottom: 1rem;
        }
      }

      >div:not(:first-child) {
        padding-left: 1rem;

        @media (max-width: 600px) {
          padding-left: 0;
        }
      }
    }
  }

  .fx-card-footer {
    width: 100%;
  }

  .fx-card-footer-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 0.66rem 0 1.33rem;
    background-color: $white;
  }

  .fx-themed-icon {
    path {
      stroke: $theme-color;
    }
  }

  .MuiInputBase-formControl,
  .fx-phone-edit {
    height: 3.5rem;
  }

  .fx-phone-edit {
    margin-top: 0 !important;

    .MuiInputBase-root {
      margin: 0 !important;
    }

    .MuiOutlinedInput-adornedEnd {
      padding-right: 0;;
    }

  }

  .MuiInputAdornment-positionEnd {
    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  /*form elements focus style*/
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: $theme-text-color !important;
    border-width: 1px !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $border-color !important;
    border-width: 2px !important;

  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $theme-text-color !important;
    border-width: 1px !important;
  }
}

.fx-card-general {
  background-color: $white;

  .fx-card-header {
    h3 {
      font-family: $font-regular;
    }
  }

  .fx-footer {
    .fx-link {
      font-family: $font;
      font-size: 0.8125rem;
      font-weight: normal;
      line-height: 1;
      color: #1b4b72;
    }
  }

}


/* fx card ends here */


/* info card */
.fx-info-card {
  .fx-info-bar {
    background-color: $passport-home-sub-heading-background-color;
  }

  h5 {
    color: $light-gray-color;
    font-family: $font-inter;
    font-size: 0.875rem;
    font-weight: 500
  }

  p {
    font-family: $font-regular ;
    font-size: 1rem;
    display: inline;
  }

  .fx-customer-info-label {
    text-align: right;
    margin-right: 1.25rem !important;
    padding-left: 1.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .fx-info-label {
    text-align: right;
    margin-right: 1.25rem !important;
    color: $input-value-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .fx-info-value {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .fx-table-generic-info {
    width: 100%;
    table-layout: fixed;

    tr:last-child td {
      border-bottom: none;
    }

    th {
      font-family: $font-regular;
      font-size: 0.75rem;
      color: $label-sec;
      text-align: left
    }

    td {
      color: $input-value-color;
      font-family: $font-regular;
      font-size: 0.875rem;

    }

    table:first-child {
      width: 100%;
    }

    td:first-child {
      color: $placeholder-color;
    }

  }

  .MuiChip-root {
    margin: 5px;
  }

  .fx-info-card-form {
    >div {
      padding-bottom: 1rem;
      position: relative
    }

    .MuiTypography-body1 {
      font-size: 0.875rem;
      font-weight: 500 ;
    }
    &-left-padding {
      @media (max-width:600px) {
        padding-left: 2rem !important;
      }

    }
  }


}



/* info card ends here */


/*card for debit card image block*/
.fx-card-debit {
  padding: 0 0 0 4rem !important;

  @media (max-width: 600px) {
    padding: 0 !important;
  }

  .fx-card-body {
    background-color: $white;
    padding: 2.5rem;
    border : $border-card;
    border-radius: $border-card-radius;


    .fx-card-debit-view {
      background-image: url(../../assets/svg/debit-card-image.svg);
      color: white;
      border-radius: 1rem;
      padding: 2rem;

      &-virtual {
        font: 500 1rem $font;
        color: $white;
        text-transform: uppercase;
        letter-spacing: .5rem;
        position: relative;
        text-shadow: 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.2), 0 -0.125rem 0.1875rem rgba(255, 255, 255, .25);
      }

      &-virtual::after {
        content: "";
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: $white;
        width: 1.625rem;
        height: 0.0625rem;
        right: -2.125rem;
        box-shadow: 0 0.0625rem 0.0625rem rgba(0, 0, 0, .2), 0 -0.125rem 0.1875rem rgba(255, 255, 255, .25);

      }

      &-name {
        font: 600 1.125rem $font;
      }

      &-account {
        font: 400 1.5rem $font;
      }
    }
    .fx-digital-debit-card-view {
      background-image: url(../../assets/svg/digital-debit-card.svg);
      color:$white;
      background-position: center;
      background-repeat: no-repeat;
      min-height: 30rem;
      min-width: 20rem;
      padding: 2rem;
      max-width: 20rem;
      margin: auto;

      &-holder-name, &-hidden-number, &-hidden-cvv, &-hidden-expiry {
        font-size: $font-size-h2;
      }

      &-label {
        font-size: $font-size-h6;
      }

      &-name{
        margin-top: 11rem;
        margin-bottom: 0.4rem;
      }

      &-number{
        margin-bottom: 0.4rem;
      }

    }

    .fx-show-digital-debit-card {
      display: flex;
      min-height: 30rem;
      justify-content: center;

      img {
        max-width: 20rem;
        padding: 0 0.7rem;
        min-width: 20rem;
        min-height: 100%;
      }
    }
  }
  
}

/*card for debit card image block ends here*/


.fx-footer {
  margin-right: 1.25rem;
  color: $theme-color !important;
}

.fx-footer:empty {
  display: none;
}

.fx-footer-icon {
  margin-left: 3px !important;
  color: $theme-button-background !important;
}

.fx-footer-middle {
  justify-content: center !important;
}
.fx-footer-title {
  color: $black-color !important ;
}
.fx-footer-link {
  color: $theme-color !important;
}

.fx-filter-card {
  width: 30rem;
  height: 100vh;
  background-color: #f2f4f7;
  position: relative;
  overflow: hidden;

  .fx-filter-card-header {
    background-color: #ffffff;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: normal;
    color: $label-dark-blue;
    padding: 1.5rem 1.5rem 1.0625rem 1.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .fx-filter-card-body {
    background-color: #f2f4f7;
    padding: 1.25rem 1.25rem;
    height: calc(100vh - 8.75rem) !important;
    overflow: auto;
    width: auto;

    .fx-bottom-text {
      color: #7a86a8;
      font-size: 0.6875rem;
      top: -0.1875rem;
      position: relative;
    }

    .fx-group-radio {
      .MuiTypography-root {
        padding-bottom: 0;
      }
    }

    .fx-group-checkbox {
      .MuiFormControlLabel-root {
        width: 100%;
      }

      .MuiCheckbox-root {
        padding: 0.3125rem 0.5625rem;
      }

      .MuiTypography-root {
        padding-bottom: 1px !important;
      }
    }

    .fx-column-checkbox {
      width: 100% !important;

      .MuiTypography-root {
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: $label-dark-blue;
      }

      .MuiSvgIcon-root {
        color: $theme-color;
        width: 0.875rem;
        height: 0.78rem;
        object-fit: contain;

      }

    }

    .MuiTypography-root {
      font-family: $font;
      font-size: 1rem;
      font-weight: 500;
      padding-bottom: 0.625rem;
    }

    .error-message {
      display: none;
    }

    .MuiGrid-container {
      padding-bottom: 0.9375rem;
    }

  }

  .fx-filter-card-footer {
    position: sticky;
    font-size: 1rem;
    bottom: 0;
    padding: 1.25rem;
    display: block;
    align-items: center;
    height: 4.5rem;
    background-color: #ffffff;
    border-top: solid 1px $border-color;
    box-sizing: border-box;

    button {
      text-transform: none !important;
    }

    .fx-link {
      color: $blue;
    }

    .fx-link :hover {
      color: #0c339c;
      background: none !important;
    }

    .fx-float-right {
      float: right;
      display: flex;

      .fx-button-submit {
        background-color: $background-color;
        color: white;
        margin-left: 10px;
      }

      .fx-button-cancel {
        background-color: $btn-def-background;
      }
    }
  }
}

/* Sticky Header Date grid*/
.fx-grid-container {
  max-height: 79vh;
  overflow-x: auto;
  height: 100%;
  max-width: calc(100vw - 5rem);

  @media (max-width: 600px) {
    max-width: calc(100vw - 6rem);
  }
}

.fx-table-pagination {
  position: sticky;
  bottom: 0;
  background: white
}

/* Sticky Header Date grid* ends here*/

.fx-card-body-quick-links {
  a {
    cursor: pointer !important;
    width: 100%;
  }

  a:hover {
    .left-column svg {
      transform: scale(120%);
    }
  }

  .fx-quick-link:not(:last-child) {
    display: flex;
  }

  >div:not(:last-child):after {
    content: "";
    position: relative;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: url(../../assets/svg/horizontal-gray-light.svg);
    background-repeat: repeat-x;
    background-size: contain;
    user-select: none;
    pointer-events: none;
    margin-top: 1rem;
    margin-bottom: 1rem;

  }

  .left-column {
    vertical-align: top;
    display: flex;
    padding-right: 8px;

    .list-icon {
      border-radius: 0.3125rem;
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 1.5rem;
        height: 1.5rem;

        path {
          stroke: $theme-color;
        }
      }
    }


  }


  .right-column {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      cursor: pointer;
      margin-top: 5px;

      svg {
        transform: scale(0.8);
      }
    }
  }

  .middle-column {
    p {
      margin: 0;
      color: $light-gray-color;
    }

  }

  .icon {
    background-repeat: no-repeat;
    height: 50px;
  }

  .MuiTypography-subtitle1 {
    font-family: $font-inter-semibold !important;
    font-weight: 600 !important;
    color: $dark-gray-color;
    font-size: 1.0625rem;
    line-height: 1.0625rem;
    margin-bottom: 0.25rem;


  }

  .MuiTypography-subtitle2 {
    font-family: $font-inter;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    color: $light-gray-color;
  }

}

.fx-dashboard-section-one-cards{

  &-right {
    max-width: 58%;
    flex-basis: 58%;
    padding-left: 1.75rem;
  }

  &-left {
    max-width: 42%;
    flex-basis: 42%;
  }
}

.fx-text-edit-with-chips{
  .MuiFormControl-root{
    .MuiInputBase-root{
      height: max-content !important;
      .MuiChip-root{
        background-color: $dark-grey-background;
        height: 2rem; 
        .MuiChip-label{
          color: $text-color;
          text-transform: none !important;
        }
        .MuiSvgIcon-root{
          width: 1rem;
          height: 1rem;
        }
       
     }
    }
}
}

.fx-account-tag-chips{
  background-color: $button-lite-background !important;
  color: $text-color !important;
  text-transform: none;
  margin-right: 1rem;
  margin-bottom: 1rem;
  .MuiSvgIcon-root{
      display:none;
      }     
}

//CSS for the Activate Card Payments Card
.activate-card-payments-card-grid{
  position: relative;
  height: 17.4rem !important;
  background-image: url(../../assets/svg/credit-card-bg-curves.svg) !important;
  background-repeat: no-repeat !important;
  background-position: bottom !important;

  &-header{
    padding: 0 0 0.9rem 0 !important;

    svg{
      position: absolute !important;
      right: 0.2rem;
      transform: translate(5.83rem, -8.83rem);
    }
  }

  .activate-card-payments-section-note{
    font-size: $font-size-h3 !important;
    font-weight: 400 !important;
    color: $disabled-button-color !important;
    padding-bottom: 1rem !important;
  }
}

.fx-merchant-add-form-info-labels{
  background-color: $dark-grey-background !important;
  border-radius: 0.41667rem;
  padding: 0.5rem !important;
  &-text{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

.fx-df-merchant-agreements {
  margin-left: 0.7rem;
  color: $text-color;
  border: 0.083rem solid $dark-grey-background;
  border-radius: 0.5rem;

  .fx-fee-agreement{

    padding: 1.75rem 0rem;

    &-link {
      text-decoration: none;
  
      span {
        color: $theme-color;
        font-weight: 600;
      }
      .normal-font{
        font-weight: 400 !important;
      }
    }
  }

}

.fx-merchant-create-form-main-grid{
  overflow: auto !important;
  max-height: 99vh;
  scrollbar-width: none !important;

  .fx-merhant-create-form-main-grid{
    background: $background-drawer;   
    background-size: contain;
  
    .fx-merchant-form-card{
      margin-bottom: 0rem;
    }
  }
}

.fx-merchant-create-form-container{
  .filter-popover-label{
    font-size: $font-size-h4 !important;
    font-weight: 500 !important;
  }
  .sales-method-errors{
    padding-bottom: 0.43rem !important;
    &-billing-summary{
      padding-bottom: 1rem !important;
    }
  }
  .card-title-note-text{
    color: $disabled-gray-color !important;
    font-size: $font-size-h5 !important;
    font-weight: 500 !important;
    margin-top: 0.7rem !important;
  }
}

.fx-sales-method-grid{
  padding-bottom: 0.3rem !important;
  > div:not(:last-child) {
    padding-bottom: 1rem;
  }
}

.fx-company-merchant-list-card-body{
  overflow: auto;
  scrollbar-width: none;
  max-height: 35vh;
}