/* skeleton styles start */
.fx-skelton {
   box-shadow: none !important;
  .MuiSkeleton-root {
    border-radius: 1px;
    background-color: #f2f3f9 !important;
  }
}

.fx-table-skelton {
  .MuiCardHeader-root {
    padding: 2px 16px;
  }

  .MuiCardContent-root {
    padding: 2px 16px 2px 16px !important;
  }

  tr:first-child td {
    height: 40px !important;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td {
    border-bottom: 1px solid #eaeff5;
    height: 44px;
  }
}

.fx-skelton-full-page-loader {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.fx-skelton-full-page-loader-menu {
  top: 0;
  bottom: 0;
  width: 220px;
  padding: 14px 0 0 0;
  background-color: #1E2833;
  min-height: 100vh !important;
}

.fx-skelton-details {
  .MuiCardContent-root {
    padding: 2px 0 0 20px;

    .MuiGrid-container {
      height: 27px;
    }

    .MuiGrid-container:last-child {
      height: 8px !important;
    }

  }

  .MuiCardContent-root:last-child {
    padding-bottom: 0;
  }

  .MuiSkeleton-root {
    transform: none;
  }
}

.fx-skelton-details-right {
  padding: 10px 0;

  .MuiGrid-container {
    height: 27px;
  }

  .MuiSkeleton-root {
    transform: none;
  }
}

.fx-skelton-details-modal {
  box-shadow: none !important;

  .MuiCardContent-root {
    padding: 0;

    .MuiGrid-container {
      height: 27px;
    }

    .MuiGrid-container:last-child {
      height: 8px !important;
    }

  }

  .MuiCardContent-root:last-child {
    padding-bottom: 0;
  }

  .MuiSkeleton-root {
    transform: none;
  }

}
/* skelton styles end */

/* fx sort styles start */
.fx-sort {
  max-height: 75vh;
  overflow: auto;

  .fx-card-body>div {
    margin: 7px 0;
  }

  p {
    margin: 0;
    padding: 13px 20px 13px 20px;
  }

  p:hover {
    background-color: $btn-def-background;
  }

  .selected {
    background-color: $btn-def-background;
  }

  .error-message {
    color: #ae1326;
    height: 17px;
    padding-top: 1px;
    font-size: 11px;
    font-weight: 600;
  }
}
/* fx sort styles end */

/* linear progress bar styles start */
.fx-linear-progress {
  .MuiLinearProgress-barColorPrimary {
    background-color: $blue;
  }

  background-color: $light-grey;
  margin: 0 20px;
}
/* linear progress bar styles end */