@import "default";
@import "global";
@import "src/components/Action/styles";
@import "src/components/Input/styles";
@import "src/components/Data/styles";
@import "src/components/Container/styles";
@import "src/components/Page/styles";
@import "src/components/Navigation/styles";
@import "src/components/Layout/styles";
@import "src/components/Page/Auth/styles";
@import "src/components/Page/AuthorisedUsers/styles";
@import "src/components/Page/Cards/styles";
@import "src/components/Page/Index/Passport/styles";
@import "src/components/Page/ScheduleDetails/styles";
@import "src/components/Page/SignUp/styles";
@import "src/components/Page/Accounts/styles";
@import "src/components/Page/PPI/styles";
@import "src/components/Page/Schedules/styles";
@import "src/components/Page/Details/styles";
@import "src/components/Page/Role/styles";
@import "src/components/Utils/utilsStyles";
@import "src/components/DrawerPages/styles";
@import "src/components/Page/Partner/styles";
@import "src/components/Page/ManageAlerts/styles";


/* material ui styles */
.MuiTypography-h3, .MuiTypography-45, .MuiTypography-h5, .MuiTypography-h6 {
  letter-spacing: 0.5px;
}

.MuiDropzoneArea-root {
  margin-bottom: 10px !important;
}

.MuiPaper-rounded {
  overflow: hidden;
  border-radius: 0.375rem;
  box-shadow: $border-shadow;
}

.MuiSelect-select:focus {
  background-color: transparent;
}

.MuiCheckbox-root {
  color: $theme-color !important;
}

.MuiCheckbox-root:hover{
  background: none !important;
}

.Mui-checked {
  color: $theme-color !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #4975f1 !important;
}

.MuiPickersDay-daySelected {
  background-color: #4975f1 !important;;
}

.MuiPickersModal-dialogRoot {
  .MuiButton-textPrimary {
    color: #4975f1;
  }
}

.MuiPickersToolbarText-toolbarBtnSelected {
  cursor: default !important;
}


.MuiTouchRipple-rippleVisible {
  opacity: 0 !important;
  animation: 0ms !important;
  transform: none !important;
}

.MuiTouchRipple-ripplePulsate {
  animation-duration: 0ms !important;
}


.MuiInputBase-root {
  color: $label-color-secondary;
  font-weight: 500;
}


/* react select menu items */
[class*="css-"] {
  [id*="-listbox"] {
    border-radius: 0.5rem !important;
    box-shadow: 0 0.25rem 1.5rem 0 rgba(0, 0, 0, 0.1) !important;

    > div {
      padding: 0 12px;
      position: relative;

      > div {
        font-size: 11px !important;
        background: $white;
        cursor: pointer;
      }

      > div:not(:last-child):after {
        background-image: linear-gradient(to left, $devider-border-color 20%, rgba(255, 255, 255, 0) 0%);
        position: relative;
        bottom: 0;
        display: block;
        content: '';
        height: 1px;
        width: 100%;
        background-position: left;
        background-size: 6px 1.5px;
        background-repeat: repeat-x;
        top: 4px;
        padding: 2px 0;
      }
    }
  }
}

/* react material menu item changes */
.MuiMenu-list {
  > li {
    font-size: 0.875rem !important;
    background: $white;
    cursor: pointer;
    display: block;
  }

  > li:not(:last-child):after {
    background-image: linear-gradient(to left, $devider-border-color 20%, rgba(255, 255, 255, 0) 0%);
    position: relative;
    bottom: 0;
    display: block;
    content: '';
    height: 1px;
    width: 100%;
    background-position: left;
    background-size: 6px 1.5px;
    background-repeat: repeat-x;
    top: 4px;
    padding: 2px 0;
  }

  .Mui-selected {
    color: $theme-color !important;
    font-weight: 700 !important;
  }
}

/* snackbar */
.MuiSnackbar-root {
  .MuiAlert-root {
    @media (max-width: 1280px) {
      width: 100%;
    }
    min-width: 24rem;
    align-items: flex-start;
    .MuiAlert-icon {
      margin-top: 2px;
    }
    .MuiSvgIcon-root {
      font-size: 1.5rem;
    }
  }
}

/* control circular progress */
.MuiCircularProgress-colorPrimary {
  svg {
    color: $white !important;
  }
}

/* calendar component */
.MuiCalendarPicker-root {
  .Mui-selected {
    background-color: $theme-color;
  }

  .MuiPickersDay-root:hover,
  .PrivatePickersMonth-root:hover,
  .PrivatePickersYear-yearButton:hover {
    color: $theme-color;
    background-color: $button-lite-background;
  }

}

/* default class for input edit components start here */
.fx-input-edit-disabled {
  font-family: $font !important;
  font-size: 1rem !important;
  background: $disabled-color !important;
  width: 100%;

  input {
    font-family: $font !important;
    font-size: 1.0625rem !important;
    font-weight: 500 !important;
    color: $theme-input-color;
  }
}


.fx-icon-button {
  svg {
    width: 1rem !important;
    height: 1rem !important;

    path {
      fill: none !important;
      stroke: $label-color-secondary !important;
    }
  }
}

/* svg color for check and radio */
.fx-card-body .MuiFormGroup-root {
  :not(.Mui-checked ) {
    svg {
      color: rgba($label-color-third, 0.5);
    }
  }

  .Mui-checked svg {
    color: $theme-color;
  }
}


.fx-schedule-card-summary {
   padding: 0 !important;

  .fx-card-body {
    background-color: $white;
    padding: 0rem;
    // box-shadow: $card-box-shadow;

    .fx-debit-card-view-action{
      padding-top: 1rem;
    }

    border-radius: $border-card-radius;


    .fx-schedule-card-summary-view {
      background-image: url(../../assets/svg/debit-card-image.svg);
      color: white;
      border-radius: 1rem;
      padding: 2rem;

      &-virtual {
        font: 500 1rem $font;
        color: $white;
        text-transform: uppercase;
        letter-spacing: .5rem;
        position: relative;
        text-shadow: 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.2), 0 -0.125rem 0.1875rem rgba(255, 255, 255, .25);
      }

      &-virtual::after {
        content: "";
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: $white;
        width: 1.625rem;
        height: 0.0625rem;
        right: -2.125rem;
        box-shadow: 0 0.0625rem 0.0625rem rgba(0, 0, 0, .2), 0 -0.125rem 0.1875rem rgba(255, 255, 255, .25);

      }

      &-name {
        font: 600 1.125rem $font;
      }

      &-account {
        font: 400 1.5rem $font;
      }
    }
  }
}

.fx-virtual-card-view img{
  border : $border-card;
  border-radius: 1rem;
  height: 100%;
  width: 100%;
  max-height: 21rem;
}
.fx-virtual-card-block{

  max-height: 21rem !important;

  .virtual-card-loader{
    position: relative;
    transform: translate(12rem, -14rem);
    height: 8rem !important;
    width: 8rem !important;
    border: none !important;
    padding: 0rem !important;
  }
}


.fx-virtual-card-detail{

  .fx-login-page-title h3{
    font-size: 2.3rem !important;
    font-weight: 500 !important;
    padding: 10px 0 0 2rem;
  }

  .fx-virtual-card-view{
    height: 27rem;
    padding: 2rem 0rem;
}



  .fx-virtual-card-summary-card{
    margin-bottom: 0rem;
    border : $border-card;
    border-radius: 1rem;
    padding:0rem;
    height: 21rem;
  }

  .fx-virtual-card-balance{
    padding: 3rem 0rem 0rem 3rem
  }

  .fx-virtual-card-summery-details{
    .fx-info-label-value-container > div:nth-of-type(2n) {
      background-color: $grey-background;
      border-radius: 0.375rem;
    }

    .fx-info-label-value > div:nth-child(1){
      padding: 0;
    }

    .fx-info-label-value {
      padding: 0.375rem 0.75rem;
    }
  }
}


.ledger-graph-category-card {
    width: 50%;
    display: flex;
    margin-top: 1.7rem;
    padding-right: 1rem;
    
    &:not(:last-child) {
      margin-right: 1rem;
      border-right: 0.0625rem solid $border-color;
    }

    .ledger-graph-category-card-header{ 
      display: flex; 
      align-items: center;
    }
}

.ledger-graph-category-card-amount{
  padding: 0.313rem;
  min-height: 0 !important;
  .fx-currency
  {
    &-value {
      font-size: $font-size-md !important;
    }
  }
}

.ledger-graph-category-cards-block{
  display: flex; 
}

.ledger-graph-category-cards-block-mobile{
  display: block; 
  .fx-card{
    background-color: white;
    margin: 1rem;
    width: auto;
  }
}

.fx-quick-settle-icon{
    svg path{
      color: $theme-color !important;
      fill: $theme-color !important;
    }
    svg rect{
      color: $theme-color !important;
      fill: $theme-color !important;
      opacity: 0.5;
    }

    &-mobile{
        margin-top: 0.4rem;
    }
  }

  .fx-adjustment-icon{
    svg path{
      color: $dark-red !important;
      fill: $dark-red !important;
    }
    svg rect{
      color: $dark-red !important;
      fill: $dark-red !important;
      opacity: 0.5;
    }

    &-mobile{
        margin-top: 0.4rem;
    }
  }

  .fx-resend-icon{
    svg {
      display: flex;
      width: 1rem;
      height: 1rem;
      justify-content: center;
      align-items: center;
    }
  }
  .fx-selected-green-icon{
    svg{
      height: 1.1rem;
      circle{
        fill : $theme-color;
      }
    }
  }

  .fx-quick-settlement-hold-icon{
    svg rect{
      color: $menu-color !important;
      fill: $menu-color !important;
    }
  }

.fx-signup{
  &-back-icon{
    padding-left: 1rem;
  }
}

.fx-word-wrap{
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.fx-business-owner{
  &-add-button{
    padding-left: 2.5rem;
    @media (max-width:600px){
      padding-left: 0;
    }
  }
  &-certify-checkbox{
    padding-left: 1rem;
  }
  &-icon{
    padding-left: 2.5rem;
  }
}
.fx-business-details{
  &-icon{
    padding-left: 2.5rem;
  }
}
.fx-payment-details{
  &-icon{
    padding-left: 2.5rem;;
  }
}
.fx-individual-details{
  &-icon{
    padding-left: 2.5rem;;
  }
}

.fx-ledger-summary-card {
  padding: 1.8rem;
  .fx-ledger-summary-card-subtitle{
    display: flex;
    background-color: $body-background;    
    padding: 0 0.5rem;
    border-radius: 0.5rem;
  }
  .fx-ledger-summary-total-section{
    padding: 1.5rem;
    background-color: $body-background;    
    border-left: 0.35rem solid $theme-color;   
    border-radius: 0 0.7rem 0.7rem 0;
  }
  .fx-ledger-summary-total-value{    
    padding-top: 0.5rem;
  }  
}
.fx-business-owner-add{
  &-border{
    margin-left: 1rem;
    @media (max-width:600px){
      margin-left: 3rem;
    }
  }
}
.fx-business-owner-view{
  &-border{
    margin-left: 1rem;
    @media (max-width:600px){
      margin-left: 3rem;
    }
  }
}
.fx-footer-kyc{
  position: fixed;
  bottom: 2rem;
}
.fx-phone-edit-kyc{
  .MuiOutlinedInput-input{
    padding: 0 !important;
  }
  .MuiIconButton-root{
    padding: 0;
  }
  .fx-phone-edit-country{
    svg{
      margin-left: 1.5rem;
      margin-top: 0.83rem;
    }
   
  }
}
.fx-ledger-summary-card-mobile{
  margin-bottom: 0rem;
} 

.fx-ea-type-button-mobile{
  @media (max-width: 600px) {
    padding-left: 1rem;
    padding-top: 0.62rem;
  }
}

.fx-settle-icon{
  svg path{
    color: $theme-color !important;
    fill: $theme-color !important;
  }
  svg rect{
    color: $theme-color !important;
    fill: $theme-color !important;
  }
}

.fx-account-info-icon {
  svg rect{
    fill: $button-lite-background !important;
  }
  svg {
    .fx-telegram-path-icon-fill{
      fill: $theme-color !important;
    }
    .fx-eye-path-icon-stroke {
      stroke: $theme-color !important;
    }
  }
}

.fx-choose-file-button{
  @media (max-width: 900px) {
    .MuiButton-root{
      padding: 0.66rem !important;
    }
  }
}
.fx-account-statement{
  &-message{
    font-size: $font-size-h4 !important;
  }
}

.fx-investment-summary-card {
  background: $white;
  border: $border-card;
  border-radius: $border-card-radius;
  font-size: $font-size-h6;
  margin-bottom: 2rem;

  .fx-card-header {
    color: $text-color !important;
  }

  &-percentage{
    border-radius: 1rem;
    background: $status-green-fill;
    font-size: $font-size-h5 !important;
    font-weight: 400;
    color: $light-green-color;
    padding: 0rem 0.5rem;
  }

  &-amount-section {
    background-color: $body-background;
    padding: 1.5rem;
    border-radius: 0.7rem;


    &-one {
      padding-right: 1.5rem;
      color: $text-color;
      &-text{
        font-size: $font-size-h5 !important;
      }
    }

    &-two {
      padding: 0rem 1.5rem;
      color: $text-color;
      &-text{
        font-size: $font-size-h5 !important;
      }
    }

  }

  &-header {
    border: none !important;
    h3{
      font-size: $font-size-h2 !important;
      font-weight: 600 !important;
    }
  }

  &-subtitle {
   font-size: $font-size-h5;
   font-weight: 500;
  }

  .MuiDivider-root {
    background-color: $border-color;
    border-color: $border-color !important;
    height: 6rem;
  }

  .fx-stepper {
    .MuiStepper-root {
      padding-top: 0rem;
      background-color: transparent;
      height: auto;     
      border: 0 !important; 
    }

    .css-z7uhs0-MuiStepConnector-line{
      display: none;
      background-color: transparent;
      height: auto;
      padding-top: 0rem;
    }

    // to modify the size of steper for teasure signup 
    svg {
      width: 2.3rem;
      height: 2.3rem;
    }

    h6{
      font-size: $font-size-h4 !important;
      font-weight: 400 !important;
      color: $light-gray-color;
    }

  }
  .MuiTypography-subtitle1{
    font-size: $font-size-h6 !important;
  }
  .fx-investment-account-summary-card-accountbalance{
    padding: 1.99rem 1.99rem 1rem 1rem;
  }

  .fx-investment-report-chart {
    svg.recharts-surface {
      margin-left: -1.5rem;
      width: calc(100% + 1.5rem);
    }

  }

  .fx-invest-btn{
    &-text{
      font-size: $font-size-h5;
      font-weight: 400;
      margin-left: 0.5rem;
    }
    &-svg{
      stroke-width: 0px;
    }
  }
}
.fx-dark-grey-info-card{
  font-style: italic;
  color: $label-color-third;
  font-family: $font;
}

.fx-dark-grey-icon{
  margin-left: 0.5rem
}

.fx-new-invite{
  &-owner-profile-label{
    font-size: $font-size-md !important;
    font-weight: 500 !important;
  }
  &-owner-profile-label-sub-header{
    font-family: $font;
    font-size: $font-size-h4 !important;
    font-weight: 600 !important;
  }
  &-owner-profile-sub-label{
    font-size: $font-size-h3 !important;
    font-weight: 400 !important;  
  }
  &-business-owner-info-card{
      h5{
        color: $text-color !important;
        padding-left: 1rem !important;
      }
      .MuiGrid-item{
        font-size: $font-size-h4 !important;
        font-weight: 400 !important;  
        color: $text-color !important;;
      }
      p{
        font-size: $font-size-h4 !important;
        font-weight: 500 !important;  
        color: $text-color !important;;
      }
  }
  &-owner-profile-action-grid{
    button{
      background-color: $grey-background !important;
      border: none !important;
      color: $label-color-secondary !important;
      margin-left: 1rem;
      svg{
        path{
          stroke: $label-color-secondary !important;
        }
      }
    }
  }
  &-sub-header{
    font-size: $font-size-h2 !important;
    font-weight: 500 !important;  
  }
  &-sub-label{
    font-size: $font-size-h4 !important;
    font-weight: 500 !important;  
  }
  &-auth-rep{
    border-radius: 1rem;
    border: 1px solid $border-color;
    padding: 0.33rem 0.833rem 0.33rem 0.66rem;
  }
  
}
.fx-light-grey-text{
  color: $label-color-third !important;
  font-family: $font;
  margin-top: 0.5rem;
}

.fx-dark-grey-info{
    width: 1.5rem;
    height: 1.5rem;
    color:$text-color;
}
.fx-admin-preferences {
  background-color: $white;
  position: relative;

  &-sub-title {
    font-size: $font-size-h4;
    font-weight: 500;

    &-grid {
      background-color: $grey-background;
      padding-left: 1rem;
      margin-right: 1rem;
    }
  }

  &-otp-verify-grid {
    border: 1px solid $border-color;
    margin-bottom: 1rem;

    .MuiChip-root {
      background-color: $text-color !important;
      color: $white;
      font-size: $font-size-h4;
      font-weight: 500;
      height: 1.5rem;
      border-radius: 0.25rem;
    }

    .MuiChip-deleteIcon {
      height: 1.5rem;

      path {
        fill: $white;
      }
    }

    .MuiAutocomplete-popupIndicator {
      svg {
        path {
          fill: $white !important;
        }
      }
    }

    .MuiAutocomplete-popupIndicator:hover {
      background-color: $white;
    }
  }

  &-user-selection-grid {
    padding: 1rem;
  }

  &-switch-grid {
    .fx-switch {
      transform: scale(0.8) !important;
    }
  }

  &-switch-title {
    font-size: $font-size-h3;
    font-weight: 600;
    padding-top: 0.5rem;
  }

  &-switch-sub-title {
    font-size: $font-size-h4;
    font-weight: 400;
    color: $text-color-secondary;
    padding-bottom: 1rem;
  }

  &-otp-checkbox-checked {
    padding-left: 1rem;

    .MuiCheckbox-root {
      color: $theme-color !important;
    }
  }

  &-otp-checkbox-unchecked {
    padding-left: 1rem;

    .MuiCheckbox-root {
      color: $border-color !important;
    }
  }

  &-radio-checked {
    padding-left: 1rem;

    .MuiRadio-root {
      color: $theme-color !important;
    }
  }

  &-radio-unchecked {
    padding-left: 1rem;

    .MuiRadio-root {
      color: $border-color !important;
    }
  }

  &-footer {
    padding-top: 4rem;
  }
}
.fx-communication-preference{
  &-sub-title-grid{
    background-color: $grey-background;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;  
    height: 2.33rem;
    padding-top: 0.3rem;
  }
  &-edit-link{
    padding-left: 1rem;
    color: $theme-color;
    font-size: $font-size-h5;
    font-weight: 500;
  }
  &-title{
    font-size: $font-size-h2 !important;
    font-weight: 500;
  }
  &-sub-title{
    font-size: $font-size-h5 !important;
    font-weight: 500;
    padding-left: 0.5rem;
  }
  &-label{
    font-size: $font-size-h3 !important;
    font-weight: 500;
  }
  &-sub-label{
    font-size: $font-size-h5 !important;
    font-weight: 500;  
  }
  &-status-grid{
      margin-top: 0.5rem;
  }
  &-primary-label{
    padding: 0.1rem 0.4rem;
    margin-left: 0.5rem;
    border: 1px solid $grey-text-color;
    border-radius: 0.25rem;
    font-size: $font-size-h5 !important;
    font-weight: 500;
    color: $grey-text-color;
  }
}
.fx-verification-modal{
  .fx-card-header{
    border-bottom: none !important
  }
  &-title{
    font-size: $font-size-h2 !important;
    font-weight: 500 !important;
  }
  &-sub-title{
    font-size: $font-size-h5;
    font-weight: 500;
    color: $text-color-secondary;
  }
  &-divider{
    border: 0.5px solid $border-color;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
  .fx-otp-edit {
    input {
      width: 4.2rem !important;
      border-radius: 0 !important;
    }
  }
  &-channel-sub-title{
    padding-left: 2.1rem;
    font-size: $font-size-h5 !important;
    font-weight: 500 !important;
    color: $text-color-secondary;
  }
  &-channel-title-grid{
    .MuiFormControlLabel-label{
      font-size: $font-size-h3 !important;
      font-weight: 400 !important;
    }
    .MuiFormGroup-root{
      margin-bottom: -0.3rem !important;
    }
  }
  &-trouble-label{
    font-size: $font-size-h3 !important;
    font-weight: 500 !important;
  }
  &-trouble-link{
    font-size: $font-size-h4 !important;
    font-weight: 500 !important;
  }
}
.fx-customer-otp-settings{
  &-title{
    font-size: $font-size-h2 !important;
    font-weight: 500;
  }
  &-label{
    font-size: $font-size-h3 !important;
    font-weight: 500;
  }
  &-sub-label{
    font-size: $font-size-h5 !important;
    font-weight: 500;  
  }
  &-switch-grid{
  }
}
.fx-sales-refund-grid{
  .fx-currency   
  {
    font-style: normal;
    font-weight: 500;    
    &-value {
      font-size: $font-size-h3 !important;
    }
  }
}

.fx-settlement-adjustment-grid{
  h6{
    font-family: $font;
    font-weight: 400 !important;
    font-size: $font-size-h5 !important;
  }

  .fx-currency   
  *{
    font-weight: 400 !important;
    font-size: $font-size-h5 !important;
  }
}

.fx-capture-collect{
  &-header{
    justify-content: center;
    display: flex;
  }
  &-subheading{
    font-size: 0.935rem !important;
      font-weight: 400 !important;
      color: $light-gray-color;
      margin-top: 0.3rem;
  }
  &-description-text{
    color: $text-color-secondary !important;
    font-size: $font-size-h5 !important;
  }
}

.fx-capture-heading{
  justify-content: center;
}
.fx-void-header{
  margin-left: 3rem;
}
.fx-auth-user-portal-access{
  &-title{
    font-size: $font-size-h3 !important;
    font-weight: 600;
  }
  &-label{
    font-size: $font-size-h4 !important;
    font-weight: 500;
    padding-left: 0.4rem;
  }
}
.fx-switch-customer-menu{
 &-avatar{
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 0 !important;
    background-color: $welcome-menu-background;
  }
  
  
  
}
.fx-sidenav-customer-switch-menu {
  &-collapse {
    margin-left: 1rem;
    .fx-popover-label {
      font-weight: 400 !important;
      font-size: $font-size-h5 !important;
    }
    .MuiPopover-paper {
      overflow: visible;
      width: 20rem;
      flex-direction: column;
      background: $menu-color;
      &:after {
        content: " ";
        position: absolute;
        right: calc(100% - 0.125rem);
        bottom: 0%;
        transform: translateY(-50%);
        border-top: 0.7rem solid transparent;
        border-right: 0.7rem solid $label-color-secondary;
        border-left: none;
        border-bottom: 0.7rem solid transparent;
      }
      li {
        width: 100%;
        height: 3.33333rem;
        padding: 0rem 1.25rem;
        gap: 0.83333rem;
        span {
          color: $white;
          font-family: $font;
          font-size: $font-size-h5;
          font-weight: 400;
        }
        p {
          color: $white;
          font-family: $font;
          font-size: $font-size-h4;
          font-weight: 500;
          width: 13rem;
        }
      }
      li:not(:last-child) {
        span {
          opacity: 0.8;
        }
      }
      li:hover {
        background: $welcome-menu-background-hover !important;
      }
      li:last-child {
        height: 3.73333rem;
        padding-bottom: 0.5rem;
        span {
          width: 9.5rem;
          color: $border-color;
          font-size: $font-size-h5;
          line-height: 1.3125rem;
        }
        div:first-of-type>span {
          opacity: 0.6;
        }
      }
    }
    li:last-child {
      span {
        width: auto !important;
        opacity: 0.8 !important;
        color: $white !important;
      }
    }
  }
  &-expanded {
    &-theme-not-sel {
      background: $label-color-secondary;
    }
    &-avatar-theme {
      background: none !important;
    }
    &-avatar-not-theme {
      background: $left-menu-head-background;
    }
    &-popup {
      
      .fx-popover-label {
        font-weight: 400 !important;
        font-size: $font-size-h5 !important;
      }
      .MuiPopover-paper {
        width: 20.4rem;
        display: flex;
        padding: 0 0 0.9rem 0rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.25rem;
        align-self: stretch;
        opacity: 0.8;
        background-color: $menu-color;
        margin-left: -1.3rem;
        margin-top: 4.5rem;
        li {
          width: 100%;
          display: flex;
          height: 3.33333rem;
          align-items: center;
          gap: 0.83333rem;
          align-self: stretch;
          span {
            color: $white;
            font-family: $font;
            font-size: $font-size-h5;
            font-weight: 400;
          }
  
          p {
            color: $white;
            font-family: $font;
            font-size: $font-size-h4;
            font-weight: 500;
            min-width: 12rem;
          }
        }
      }
    }
  }
}
.fx-margin-one-px{
  margin: 1px !important;
}
.fx-create-external-account {
  &-sub-title {
    font-size: $font-size-h5;
    padding-left: 1.0rem;
    
  }
  &-address-container {
    border: 2px solid $border-color;
    border-radius: 0.625rem;
    padding: 1.75rem;
  }
}
.fx-create-contact{
  &-address-container{
    border: 2px solid $border-color;
    border-radius: 0.625rem;
    padding: 1.75rem;
  }
  &-sub-title{
    font-size: $font-size-h5;
    padding-left: 1.0rem;
  }
}

.congrats-clock-timer{
  width: 1.58rem;
  height: 1.58rem;
}

.fx-funding-gain-access {
  h1 span{
    color: $theme-color;
    font-size: 2.5rem;
    font-weight: 700;
  }

  &-priority {
    border-bottom: 1px solid $black-color;
    border-width: medium;
  }
  &-terms-sub {
    font-style: italic;
    font-weight: 500;
  }
}
//styles for account creation drawer
.fx-account-creation{
  &-icon{
    svg {
      path {
        fill: none !important;
      }
    }
  }
  &-name{
    font-size: $font-size-md !important;
    font-weight: 500 !important;
  }
  &-agreement{
    margin-left: 0;
  }
}

.fx-mr-4{
  margin-right: 1rem ;
}

.fx-warning-severity {
  background: $warning-severity !important;
  color: $black-color;
}