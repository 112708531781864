.fx-alert-perferences {
    .fx-alert-perferences-card-body {
        max-height: 60vh;
        overflow-x: auto;
        height: 100%;
        max-width: calc(100vw - 5rem);
    }

    .fx-tab-border {
        .fx-button-group {
            border-radius: 0.375rem !important;
        }
    }

    .alert-perferences-grid {
        .fx-card-header {
            display: block !important;
        }

    }

    .MuiInputBase-formControl {
        height: 2.5rem !important;
    }

    .fx-alert {
        width: 95%;
        padding: 0;

        .fx-card {
            box-shadow: none !important;
            border: $border-card;
        }

        .MuiTypography-h6,
        .MuiTypography-h5 {
            font-family: $font;
        }

        &-tab-button-tab {
            min-height: 3.6rem;
            margin-top: 1.625rem;
        }

        .MuiAccordionDetails-root {
            margin-left: 1.95rem;
        }

        .MuiAccordionSummary-root {
            padding-left: 0;
        }

        &-checkbox {
            .MuiIconButton-colorSecondary:hover {
                background: none;
            }

            .MuiFormControlLabel-root {
                margin: 0;
            }
        }

        &-checkbox.diabled-check-box {
            svg {
                color: $light-grey-color !important;
            }
        }


        &-grid-item-20 {
            min-width: 5.80rem;
            padding: 0.65rem;
            display: flex;
            color: $menu-color;
        }

        &-head-row {
            .fx-group-checkbox {
                width: 0.78rem;
            }
        }

        &-head-row {
            background-color: $table-passport-th-background;
            text-transform: uppercase;
            height: 2.925rem;
            font-family: $font-inter !important;
            align-items: center;

            .MuiGrid-item {
                font-family: $font-inter !important;
                text-transform: uppercase;
                font-weight: 500;
            }

        }

        .header-cell {
            font-family: $font-inter !important;
            font-weight: 500 !important;           
            font-size: $font-size-h5 !important;
            padding-top: 0.98rem !important;

        }

        &-title {
            font-size: $font-size-h3 !important;
            font-weight: 500;
            line-height: 1.725rem;
        }

        &-sub-title {
            color: rgba($label-color-secondary, 0.6);;
            line-height: 2.5rem;
        }      

        &-row {
            border-bottom: 0.13rem solid $light-blue-border;
            height: 5.925rem;
        }

        .fx-disabled * {
            pointer-events: none;
        }

        .diabled-check-box {
            .MuiFormControlLabel-root.Mui-disabled {
                margin: 0;
            }

            .Mui-disabled {
                .MuiButtonBase-root {
                    svg {
                        background: $light-grey-color !important;
                        transform: scale(.75);

                        path {
                            fill: none
                        }
                    }

                    border: none !important;
                }
            }
        }
    }

    .fx-input-edit {
        .Mui-disabled{
            background-color: $balance_background;
        }    
    }

    .fx-select-input{
        .fx-disabled{
            .MuiSelect-select{
                opacity: 1;
                -webkit-text-fill-color: rgba($black-color, 0.38);
            }
        } 
    }

    .fx-alert-preferences-customTrigger-disabled{
        .MuiOutlinedInput-root:hover{
            .MuiOutlinedInput-notchedOutline{
                border-color:$border-color !important;
                border-width: 2px !important;
            }                    
        }
    }

    .fx-form-edit-profile{
        .MuiOutlinedInput-input{
            padding-right: 32px !important;
            font-size: $font-size-h4;               
        }
    }
    
}