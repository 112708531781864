/* passport theme style start */
.fx-theme-passport,
.fx-theme-passport-no-before,
.fx-theme-passport-no-before-reduced-margin {
  background: $white;
  margin-bottom: 2rem;
  border: $border-card;
  border-radius: $border-card-radius;

  .fx-card-header {
    font-family: $font;
    font-size: 1.5rem;
    border-bottom: none !important;
    font-weight: 500;
    display: flex;
    color: $label-color-secondary !important;

    .MuiGrid-container {
      margin-top: 0;
    }

    h3 {
      font-family: $font !important;
      font-size: 1.5rem !important;
      font-weight: 500 !important;
      color: $label-color-secondary !important;
      overflow: hidden !important;
      white-space: nowrap !important;
    }

    h4 {
      font-size: 1.5rem !important;
      font-weight: 500 !important;
      @media (max-width: 600px) {
        font-size: 1.1rem !important;
      }
    }

    h6 {
      font-size: 0.935rem !important;
      font-weight: 400 !important;
      color: $light-gray-color;
      margin-top: 0.3rem;
    }
  }


  .MuiPaper-rounded {
    border-radius: 0;
    box-shadow: none;
  }

  @media (max-width: 600px) {
    .MuiPaper-rounded {
      padding: 0 !important;
    }
  }

  .fx-card-body {
    .MuiDivider-root {
      height: 0.0625rem;
      background-color: $border-color;
      border-color: $border-color !important;
    }

    .fx-label {
      color: $placeholder-color;
      padding-bottom: 1rem;
      font-size: 0.75rem !important;
      font-weight: 500;

      &-large{
        font-size: 1.15rem !important;
      }
    }

    .fx-value {
      font-size: 0.75rem;
      font-weight: 500;
      text-align: right;
    }
  }

  .fx-footer {
    display: flex;
    justify-content: flex-end;
  }
}

.fx-theme-passport-reduced-padding {
  padding: 1.5rem !important;
}

.fx-theme-passport-no-before {
  padding: 2.5rem 3rem !important;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: 0.75rem 1rem !important;

    h3 {
      max-width: 100% !important;
    }
  }

  .fx-card-header {
    h6 {
      margin-top: 0.75rem;
      color: $label-color !important;
      font-size: 1rem !important;
    }
  }
}

.fx-theme-passport-no-before-reduced-margin {
  padding: 1.5rem 3rem !important;
}

//left drawer
.fx-appmenu {
  height: 100%;
  background: $menu-color !important;
  color: $white;
  padding: 2rem 0;

  .fx-logo-passport-menu-icon {
    padding: 0 1rem;


    .fx-link {
      height: 2.5rem;
      svg {
        color: $white;
        width: 11.875rem;
        height: 2.5rem;
      }
    }

    &-close {
      border: 1.5px solid rgba($white, 0.2);
      border-radius: 0.375rem;
      cursor: pointer;
      padding: 0.125rem;
      @media  (max-width: 600px) {
        max-width: max-content;
        max-height: max-content;
        padding: 0.667rem;
      }

      svg {
        color: $white;
        width: 1.5rem;
        height: 1.5rem;
        @media  (max-width: 600px) {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}

.fx-icon-minimize-drawer {
  position: absolute;
  right: 2.9375rem;
  top: 1.5rem;
  border: 0.09375rem solid $min-drawer-border;
  border-radius: 0.375rem;
  height: 3.5rem;
  width: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  background: transparent;
  padding: 0 !important;

  svg {
    width: 1.5rem;
    height: 1.5rem;

    path {
      fill: $light-gray-color;
    }
  }
}

.fx-appbar-top-passport {
  z-index: 901 !important;
  background: $white !important;
  border-bottom: 1.5px solid $border-color;
  box-shadow: none !important;

  .fx-passport-drawer {
    border-radius: 0.375rem;
    border: 0.09375rem solid $grey;
    margin-right: 1.5rem;
    margin-left: 0.25rem;
    height: 3.5rem;
    width: 3.5rem;

    svg {
      width: 1.5rem;
    }
  }

  .MuiToolbar-gutters {
    padding: 0 !important;
    max-height: 7rem;
  }
}

//left menu
.fx-menu-passport {
  top: 0;
  box-sizing: border-box;
  &-profile-space {
      @media (max-width: 600px) {
        padding-left: 0.875rem;
      }
    }
  .fx-menu-passpor-welcome {
    padding: 0 1rem;

    span {
      opacity: 0.5;
      font-size: 1.125rem;
      font-family: $font-inter;
      font-weight: 400;
    }
  }

  svg {
    color: $white;
    width: 1.125rem;
    height: 1.125rem;

  }

  .MuiListItem-gutters {
    padding-left: 0 !important;
  }

  :nth-child(2) {
    max-height: 60vh;
    overflow: scroll;
    @media (max-width: 600px) {
      max-height: 48vh;
    }
  }

  :nth-child(2)::-webkit-scrollbar {
    height: 0;
    display: none;
    width: 0 !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-top: 1.875rem;
    align-items: center;
  }

  ul > li {
    border-bottom: 1px solid rgba($white, 0.1);

    a {
      width: 100%;
      height: 100%;
      color: $menu-li-color;
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    a:hover {
      position: relative;
      display: flex;
      align-items: center;

      &::before {
        position: absolute;
        content: "";
        background-color: $theme-color;
        border-radius: 0 1.875rem 1.875rem 0;
        width: 0.25rem;
        height: 66.6%;
        left: -1rem;
      }

      color: $theme-color;

      svg {
        path {
          stroke: $theme-color;
        }
      }
    }

    > div {
      height: 4.1rem;
      padding: 0 1rem;

      .MuiListItemIcon-root {
        min-width: 0;
        padding-right: 1rem;
      }
    }

  }

  ul > li:not(:last-child) {
    border-bottom: 1px solid rgba($white, 0.1);
  }

  li > li {
    font-size: 1rem;
    padding: 1rem 0;
    text-transform: none;
    font-family: $font-inter !important;
    font-weight: 500;
    background: $menu-color !important;

    a {
      color: rgba($white, 0.5);
      text-decoration: none;
    }

    a:hover {
      color: $theme-color;
      text-decoration: none !important;
    }
  }

  &-account {
    box-sizing: border-box;
    margin: 1.5rem auto 0 auto;
    padding: 1rem 0;
    color: $white;
    background-color: rgba($white, 0.1);
    height: 3.5rem;
    width: 15.625rem;
    display: flex;
    align-items: center;
    border-radius: 0.375rem;

    >div >div {
        padding: 0 1rem;
    }

    &-indicator {
      svg {
        opacity: 0.7;

        circle,
        path {
          fill: $white;
          stroke: $white;
        }
      }

    }

    &-middle {
      padding: 0 0.25rem;
      margin-top: -2px;
      font-size:$font-size-h4;
    }

    &-left {
      svg {
        margin-top: 2px;

        path {
          fill: $theme-color;
        }
      }
    }
  }

  &-bottommenu {
    position: absolute;
    width: 100%;
    bottom: 2rem;
    @media (max-width: 600px) {
      bottom: 0
    }

    > div {
      padding: 1rem !important;
    }

    .MuiAvatar-root {
      height: 2.5rem;
      width: 2.5rem;
      background-color: rgba($white, 0.05);
      border-radius: 0.268rem;
    }

    &-smalltext {
      font-weight: 300;
      opacity: 0.6;
      font-size: 0.875rem;
    }

    .fx-menu-left-back-to-mxm {
      padding: 1.6565rem 1rem !important;

      .fx-mxm-logo {
        transform: scale(1.5);

        rect {
          fill: none;
        }
      }
    }

    .fx-menu-left-logout,
    .fx-menu-left-readme{
      padding: 1.6565rem 1rem !important;
    }

    > div:not(:last-child) {
      border-bottom: 1px solid rgba($white, 0.1);
      padding: 0.5rem 0;
    }

    .fx-link {
      text-decoration: none;
      color: $white;
    }

    .fx-link:hover {
      * {
        color: $theme-color;
      }
    }
  }

  &-bottommenu-welcome {

    > div {
      border-bottom: none !important;
      padding-bottom: 0 !important;
    }

    > div:last-child, > div:first-child {
      padding-top: 0.5rem !important;
      border-top: 1px solid rgba($white, 0.1) !important;
      padding-bottom: 0 !important;
    }

    button:hover{
      color: $theme-color !important;

      .MuiAvatar-root {
        color: $theme-color;
      }
    }

    .fx-link:hover {
      svg{
        path {
          stroke: $theme-color;
        }
      }
    }
    
  }

  &-sel {
    position: relative;
    background: rgba(255, 255, 255, 0.05);
    display: flex;
    align-items: center;

    &::before {
      position: absolute;
      content: "";
      background-color: $theme-color;
      border-radius: 0 1.875rem 1.875rem 0;
      width: 0.25rem;
      height: 66.6%;
    }

    .MuiGrid-container .fx-link {
      color: $theme-color;

      svg {
        path {
          stroke: $theme-color;
        }
      }

    }
    &-selected-portal{
      margin-left: 1.5rem;
      &-label{
        opacity: 0.8;
      }
     
    }
    &-selected-portal-small{
     
      &-label{
        opacity: 0.8;
      }
      &grid{
        display: flex;
        justify-content: center;
      }
      &-collapse-label{
        opacity: 0.8;
        color: $white;
        font-size: $font-size-h6;
       
      }
    }

    .MuiGrid-container:not(.fx-sidenav-bottommenu-main-right):before {
      position: relative;
      content: "";
      background-color: $theme-color;
      border-radius: 0 1.875rem 1.875rem 0;
      width: 0.15rem;
    }
  }
}

//left menu collapse
.fx-menu-passport-collapse {
  position: fixed;
  z-index: 1001;
  bottom: 0;
  background: $collapse-menu-background;
  width: 5.5rem;
  top: 0;

  > div {
    text-align: center;
  }

  &-menu {
    max-height: calc(100vh - 17rem);
    overflow: scroll;

    ul {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 10rem;
    }

    ul > li {
      .MuiListItemIcon-root {
        vertical-align: bottom;
        min-width: 1rem;
        width: 100%;
        padding: 1.4rem 0;

        svg {
          position: relative;
          margin: auto;
          width: 1.625rem;
          height: 1.625rem;

        }
      }

      .fx-link:hover {
        position: relative;
        display: flex;
        flex-direction: column;

        svg {
          path {
            stroke: $theme-color;
          }
        }

        .MuiListItemIcon-root:before {
          position: absolute;
          content: "";
          background-color: $theme-color;
          border-radius: 0 1.875rem 1.875rem 0;
          width: 0.25rem;
          height: 66.6%;
          align-self: center;
        }
      }
    }

    ul > li:not(:last-child) {
      .MuiListItemIcon-root {
        border-bottom: 1px solid rgba($white, 0.1);
      }
    }

    &-sel {
      position: relative;
      display: flex;
      flex-direction: column;

      .MuiListItemIcon-root {
        background-color: rgba($white, 0.05);

        svg {
          path {
            stroke: $theme-color;
          }
        }
      }

      .MuiListItemIcon-root:before {
        position: absolute;
        content: "";
        background-color: $theme-color;
        border-radius: 0 1.875rem 1.875rem 0;
        width: 0.25rem;
        height: 66.6%;
        align-self: center;
      }
    }
  }

  &-menu::-webkit-scrollbar {
    height: 0;
    display: none;
    width: 0 !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &-logo {
    padding-top: 2rem;

    svg {
      width: 3rem;
      height: 3rem;
    }
  }

  &-account {
    box-sizing: border-box;
    margin: 1.5rem 0;
    padding: 0.75rem 0;
    color: $white;
    background-color: $theme-color;
    height: 3rem;

    &-indicator {
      svg {
        transform: scale(0.8);
        opacity: 0.7;
      }
    }

    &-middle {
      padding: 0 0.25rem;
    }

    &-left {
      margin-top: -2px;

      svg {
        transform: scale(0.5);
      }
    }

    svg {

      circle,
      path {
        fill: $white;
        stroke: $white;
      }

    }
  }

  &-expandicon {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 4rem;

    svg {
      width: 3rem;
      height: 6.75rem;

      path {
        fill: ($collapse-menu-background);
      }
    }
  }

  &-bottommenu {
    position: absolute;
    bottom: 2rem;
    left: 25%;

    .MuiAvatar-root {
      height: 2.5rem;
      width: 2.5rem;
      background-color: rgba($white, 0.05);
    }

    .fx-link:hover {
      .MuiAvatar-root {
        color: $theme-color;
      }
    }
  }

  .fx-link {
    text-decoration: none;
  }
}

.fx-top-area-passport {
  box-sizing: border-box;
  padding: 1rem 4rem 1.65rem 10.5rem;
  @media (max-width: 999px) {
    padding: 1rem 2rem 1.65rem 8.5rem;
  }

  @media (max-width: 600px) {
   padding-left: 1.5rem;
   padding-right: 1rem;
  }

  .fx-select-account {
    top: -2px !important;

    :first-child {
      top: 0 !important;
    }

    width: 160px;

    div {
      font-weight: 700 !important;
      font-size: 0.95rem !important;
      font-family: $font-inter-bold;
    }

    [class*="-control"] {
      border: $border-color;
      min-height: 2.25rem !important;

      svg {
        path {
          fill: $light-gray-color !important;
        }
      }

      [class*="-indicatorContainer"] {
        padding: 4px 2px 2px 2px;
      }
    }

    [class*="-control"]:hover {
      border: $theme-hover-color !important;
    }

    [class*="-singleValue"] {
      color: $black-color;
    }
  }

  &-left {

    &-title, &-title > div {
      display: flex;
      align-items: center;
      font-family: $font;
      font-weight: 600;
      font-size: 1.75rem;
      color: $label-color-secondary;
      max-width: 100%;
      margin-top: -0.5rem ;
      -webkit-font-smoothing: initial;
      -moz-osx-font-smoothing: initial;

      &-mobile {
        @media (max-width: 600px) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 1.3rem;
          display: block;
        }
      }

      span.fx-mode-status {
        align-items: center;
        display: flex;
      }

      span:not(.fx-mode-status) {
        font-size: 1.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 800px) {
          width: 70%;
        }
        @media (max-width: 690px) {
          width: 60%;
        }
        @media (max-width: 630px) {
          width: 50%;
        }
        @media (max-width: 600px) {
          font-size: 1.3rem;
          width: 100%;
        }
      }

      .fx-status-filled {
        @media (max-width: 600px) {
          font-size: 0.7rem;
        }
      }
    }
    &-customer{
      font-weight: 500;
      font-size: $font-size-h4;
      color: $label-color-4;
      padding-top: 6px !important;
    }
    &-sub-title{
      font-weight: 500;
      font-size: $font-size-h4;
      color: $label-color-4;
      line-height: 1.5rem;
      padding-top: 6px !important;
      @media (max-width: 600px) {
        padding-top: 0 !important;
      }

    }
    .fx-button-top-back{
      @media (max-width: 600px) {
        width: 2.5rem !important;
        padding: 0 !important;
        height: 2rem !important;
        margin-right: 0.5rem !important;
      }
      svg {
        @media (max-width: 600px) {
          height: auto;
          width: 0.9rem;
        }
      }
    }
  }

  &-right {
    .fx-top-move-money{
      @media (max-width: 600px) {
        width: auto;
      }
      b {
        @media (max-width: 600px) {
          display: none;
        }
      }
    }

    .fx-logout,
    .fx-readme{
      @media (max-width: 600px) {
        padding: 0 !important;
        height: 2.5rem;
        width: 2.5rem;
        margin-left: 0 !important;
      }

      svg {
        @media (max-width: 600px) {
          height: 1.2rem;
          width: 1.2rem;
        }
      }
    }
  }

  // for treasure banner text
  &-text1
  {
    color:$theme-color;
    font-size: $font-size-h2;
    font-weight: 500;
  }
  &-text2{
    font-weight: 500;
    font-size: $font-size-h3;
  }
}


.MuiButton-startIcon {
  margin-right: 0.5rem;

  @media (max-width: 600px) {
    margin-right: 2px;
  }

  svg {
    height: 1rem;
    width: 1rem;
  }
}

.fx-home-top-buttons {
  button {
    margin-right: 1.5rem;
  }
}

.fx-svg-theme {
  svg path {
    fill: inherit !important;
    // fill: $theme-color!important;
  }
}

.fx-table-passport {
  th {
    background-color: $table-passport-th-background !important;
    color: $label-color-secondary !important;
    font-family: $font-inter !important;
  }

  td {
    color: $label-color-secondary;
    border-bottom: 1px solid rgba($label-color-secondary, 0.05) !important;
    font-family: $font;
    font-weight: 400;
    &:first-child {
      border-left: 0.25rem solid transparent;
    }
  }

  &:not(.no-hover-table) tr:hover td{
    background: $tr-hover;
  }

  tr:hover td {
    &:first-child {
      border-left: 0.25rem solid $theme-color;
      border-radius: 0.4rem 0 0 0.4rem;
    }

    &:last-child{
      border-radius: 0 0.4rem 0.4rem 0;
    }
  }

  tr:hover .fx-hover-card{
    display: inline;
  }
}

.fx-quick-settle{
  &-icon{
    svg path{
      color: $theme-color !important;
      fill: $theme-color !important;
    }
    &-height{
      height: 1.60rem;
    }
  }
  &-text{
    color: white;
    text-transform: none;
  }

}

.fx-hover-card{
  display:none;
  background-color: $theme-color;
  margin: 1rem;
}

.fx-batch-id{
  color: $theme-color;
}

.fx-span-float-right{
  float: right;
}


.fx-passport-debit-credit-svg {
  svg {
    height: 3rem;
    width: 3rem;
    display: inline-block;
    vertical-align: middle;
  }

  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  display: flex;
  border-radius: 0.375rem;
}


.fx-passport-amount {
  font-family: $font-nunito !important;
  font-size: 1.0625rem !important;
  font-weight: 700 !important;
  text-align: right;
}

.fx-passport-amount-green {
  color: $passport-credit-color;
}

.fx-passport-amount-gray {
  color: $passport-debit-color;
}

.fx-profile-icon {
  padding: 1rem !important;
  border-radius: 0.416rem !important;
  margin-left: 0.8rem !important;
  height: 3.5rem !important;
  width: 3.5rem;
  border: solid 1px $border-color;
  background-color: $white;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &-admin {
    width: auto;
    color: $label-color-secondary;
    text-transform: none;
    font-family: $font;
    font-size: 1.16rem;
    font-weight: 500;
    margin-right: 0.833rem;
  }
}

.fx-profile-icon:hover {
  svg {
    path {
      stroke: $theme-color;
      fill: $white;
    }
  }
}

//list button styles
.fx-layout-list,
.fx-data-list {
  .fx-button {
    border: 1px solid $btn-fill-background;
    background-color: $white;
    box-shadow: none;
    border-radius: 8px;
    color: $white;

    svg {
      color: $white;

      path {
        fill: $white;
      }
    }
  }

}

/* passport theme style end */

.fx-container-top-move-money {
  background-color: $black-color;
  color: $white;
  padding: 20px 57px;
  z-index: 1;
  left: 4px;
  width: 100%;
  position: relative;

  h1 {
    font-size: 1.125rem;
    font-weight: 700;
    color: $theme-color;
    font-family: $font;
  }

  .fx-move-money-middle {
    border-right: 2px dashed $label-color-secondary;
    border-left: 2px dashed $label-color-secondary;
    padding-left: 36px !important;
    padding-right: 36px !important;
  }

  .fx-move-money-left {
    padding-right: 36px !important;
  }

  .fx-move-money-right {
    padding-left: 36px !important;
  }

  .fx-link {
    text-decoration: none !important;
  }

  .fx-link:hover {
    color: $theme-color !important;

    h1 {
      color: $theme-color !important;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      padding-bottom: 16px;
      white-space: nowrap;

      a {
        text-decoration: none !important;
        cursor: pointer;
        color: $white;
        font-weight: 700;
        font-size: 12px;
        font-family: $font;
        white-space: pre-line;
      }
    }
  }

  .fx-dropdown-shape {
    background-color: $white;
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    top: -2px;
    transform: rotate(45deg);
    left: 260px;
  }

  .fx-icon-move-money {
    width: 20px;
    height: 20px;
    background: $theme-button-background;
    border-radius: 0.375rem;
    padding: 3px;
    margin-right: 10px;

    svg {
      color: $black-color;
    }
  }
}

.home-popover-menu {
  background: $button-passport-background-color;

  .MuiPopover-paper {
    width: 100%;
    margin-top: 50px;
    // min-height:200px;
    left: 0 !important;
    min-width: 100%;
  }
}

/* fx-menu styles start */
.fx-menu {
  margin-top: 15px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;

  .MuiTypography-body1 {
    font-family: $font-header;
    font-size: 14px;
    color: $input-value-color;
  }

  svg {
    width: 30px;
    height: 30px;

  }

  .MuiListItem-root {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

.fx-menu-design {
  border-radius: 0.375rem !important;

  .MuiListItemIcon-root {
    margin-left: -2px !important;
    min-width: auto;
  }
}

.fx-menu-design:hover,
.fx-menu-design-selected {
  background-color: $theme-color !important;
  border-radius: 0.375rem !important;

  .MuiTypography-body1 {
    color: $white;
  }

  path {
    stroke: $white !important;
  }

  rect {
    stroke: $white
  }

  .fx-bulk-download-icon {
    svg {
      fill: $white;
    }
  }
}

.fx-bulk-download-icon {
  svg {
    fill: $input-value-color;
  }
}

.iconButton-margin {
  top: -6px !important;
}

.portalIcon {
  margin-top: 10px;
  margin-left: 20px;

  .MuiIconButton-root:hover {
    background: transparent;
  }
}

.portal-menu-Icon {
  margin-left: -4px;
  width: 40px !important;
  height: 40px !important;
}

.fx-passport-content,
.fx-modal {
  [class*="-control"]:hover {
    box-shadow: none !important;
  }

  .fx-button {
    height: 2.5rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1.0rem;
  }

  .fx-overlay-home {
    background-image: $overlay-background;
    border: 1px solid $border-color;
    border-radius: 0.25rem;
    padding: 1.75rem;
    margin-bottom: 2rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 101%;
    background-color: $white;

    &-info {
      padding-left: 0;
      padding-top: 0.3rem;
      h1 {
        margin-bottom: 1rem;
      }
    }

    h1 {
      font-family: $font;
      font-weight: 600;
      font-size: $font-size-h1;
      line-height: 2.832rem;
      color: $label-color-secondary;
      letter-spacing: 1px;
    }

    h3 {
      font-family: $font;
      font-weight: 500;
      font-size: $font-size-h3;
      color: $label-color-secondary;
      letter-spacing: 1px;
    }


    .fx-card {
     padding: 0;
    }

    &-icon-padding-top {
      padding-top: 0.3rem;
    }

    .fx-kyc-stepper {
      .MuiLinearProgress-root {
        background-color: transparent;
        margin-left: 1rem;
        margin-right: 1rem;
        height: 0.18rem;
        border-radius: 0.5rem;
        .MuiLinearProgress-bar {
            background-color: $theme-color;
            border-radius: 0.5rem;
        }
      }

      .MuiStepper-root {
        border: none !important;
        border-radius: 0.5rem;
      }

      .MuiStepConnector-lineHorizontal {
        margin-top: 0;
      }
      .MuiStepper-root {
        height: 4.5rem;
        padding: 0 19rem;
      }
      .MuiStepLabel-labelContainer {
        .MuiStepLabel-label {
          padding-right: 0.83rem;
          white-space: nowrap;
        }
      }
    }
  }

  &-main {
    overflow-y: scroll;
    padding: 2rem 3.5rem 0 3.5rem;
    @media (max-width: 999px) {
      padding: 0rem;
    }

    display:flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    >div {
      max-width: 1440px !important;
    }

  }

  &-main::-webkit-scrollbar {
    height: 0;
    display: none;
    width: 0 !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &-top {
    width: 6rem;
  }
}

/* fx-menu styles end */


/*passport layout specific scss */
.fx-top-area {
  color: #000;
  margin-top: 0.45rem !important;

  @media (max-width: 600px) {
   align-items: center;
  }
}

.fx-top-overview {
  display: none !important;
}

.fx-logo-passport-top {
  width: 13.75rem;
  height: 3.1875rem;
  margin-right: 2rem;
  margin-top: 2px;
}

.fx-card-general {
  box-shadow: none !important;
}

.fx-passport-content {
  .fx-top-header {
    display: none;
  }
}

.fx-main-grid {
  padding: 0 !important;
  margin: 0;
}

.fx-top-move-money {
  background: $theme-color;
  border: solid 1px $border-color;
  border-radius: 0.416rem;
  text-transform: none;
  color: $white;
  width: 14.24rem;
  height:3.5rem !important;

    font-family: $font;
    font-size: $font-size-h3;
    font-weight: 500;
    padding-left: 0.833rem;

  svg {
    path{
      fill: $theme-color;
      stroke: $white;
    }
    width: 1.25rem;
    height: 1.25rem;
  }
  &-dropdown-fill-color{
    path{
      fill:$white !important;
    }
  }
  

}

.fx-top-move-money-menu {
  margin-top: 1rem;
}


/* mobile view menu icon */
.mobile-view-expand-logo-icon {
  margin-left: 1rem;
  border: 1px solid $theme-button-background-lite;
  border-radius: 0.375rem;
  padding: 0.667rem;
  line-height: 0;
  cursor: pointer;
  svg {
    width: 2rem !important;
    height: 2rem !important;
    path {
      stroke: $theme-color;
    }
  }
}

// for empty grid data icon
.fx-empty-data-icon {
  path.dots {
    stroke: $theme-color;
  }
}
.fx-tab-border {
  .fx-button-group{
      border: 0.063rem solid $border-color;
      padding: 0.4rem;
      border-radius: 0.2rem;
      background-color: $modal-background !important;
  }
  [class~=fx-button-group-def] {
    background-color: $modal-background !important;
  }
}

.fx-quick-settle{
  &-icon{
    svg {
      transform: translatey(0.2rem);
      path{
      color: $theme-color !important;
      fill: $theme-color !important;
    }}
  }
  &-text{
    color: white;
    text-transform: none;
    font-weight: 500;
  }
}
.fx-leads-select{
 min-width: 7.5rem !important;
  [class*="-indicatorSeparator"] {
    display: none;
  }
  [class*="-indicatorContainer"] {
    padding-right: 0.9rem;
    svg {
        path {
            stroke: $theme-color;
        }
    }
  }

  [class*="-ValueContainer"] {
   padding-right: 0;
    padding-left: 0.9rem;
  }
  [class*="-control"] {
    border: none !important;
    box-shadow: none !important;
    &:hover {
      background: $grey-background;
      cursor: pointer;
    }
  }
  [class*="-menu"] {
   margin-top: 0;
  }
  [class*="-singleValue"] {
    color: $theme-color;
    font-weight: 500;
    font-size: $font-size-h3;
  }

  [class*="-option"] {
    padding-left: 0;
    color: $text-color;
    font-weight: 500;
    font-size: $font-size-h3 !important;
  }
}

[class*="css-"].fx-leads-select {
  [id*="-listbox"] > div > div {
    font-size: $font-size-h3 !important;
  }
}
.fx-selected-portal{
  margin-left: 3rem !important;
}

.fx-stepper-success-icon {
    circle {
      fill: $theme-color;
    }
}