$body-background : #F8FAFB;
$login-background : #FFF;
$font-size: 0.833333333vw;
$light-grey:#7a86a8;
$label-sec:#103046;
$grey:#e8eef8;
$blue : #1549dc;
$light-blue: lighten(#b7c1c8, 15%);
$primary-blue: #CFD6DA;
$red : #f12826;
$dark-red: #D34306;
$green : #37f126;
$white : #fff;
$saturated-orange: #FC4C02;
$theme-color:#8BC144;
$theme-button-background:#8BC144;
$theme-button-background-lite: rgba($theme-color, 0.2);
$theme-button-background-medium: rgba($theme-color, 0.5);
$theme-color-head:#31363b;
$theme-input-color:#31363b;

$theme-text-color:#31363b;
$theme-hover-color:rgba($theme-color,0.9);

$btn-def-background:#F2F2F2;
$btn-fill-background:#8BC144;

$chip-def-background:#F2F2F2;
$chip-label-def-color:#4F4F4F;

$paper-background: #f9f9f9;
$card-shadow:   0px 0.625rem 3.125rem rgba(0, 0, 0, 0.06);
$border-shadow-color:rgba(2, 32, 167, 0.12);
$border-shadow:0 2px 6px 0 $border-shadow-color;
$border-color:#F1F4F6;
$border-color-secondary:#F2F2F2;
$border-hover-color: #FBCDA6;
$menu-tab-color:#314172;
$menu-sel-color : #31363b;
$background-color:#31363b;
$placeholder-color:#A0A0A0;
$disabled-placeholder-color: rgba(0, 0, 0, 0.87);
$disabled-button-color:#6D818E;
$tab-color:#757575;
$paragraph-color:#667085;
$menu-color:#103046;
$red-danger:#FF4E4E;
$text-color: #103046;
$text-color-secondary: #6D818E;

$input-value-color:#31363b;
$input-border-color:#F2F2F2;
$input-border-focus-color: #6695e3;
$input-animated-label-color: #adadad;
$label-dark-blue:#27394f;
$left-menu-link-color:rgba(255, 255, 255, 0.98);
$left-menu-head-background:rgba(255, 255, 255, 0.1);

$card-box-shadow : 0px 0.625rem 3.125rem rgba(0, 0, 0, 0.06);
$card-box-shadow-secondary : 0px 2px 5px 0px rgba(0, 0, 0, 0.10);
$quickLink-box-shadow : 0 0 8px 0 rgba(0, 13, 73, 0.08);
$menu-item-box-shadow: 0 2px 6px 0 #10304614;
$box-shadow-top-action: -0.5rem 0.5rem 0.9375rem rgba(121, 121, 121, 0.15);

$overlay-gradient: url(../../assets/svg/background-cloud-mxm.svg);
$overlay-background: url(../../assets/svg/overlay-background.svg);
$main-gradient: linear-gradient(84.56deg, #54934A 0.96%, #ACBE5D 60.98%);
$tick-mark: url('../../assets/svg/tick-mark-mxm.svg');
$top-banner-polygon-1: url("../../assets/svg/polygon1-green.svg");
$top-banner-polygon-2: url("../../assets/svg/polygon2-green.svg");
$top-banner-rectangle-1: url("../../assets/svg/rectangle1-green.svg");
$top-banner-rectangle-2: url("../../assets/svg/rectangle2-green.svg");
$top-banner-polygon-3: url("../../assets/svg/polygon3-grey.svg");
$top-banner-polygon-4: url("../../assets/svg/polygon4-grey.svg");
$top-banner-rectangle-3: url("../../assets/svg/rectange3-grey.svg");
$top-banner-rectangle-4: url("../../assets/svg/rectangle4-grey.svg");
$top-banner-rectangle-5: url("../../assets/svg/rectangle5-grey.svg");
$top-banner-rectangle-6: url("../../assets/svg/rectangle6-grey.svg");

$processing-line: url('../../assets/svg/processing-summary-line-mxm.svg');
$terminal-line: url('../../assets/svg/txn-terminal-status.svg');
$divider-color-gray:#b7c1c8;

$top-area-border-bottom :#dcdfdf;
$border-buttom-view-all : #D5DEED;

$dark-theme-color:#289643;
$theme-color-svg-icon:#94C940;
$theme-color-lite-svg-icon:#D4EB89;
$status-green:#24b323;
$button-cancel:#f2f5fd;
$button-hover:#dddddd;

$dark-grey: #6B7D8A;

$balance_background:#fff;
$button-blue:#3c70e2;
$button-dark-blue: #103046;

$balance-color :#31363b;
$modal-background:#ffffff;
$grey-table-background:#f5f7f7;

$divider-color: #f2f1f1;
$button-secondary-border-color: #ccc;
$button-secondary-background-color: #fff;
$button-passport-background-color:rgba(0, 0, 0, 0.05);
$button-passport-border-color:rgba(#8BC144, 0.2);
$button-passport-hover-color: linear-gradient(0deg, #F58220, #F58220), #FFFFFF;
$button-delete-background:rgba(255, 78, 78, 0.15);
$button-lite-background: rgba($theme-color, 0.15);
$button-border:#e6e6e6;
$warning-severity: #FFD73F; 

$select-account-border:2px solid rgba(244, 130, 32, 0.2);

$summary-border-color:#F2F2F2;
$devider-border-color:#BDBDBD;
$chip-border-color: #E0E0E0;
$light-green-color:#00B05C;
$black-color:black;
$vertical-divider-border-color:#4F4F4F;
$light-gray-color: #828282;
$dark-gray-color:#333333;

$table-passport-th-background:#F8FAFB;
$success-background: rgb(0, 176, 92);
$debit-background:  #f6f6f6;
$debit-background-second:  #F8FAFB;
$pagination-select:  #F8FAFB;
$passport-credit-color:#00B05C;
$passport-debit-color:#333333;
$passport-home-sub-heading-color:rgba(79, 79, 79, 0.8);
$passport-home-sub-heading-background-color:rgba(242, 242, 242, 0.5);
$th-background:#fafafa;
$min-drawer-border:#262626;
$popover-box-shadow:rgba(0, 0, 0, 0.1);
$tr-hover:rgba($theme-color,0.05);
$tab-box-shadow: rgba(0, 0, 0, 0.08);
$disabled-color: #F8FAFB;
$disabled-gray-color: #9FACB5;
$hover-color: rgba($theme-color,0.05);
$hover-color-lite: rgba($theme-color,0.025);
$light-green-background-color:#00B05C15;
$border-button-theme-color:0.09375rem solid rgba($theme-color, 0.2);
$background-gray-color: #FBFBFB;

//label colors
$label-color:#06253B;
$label-color-large : #06253B;
$label-color-medium : #06253B;
$label-color-secondary:#103046;
$label-color-third:#808080;
$label-color-4:#6D818E;
$label-color-5:#b7c1c8;


//grey varaints
$grey-background:#F8FAFB;
$dark-grey-background:#ECF0F2;
$grey-text-color:#708390;
$kyc-left-menu-background: #103046;
$collapse-menu-background:#103046;
$menu-li-color:$white;
$light-blue-border:#F1F4F6;
$light-grey-color:#D5DEED;
$status-green-fill: #D9F3E7;

$border-color: #ECF0F2;
$border-card:1px solid #ECF0F2;
$border-card-radius:0.25rem;
$welcome-menu-background:#1C3A4F;
$welcome-menu-background-hover:rgba(255, 255, 255, 0.10);

// for investment allocation card
$manage-income-color: #6D818E;
$manage-treasure-color: #3E586A;
$light-orange-background: #FFF9F3;

$background-drawer: url("../../assets/svg/drawer-green-background.svg") no-repeat bottom -1.5rem right; 

//required property for non default css
.fx-input-edit{
    [class*="-control"]{
        border-color: $border-color;
        border-width:1px;
        box-shadow: 0 0 0 1px $border-color;
        max-width: calc(100% - 2px);
        margin-left: 1px;
       }
    [class*="-control"]:hover{
        border-color: $theme-hover-color;
        border-width: 1px;
        box-shadow: none;
       }
       [class*="dxg-control"]{
        border-color: $theme-hover-color;
        box-shadow: none;
       }
}
