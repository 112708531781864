//captcha
.fx-recaptcha {
  transform: scale(0.85);
  transform-origin: 0 0;
}

//using for signup stage in kyc
.fx-signup-stage {
  ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin-bottom: 0.75rem;

    li>ul {
      list-style: disc;
      padding-left: 3.5rem;

      li {
        color: $theme-color;;
      }
    }
  }

  li:not(:last-child) {
    padding: 0.75rem 0;
  }

  &-selected {
    h5 {
      color: $white !important;
    }

    .MuiBadge-root {
      span {
        background-color: $theme-color !important;
        color: $white !important;
      }
    }
  }

  .MuiBadge-root {
    padding-right: 0.5rem;
    span {
      color: $text-color-secondary;
      background-color:$welcome-menu-background;
      transform: none;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: calc(2.5rem/2);
      position: relative;
    }
  }

  h5,
  h6 {
    padding: 0 1rem !important;
  }

  .MuiGrid-item {
    h5 {
      color: $disabled-button-color;
    }
  }

  .fx-list-item {
    font-size: $font-size-h3 !important;
  }

  h6 {
    font-weight: 500 !important;
    color: $disabled-gray-color !important;
    font-size: $font-size-h3 !important;
    margin-bottom: 1rem;
  }

  &-icon {
    svg {
      width: 1.1rem !important;
      transform: translate(1.75rem, 1.125rem);
      position: absolute;
      z-index: 999;
    }
  }
  &-mobile {
    h5 {
      color: $text-color !important;
      font-weight: 600 !important;
    }
}
}

.fx-login-info-label {
  color: rgba($label-color, 0.8) !important;
  font-family: $font !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
}

//kyc agreement area
.fx-kyc-agree {
  position: relative;
  margin-top: 2rem;

  &-pdf {
    border: 1px solid $button-border;
    border-radius: 8px;
    padding: 0.5rem;
    height: 66vh;
    overflow-y: scroll;

    iframe {
      border: 0;
    }
  }
  &-title {
    font-size: $font-size-h3;
    font-family: $font-header;
    font-weight: 500;
    color: $theme-color-head;
    padding-top: 0.25rem;
    padding-bottom: 1px;
    white-space: normal;
  }

  &-label {
    font-size: 1rem;
    font-weight: 500;
    color: $label-color-secondary;
    display: flex;
    padding-top: 1rem;
    .MuiTypography-body1{
      font-weight: 500 !important;
      font-size: 0.875rem !important;
    }
  }

  &-label:after {
    content: "";
    position: relative;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: url(../../../assets/svg/horizontal-gray-light.svg);
    background-repeat: repeat-x;
    background-size: contain;
    user-select: none;
    pointer-events: none;
    margin-top: 1rem;
  }

  .fx-kyc-agree-label.fx-review-business-signup-agree:after {
    background-image: none;
  }

  .MuiFormControlLabel-root{
    align-items: flex-start !important;
    .MuiCheckbox-root{
      padding: 0 0.5rem !important;
    }
  }

}

.fx-kyc-privacy {
  @media (max-width:600px) {
    margin-left: 3rem;
  }
}
//kyc agreement area ends here

//kyc business page
.fx-info-kyc {
  h4,
  h5,
  h6 {
    font-family: $font;
  }

  h4 {
    color: $dark-gray-color;
    font-size: 1.5rem;
    font-weight: 600;
  }

  h5 {
    color: $label-color-secondary !important;
    font-size: $font-size-h2;
    font-weight: 500;
    background-color: transparent !important;
  }

  h6 {
    color: $label-color-secondary;
    font-size: $font-size-h3;
    font-weight: 400;
    font-family: $font;
  }

  &-view {
    .fx-button {
      height: 3rem !important;
      svg{
        height: 1rem;
        width: 1rem;
      }
    }

    .fx-button-delete{
      background-color: transparent !important;
      color: $label-color-secondary !important;
    }

    &-topbutton {
      position: absolute;
      transform: translate(0, -0.5rem);

      .fx-button {
        height: 2.75rem;
        width: 2.75rem;
        background-color: transparent;
        padding: 0;
      }
    }

    .fx-info-label-value-container {
      padding-bottom: 1rem;

      .MuiTypography-h5 {
        background-color: $white;
        border-radius: 6px;
        color: rgba($label-color-secondary, 0.6);
        font-weight: 500;
        font-size: 0.875rem;
      }

      .fx-info-label-value div:nth-child(1) {
        font-weight: 500;
      }

      .fx-info-label-value div:nth-child(2) {
        font-weight: 500;
      }
    }
  }

  &-edit {
    padding: 0 !important;
  }

  .fx-card-header {
    font-size: $font-size-md !important;
  }
}

//kyc location page
.fx-info-kyc-new {
  h4,
  h5,
  h6 {
    font-family: $font;
  }

  h4 {
    color: $dark-gray-color;
    font-size: 1.5rem;
    font-weight: 600;
  }

  h5 {
    color: $label-color-secondary !important;
    font-size: $font-size-h2;
    font-weight: 500;
    background-color: transparent !important;
  }

  h6 {
    color: $label-color-secondary;
    font-size: $font-size-h3;
    font-weight: 400;
    font-family: $font;
  }

  &-view {
    .fx-button {
      height: 3rem !important;
      svg{
        height: 1rem;
        width: 1rem;
      }
    }

    .fx-button-delete{
      background-color: transparent !important;
      color: $label-color-secondary !important;
    }

    &-topbutton {
      position: absolute;
      transform: translate(0, -0.5rem);

      .fx-button {
        height: 2.75rem;
        width: 2.75rem;
        background-color: transparent;
        padding: 0;
      }
    }

    .fx-info-label-value-container {
      padding-bottom: 1rem;

      .MuiTypography-h5 {
        background-color: $grey-background !important;
        border-radius: 0.33333rem;
        padding: 0.41667rem 1.25rem;
        color: $text-color;
        font-weight: 500;
        font-size: $font-size-h4;
      }
      .MuiTypography-body1{
        font-weight: 500;
        font-size: $font-size-h4;
      }

      .fx-info-label-value{
        padding: 0rem 1.25rem;
      }

      .fx-info-label-value div:nth-child(1) {
        font-weight: 400;
        font-size: $font-size-h4;
      }

      .fx-info-label-value div:nth-child(2) {
        font-weight: 400;
        font-size: $font-size-h4;
      }
    }
  }

  &-edit {
    padding: 0 !important;
  }
}

//signup-kyc specific
.fx-signup-formkyc {
  &-mobile{
    height: calc(70vh - 7.5rem);
  }
  height: calc(100vh - 7.5rem);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;


  &-topblock {
    &-icon {
      margin-right: 1rem;
      transform: scale(0.9);
      svg {
         g {
           path {
             stroke: $text-color;
           }
         }
      }
      path {
        stroke: $text-color;
      }
    }
    &-heading{
      font-size: $font-size-h5 !important;
      color: $label-color !important;
      margin-top: 0.75rem !important;
      letter-spacing: 0.5px !important;;
    }
  }

  .fx-info-card-form {
    margin-top: 0.5rem;

    .fx-card-header {
      font-size: 1.5rem !important;
    }
  }
  h6{
    font-family: $font !important;
    color: $label-color-secondary !important;
  }
  .MuiFormControlLabel-root{
    align-items: flex-start !important;

    .MuiFormControlLabel-label{
      color: $text-color !important;
    }

    .MuiCheckbox-root{
      padding-top: 0 !important;
      padding-right: 0.5rem !important;
      padding-bottom: 0 !important;
    }
  }
  .Mui-checked{
    svg{
      color:$theme-color;
    }
  }
  .fx-group-radio{
    .MuiFormControlLabel-root{
      align-items: center!important;
      &-input-value{
        @media (max-width:600px) {
          padding-left: 3rem;
        }
      }
    }
  }
  
  &-payment-id-options{
      span.MuiFormControlLabel-label {
        font-weight: 400 !important;
      }
    .MuiFormControlLabel-root{
      align-items: center !important;
    }    
  }

  &-info-text {
    font-size: $font-size-h3;
    font-weight: 400;
  }

  &-subtitle {
    color: $text-color;
    font-size: $font-size-h3 !important;
    font-weight: 500 !important;
    &-secondary {
      color: $text-color;
      font-size: $font-size-h4 !important;
      font-weight: 400 !important;
    }
  }

  &-radio-edit {
    span.MuiFormControlLabel-label {
      font-size: $font-size-h3 !important;
      color: $text-color;
    }
  }

  &-account-identifier-form-card {
    .fx-card-body h6 {
      margin-top: 1.5rem;
      letter-spacing: normal;
    }
  }
}

.fx-signup-formkyc::-webkit-scrollbar{
  display: none;
  width: 0 !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
//signup-kyc specific end here


/* edit profile styles start */
.fx-form-edit-profile {
  .MuiInputLabel-formControl {
    font-family: $font;
    font-size: 12px;
  }

  .form-label {
    font-size: 13px;
    font-family: $font-regular;
    text-align: left;
    color: $input-animated-label-color;
  }

  .MuiOutlinedInput-input {
    font-family: $font;
    font-size: 0.875rem;
    border-radius: inherit;
    padding: 1.03125rem 0.8125rem !important;
  }

  .border-error-input,
  .Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border-color: $red !important;
      border-width: 1px !important;
    }

    [class*="-control"] {
      border-color: $red !important;
      border-width: 1px;
    }
  }

  h5 {
    color: $light-gray-color;
    background-color: $passport-home-sub-heading-background-color;
    padding: 4px 0;
  }
}

.fx-join-tenancy-co-owner{
  .fx-co-owner-agreement-sub-title{
    background-color: $table-passport-th-background;
    border-radius: 0.75rem;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  .fx-info-kyc-view {
    .fx-info-label-value-container{
    .MuiTypography-h5{      
      background-color: $table-passport-th-background !important;
      border-radius: 0.375rem;
      padding: 0.5rem 0.75rem;
      margin-bottom: 1.25rem;
      color: rgba($label-color-secondary, 0.8);
      font-weight: 500;
      font-size: $font-size-h4;
    }
    }
  } 
  .fx-info-card {
    .fx-info-card-form {
       .MuiTypography-body1
        {
          font-size:$font-size-h3;
        }
      }
  }
}
/* edit profile styles end */

.fx-review-info-card {
  border-radius: 0.5rem;
  border: 1px solid $border-color;
  padding: 1.75rem;
  color: $text-color;
  &:hover {
    border-color: $border-hover-color;
    box-shadow: $card-box-shadow-secondary;
    cursor: pointer;
  }
  &-header {
    margin-bottom: 1.75rem;
    h3 {
      font-size: $font-size-md;
      font-weight: 700;
      font-family: $font;
    }
  }
  &-item-header {
      font-family: $font;
      font-size: $font-size-md;
      font-weight: 600;
      margin-bottom: 0.5rem;
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: initial;
  }
  &-data-item-info {
    font-size: $font-size-h3 !important;
  }
  &-data-item-auth-signatory-label {
    border-radius: 2rem;
    border: 1px solid $border-color;
    padding: 0.33rem 0.83rem;
  }
  &-data-item:not(:last-child) {
    margin-bottom: 0.42rem;
  }
  &-item:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  .fx-info-kyc-view-topbutton {
    position: static;
  }
}
