/* button styles start */
.fx-button {
  height: 3rem;
  border-radius: 0.375rem;
  box-shadow: none;
  background-color: $btn-def-background;
  color: $light-gray-color;
  text-transform: uppercase;
  font-family: $font;
  font-size: $font-size-h4;
  font-weight: 500;
  letter-spacing: 0.5px;

  @media (max-width: 600px) {
    min-width: 0;
  }

  .MuiButton-startIcon {
    margin-left: 0;
  }

  .MuiButton-endIcon {
    margin-right: 0;
  }

  .MuiIconButton-label {
    text-transform: uppercase;
    font-family: $font;
    font-size: 1.0625rem;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  &-theme-disabled {
    background-color: rgba($theme-color, 0.5) !important;
    color: $white;
  }
}

.fx-button:hover {
  filter: invert(5%) contrast(120%);
}

.MuiButton-root {
  padding: 0.75rem 1rem !important;
  letter-spacing: normal;
}

.fx-button .divider {
  border-left: 1px solid $grey;
  margin-left: 0.3125rem;
  margin-right: -0.3125rem;
  height: 1.6875rem;
  padding: 0.4rem 0.625rem 0 0.625rem;
}

.fx-button-white {
  background-color: #fff;
}

.fx-button-blue {
  background-color: $button-blue;
  color: $white;

  .MuiSvgIcon-root {
    stroke: $white;
  }

  span {
    font-family: $font-regular !important;
  }
}

.fx-button-theme {
  background-color: $theme-button-background;
  color: $white;
  .fx-button-circular-progress {
    margin-right: 0.5rem;
  }

  .MuiSvgIcon-root {
    stroke: $white;
  }

  svg {
    path {
      stroke: $white;
    }
  }

  span {
    font-family: $font-regular;
    text-transform: uppercase;
  }
}

.fx-button-theme:hover {
  // filter: contrast(120%);
}

.fx-button-grey {
  background-color: $grey;
}

.fx-button-red {
  color: #fff !important;
  background-color: $red !important;
  font-weight: bold;
}

.fx-button-green {
  color: #fff !important;
  background-color: $green !important;
  font-weight: bold;
}

.fx-button-icon {
  svg {
    width: 1rem;
    margin-right: 0.125rem;
  }
}

.fx-button-delete {
  color: $red !important;
  background-color: $button-delete-background !important;
  border-color: $button-delete-background !important;
}

.btn-actions-left button {
  margin-right: 0.625rem;
}

.btn-actions button {
  margin-left: 0.625rem;
}

.fx-button-danger {
  color: $red-danger !important;
  background: $white !important;
  border: none !important;

  svg {
    path {
      fill: none !important;
    }
  }
}

.fx-button-reset {
  color: $label-color-secondary;

  svg {
    path {
      stroke: $label-color-secondary;
    }
  }

  border: none !important;
  background: $white !important;
}

.fx-button-lite {
  color: $theme-color !important;
  border: none !important;
  background: $button-lite-background !important;

  svg path {
    stroke: $theme-color;
  }
}

.fx-button-passport-border {
  border: 0.09375rem solid $button-passport-border-color !important;
  color: $theme-color !important;
  background: $white !important;
  padding: 0.125rem 0.625rem !important;
  height: 2.5rem !important;
  text-transform: uppercase;
  font-size: 0.875rem;
  &-kyc{
    @media (max-width:600px){
    margin-left: 3rem;
    }
  }
  svg {
    padding-top: 0 !important;
    transform: scale(0.7, 0.7);

    path {
      stroke: $theme-color;
      fill: transparent;
    }
  }
}

.fx-button-passport-border:hover {
  background: $button-lite-background !important;
}

.fx-button-action,.fx-button-action-no-border,
.fx-button-action-fill {
  background-color: $grey-background !important;
  height: 2.5rem !important;
  border: $border-button-theme-color !important;
  box-shadow: none;
  border-radius: 0.6rem;
  color: $label-color-secondary;

  svg {
    width: 1rem;
  }
}

.fx-button-action, .fx-button-action-no-border {
  svg {
    path {
      fill: none !important;
      stroke: $label-color-secondary !important;
    }
  }
}

.fx-button-action-no-border {
  border: none !important;
}

.fx-button-action-fill {
  svg {
    path {
      fill: $white !important;
    }
  }
}

.fx-button-dotted {
  border: .09375rem solid $button-passport-border-color !important;
  background: $white !important;
  min-width: 1rem;
  color: $theme-color !important;
  height: 2.5rem !important;
  padding: 0.75rem 0.75rem !important;

  svg {
    width: 1rem;
    height: 1rem;
  }

  &-icons{
    border: .09375rem solid $dark-grey-background !important;
    color: $disabled-button-color !important;
    svg {
      width: 1rem;
      height: 1rem;
      path {
        fill: $disabled-button-color !important;
      }
    }
  }
}

.fx-button-delete-icon {
  svg {
    width: 1.5rem;
    height: 1.5rem;

    path {
      stroke: $theme-color !important;
    }
  }

  background-color: transparent !important;
}

.fx-button-accept {
  color: $light-green-color;
  background-color: rgba($success-background, 0.15);
}

.MuiButton-endIcon {
  padding-top: 0.125rem;
}

.MuiButton-root {
  padding: 0.375rem 0.625rem;
}

.fx-button-disabled {
  background-color: $disabled-color !important;
  color: rgba($label-color, 0.2) !important;

  svg {
    path {
      stroke: rgba($label-color, 0.2) !important;
    }
  }
}

.fx-forbidden {
  cursor: not-allowed;
}

.fx-button-filter {
  color: $theme-color !important;
  background: $white !important;
  font-weight: 500 !important;
  height: 2.5rem !important;
  text-transform: uppercase;
  border: 1px solid $theme-color !important;
  padding: 1rem !important;

  &:hover {
    background: $button-lite-background !important;
  }


  .MuiButton-startIcon {
    svg {
      path {
        stroke: $theme-color !important;
        fill: $white !important;
      }
    }
  }
}

.fx-button-group {
  box-shadow: none;

  &-def {
    background-color: $btn-def-background !important;
    color: $light-gray-color !important;;
  }

  span {
    font-family: $font-regular;
    text-transform: none !important;
  }

  &-sel {
    background-color: $theme-button-background !important;
    color: $white !important;
  }

}

.fx-button-passport {
  box-shadow: none;
  background: $button-passport-background-color !important;
  border-radius: 0.375rem !important;
  height: 3rem !important;
  border: none !important;
  color: $black-color !important;

  span {
    font-size: 1.0625rem !important;
    font-family: $font-inter-medium !important;
    text-transform: none !important;
    font-weight: 500 !important;
  }

  svg {
    color: $black-color;
    width: 1.5rem;
    height: 1.5rem;
  }

  .fx-svg-up {
    margin-top: -3px;
  }
}

.fx-button-passport:hover {
  background-color: $button-lite-background !important;
  color: $theme-color !important;

  svg {
    path {
      fill: $theme-color;
    }
  }
}

.fx-button-passport-sel,
.fx-button-large,
.fx-button-round {
  background-color: $theme-button-background !important;
  color: $white;
  span {
    color: $white !important;
  }

  svg {
    path {
      fill: $white;
      stroke: $white;
    }
  }

  .MuiButton-endIcon {
    svg {
      color: $theme-color;

      path {
        fill: $theme-color;
      }
    }
  }
}

.fx-button-large {
  svg {
    path {
      fill: transparent !important;
    }
  }
}

.fx-button-large:hover {
  background: $theme-hover-color !important;
  color: $white !important;
}

.fx-button-large {
  width: 13rem !important;
  height: 3.332rem !important;
  color: $white !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  svg {
    width: 1rem !important;
  }
}

.fx-button-large-nobackground {
  width: 10.078rem !important;
  background-color: $white !important;
  color: $theme-color;
  border: 1.5px solid rgba($button-passport-border-color, 0.2) !important;
  border-radius: 4px !important;
}

.fx-button-view-all {
  background-color: $white;
  color: $label-color-secondary;
  border: 1.5px solid $border-buttom-view-all !important;
  border-radius: 0.5rem;
  margin-top: 1.56rem;

  svg {
    width: 1.1rem;
    height: 1.1rem;

    path {
      stroke: $label-color-secondary;
    }
  }
}

.fx-button-view-all:hover {
  background-color: $button-lite-background;
}

.fx-button-cancel {
  color: $theme-color !important;
  border: 1.5px solid $theme-button-background-lite !important;
  background-color: $white;
}

.fx-button-cancel:hover {
  filter: invert(2%);
}

.fx-button-top-back {
  width: 3rem !important;
  background-color: $white !important;
  border: 1.5px solid rgba($theme-color, 0.2) !important;

  svg {
    width: 0.875rem;
    height: 0.8125rem;
    path {
      stroke: $theme-color;
    }
  }

  margin-right: 1rem !important;
  border-radius: 0.5rem !important;
  min-width: 1rem !important;
  padding: 0.5rem 1rem !important;
  height: 2.5rem !important;
}

.fx-button-rounded {
  min-width: 2rem !important;
  border-radius: 28% !important;
  padding: 0.125rem !important;
  border: 1px solid $theme-color-head !important;
  background: $white;
  width: 2.5rem;
  height: 2.5rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
}


.fx-button-orange {
  background-color: #e96919 !important;
  color: #fff !important;

  .MuiButton-startIcon {
    svg {
      path {
        fill: #fff;
      }
    }
  }

}

.fx-button-orange:hover {
  background-color: #c45712 !important;
}

.fx-button-submit:hover {
  background-color: #2e5cdd !important;
}

.fx-button-saturated-orange {
  box-shadow: none;
  border-radius: 0.33333rem !important;
  border: none !important;
  color: $white !important;
  font-weight: 500 !important;
  font-size: $font-size-h4 !important;
  background-color: $saturated-orange !important;
  padding: 1.5rem 2.2rem !important;
  letter-spacing: 0.03rem;
}

.fx-button-signup-saturated-orange{
  box-shadow: none;
  border-radius: 0.41667rem !important;
  border: none !important;
  color: $white !important;
  font-weight: 400 !important;
  font-size: $font-size-h5 !important;
  background-color: $saturated-orange !important;
  padding: 0.58333rem 0.83333rem !important;
}

.fx-reject-button{
  font-weight: 500 !important;
  font-size: $font-size-h4 !important;
}

.fx-button-gradient {
  background: $main-gradient !important;
}
/* button styles end */


/* link styles */
.fx-link {
  color: $blue;
  background: none !important;
  cursor: pointer;
}

.fx-link :hover {
  color: #0c339c;
  text-decoration: none;
}
/* link styles end */


/* switch styles start */
.fx-switch {
  padding: 0.5rem;
  width: 4.0rem;
  height: 3.125rem;
  .Mui-checked {
    .MuiSwitch-thumb {
      background-color: $white;
    }
  }

  .Mui-checked.Mui-disabled .MuiSwitch-thumb {
    background-color: currentColor;
  }
  .MuiSwitch-thumb {
    width: 1.5rem;
    height: 1.5rem;
  }

  .Mui-checked+.MuiSwitch-track {
    background-color: $theme-color;
    opacity: 1;
  }

  .Mui-disabled + .MuiSwitch-track {
    background-color: #000;
    opacity: 0.12;
  }

  .Mui-disabled {
    color: #f5f5f5 !important;
  }

  .MuiSwitch-track{
    border-radius:1rem;
  }
  .MuiSwitch-switchBase{
    padding: 0;
    transform: translate(50%, 50%);
  }
  .MuiSwitch-switchBase.Mui-checked {
    transform: translate(100%, 50%);
  }
}

.fx-switch-tick {
  .Mui-checked {
    .MuiSwitch-thumb {
      &::after {
        transform: translate(50% ,50%);
        width:0.75rem;
        height: 0.75rem;
        content:'';
        background-image: $tick-mark;
        display: block;
        background-size: 0.75rem 0.75rem;
      }
    }
  }
  .Mui-checked.Mui-disabled .MuiSwitch-thumb {
    &::after {
      background-image: url("../../assets/svg/tick-mark-gray.svg");
    }
  }
}

.fx-switch-thin {
  width: 2.75rem;
  height: 1.33rem;
  padding: 0;

  .MuiSwitch-thumb {
    box-sizing: border-box;
    width: 1rem;
    height: 1rem;
  }

  .MuiSwitch-switchBase{
    padding: 0;
    margin: 2px;
  }

  .MuiSwitch-switchBase{
    transform: none;
  }
  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(1.4rem);
  }
}

.fx-switch-thin.fx-switch-tick {
  .Mui-checked {
    .MuiSwitch-thumb {
      &::after {
        width:0.5rem;
        height: 0.5rem;
        background-size: 0.5rem 0.5rem;
      }
    }
  }
}
/* switch styles end */


/* toggle button styles start */
.fx-toggle-button {
  margin-bottom: 0.625rem !important;

  .MuiToggleButton-root {
    color: black;
  }

  .MuiToggleButton-root.Mui-selected {
    color: white;
    background-color: blue;
  }

  button {
    padding: 0.625rem 0.625rem 0.5625rem;
    font-family: $font !important;
    font-size: 1rem !important;
    text-transform: capitalize;
    line-height: 1;
    background-color: $grey;
    border-color: $grey;
  }

  button.Mui-selected {
    background-color: $background-color !important;
  }
}
/* toggle button styles end */



/* toolbar styles start */
.fx-toolbar {
  flex-grow: 1;
  background: transparent;

  .MuiPaper-root {
    background: transparent;
    color: $input-value-color;
    box-shadow: none;
  }

  .MuiToolbar-root>div {
    margin-right: 0.625rem;

    h3 {
      font-size: 0.875rem;
      font-weight: bold;
    }
  }

  .MuiToolbar-root>div:last-child {
    margin-right: 0;
  }
}

.fx-toolbar-nomargin {
  .MuiToolbar-gutters {
    padding-left: 0;
    padding-right: 0;
  }
}

.fx-toolbar-card {
  flex-grow: 1;
  background: transparent;

  .MuiPaper-root {
    background: transparent;
    color: $input-value-color;
    box-shadow: none;
  }

  .MuiToolbar-root>div {
    margin-right: 0.625rem;
  }

  .MuiToolbar-root>div:last-child {
    margin-right: 0;
  }

  .MuiToolbar-regular {
    min-height: 3rem;
    padding-left: 0;
    padding-right: 0;
  }

  header {
    .MuiToolbar-gutters {
      .fx-button {
        margin-top: 0.9375rem;
        margin-Bottom: 0.9375rem;
      }
    }
  }
}
/* toolbar styles end */

// Pass to Tooltip PopperProps
.fx-tooltip-popper-forbidden{
  .MuiTooltip-tooltip {
    color: $white;
    background-color: $menu-color;
  }
}
.fx-grid-margin-bottom-mobile{
  @media  (max-width: 600px) {
    button:first-child {
    margin-bottom: 0.5rem!important;
      }
    }
  }

  .fx-investment-link {
    color: $theme-color;
    background: none !important;
    cursor: pointer;
    font-size: $font-size-h5 !important;
  }

  .fx-buttom-full-width{
    width: 100% !important;
  }

  .fx-button-action-reject{
    .MuiCircularProgress-colorPrimary svg{
      color: inherit !important;
      width: auto;
    }
  }

.fx-badge{
  .MuiBadge-badge{
    background-color: $theme-color;
    color: white;
  }
}

/*Split funding styles Start*/
.fx-funding-button-dotted {
  svg {
    path {
      fill: $theme-color !important;
    }
  }
}

.funding-splitrule-criteria-section {

  &-firstsplit{
    margin-top: 2rem;
  }
  &-non-firstsplit{
    margin-bottom: 1rem;
  }

  .MuiPaper-rounded {
    box-shadow: 0 0 0 1px $border-color;
  }
  
  &-dropdowncontact {
    .MuiPaper-root{
      overflow: visible;
    }
  }

&-split-divider {
     border-left:1px solid $border-color;
     border-right:1px solid $border-color;
     margin-top: 2.3rem;
     height: 3.5rem;
}

  &-firstAction{
    margin-top: 5.2rem;
  }
}

//Css for split funding association card
.split-funding-association {
  .MuiOutlinedInput-root{
    padding: 0.8rem !important;
  }
  .MuiInputBase-root{
    padding: 0 !important;
  }
  

  .fx-optionsparam{
    padding: 1rem !important;
    background-color: $debit-background-second;

    .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }
    
    .MuiSelect-select{
      width: 4rem;
      border-width: 0 !important;
    }

    svg{
      color: $theme-color !important;
    }

  }
}

[class*="css-"].fx-funding-select-contact {
  [id*="-listbox"] > div{
    height: auto;
    max-height: 12rem; 
  }
}

.fx-account-select {
  .MuiOutlinedInput-input {
    .fx-select-prefix, .fx-selected-view  {
      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

.fx-account-transfer-form {
  .fx-balance-info {
    color: $text-color-secondary;
    font-size: 1rem;
    font-style: italic;
    display: flex;
    .fx-currency {
      .fx-currency-value {

        font-size: 1rem;
      }
    }
  }
}


/*Split funding styles end*/