//fx top banner
.fx-top-banner {
  position: fixed;
  width: 100%;
  z-index: 1002;
  background-color: $grey-background;
  height: 3.87rem;
  display: flex !important;

  &-shapes-background {
    height: 100%;
  }

  &-left-side {
    background: $top-banner-polygon-1 no-repeat left / contain, $top-banner-rectangle-1 no-repeat left / contain;
  }

  &-right-side {
    background: $top-banner-polygon-2 no-repeat right / contain, $top-banner-rectangle-2  no-repeat right / contain !important;
  }
  
  &-grey{
    &-left-side {
      background: $top-banner-polygon-4 no-repeat left / contain, $top-banner-rectangle-5 no-repeat left / contain;
    }
  
    &-right-side {
      background: $top-banner-polygon-3 no-repeat right / contain, $top-banner-rectangle-6 no-repeat right / contain !important;
    }
  }

  &-content {
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.5px;
  }
}

.fx-top-banner-content {
  display: flex;
  width: 100%;
  span.fx-bold {
    font-weight: 800;
    font-size: 1.32rem;
    font-family: $font;
    @media (max-width: 1150px) {
      font-size: $font-size-h4;
    }
  }
  >div {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    p {
      font-family: $font;
      font-weight: 400;
      margin-right: 1rem;
      font-size: 1.32rem;
      display: flex;
      align-items: center;
      @media (max-width: 1150px) and (min-width: 1000px) {
        font-size: $font-size-h4;
      }
    }
    svg {
      height: 2rem;
      width: 8.42rem;
      @media (max-width: 1150px) and (min-width: 1000px) {
        width: 7rem;
      }
    }
  }

  .fx-button-saturated-orange {
    letter-spacing: 0.025rem !important;
    padding: 0.375rem 1.3rem !important;
    font-size: 1.1rem !important;
    height: fit-content;
    @media (max-width: 1150px) and (min-width: 1000px) {
      font-size: $font-size-h4 !important;
    }
  }
}

.fx-passport-root {
  >.fx-top-banner ~ header{
    top: 3.87rem !important;
  }
  >.fx-top-banner ~ main{
    margin-top: 12.3rem;
    .fx-menu-passport-collapse{
      top: 3.87rem;
    }
  }
}

//fx-right-drawer
.fx-right-drawer {
  width: 100%;
  height: 100%;
  overflow: scroll;

  &-close {
    width: 1.2rem;
    height: auto;
    position: fixed;
    right: 2rem;
    top: 1.125rem;
    &:hover {
      cursor: pointer;
    }
  }
}

/* fx dialog card */
.fx-dialog-card {
  margin: auto;
  padding: 1.6rem;
  background: $white;
  text-align: center;

  &-icon {
    margin: auto;
    padding: 1rem;

    svg {
      max-width: 100%;
      height: auto;
    }
  }

  &-title {
    font-weight: 700;
    font-size: 1.25rem;
    letter-spacing: 0.5px;
  }

  &-message {
    >div:first-child {
      padding: 0.5rem 1.25rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      font-weight: 400;
      font-size: 0.875rem;
      margin: 0;
    }

    svg {
      margin-right: 0.5rem;
      circle {
        fill: $theme-color;
        stroke: $theme-color;
      }
    }
  }

  &-button {
    // font-weight: 400;
    box-shadow: none;
  }

  &-note {
    >div:first-child {
      padding: 1rem 9rem;
    }
    p {
      text-align: center;
      font-weight: 400;
      font-size: $font-size-h5;
      color: $disabled-gray-color;
    }
  }

  hr {
    border-color: $border-color;
    width: 7rem;
    margin: auto;
  }
}

//fx info popover styles
.fx-info-tooltip {
  .MuiTooltip-tooltip {
    background: $menu-color;
    color: $primary-blue;
    font-family: $font;
    padding: 0.833rem;
    border-radius: 0.6rem;
  }
  .MuiTooltip-arrow {
    color: $menu-color;
  }
}

.fx-congratulation {
  color: $text-color;

  &-title {
    font-size: $font-size-xxl;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

   &-message {
     font-size: $font-size-h3;
     text-align: center;
   }
  .fx-button-theme {
    box-shadow: none;
    margin-top: 1.5rem;
  }
}

.fx-solid-divider {
  background-image: none !important;
  border: 0.5px solid $border-color !important;
}