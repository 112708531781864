/*  permission related */
.fx-permissions{
  width:95%;
  padding:0;
  .fx-card{
    box-shadow: none !important;
    border: $border-card;
  }
  .MuiAccordionSummary-content.Mui-expanded{
    margin-bottom: 0.625rem;
  }
  .MuiTypography-h6, .MuiTypography-h5{
    font-family: $font;
  }
  &-tab-button-tab{
    min-height: 3.6rem;
    margin-top:1.625rem;
  }
  &-accordian-title-cell{
    display: flex;
    align-items: center;
    .selected-access {
      border-radius: 0.33rem;
      padding: 0;
      span {
        padding: 0.33rem 0.5rem;
        font-weight: 600;
      }
    }
    .selected-access-custom-color {
      color: $light-green-color!important;
      background-color: $light-green-background-color !important;
    }
    p {
      font-size: $font-size-h3;
      margin: 0 0.83rem;
    }

  }
  .MuiAccordionDetails-root{
    margin-left:1.95rem;
  }
  .MuiAccordionSummary-root{
    padding-left: 0;
  }

  &-checkbox {
    .MuiIconButton-colorSecondary:hover{
      background: none;
    }
    .MuiFormControlLabel-root {
      margin: 0;
    }
    svg {
      color: $light-grey-color !important;
    }
  }
  &-checkbox.green{
    svg {
      color: #00B05C !important;
    }
  }

  &-grid-item-50 {
    min-width: 7.80rem;
    border-right: 0.13rem solid $border-color;
    padding: 0.65rem;
    display: flex;
    justify-content: center;
    color: $menu-color;
  }

  &-head-row {
    .fx-group-checkbox{
      width:0.78rem;
    }
  }

  &-head-row{
    background-color: $table-passport-th-background;
    text-transform: uppercase;
    height: 3.925rem;
    font-family: $font-inter !important;
    align-items: center;

    .MuiGrid-item {
      font-family: $font-inter !important;
      font-size: 0.91rem;
      text-transform: uppercase;
    }

  }

  .parent-role{
    position: relative;
    margin-top: -1.4rem;
    .fx-select-def :first-child{
      color: $label-color-secondary;
      :has(> input) {
        padding: 0.35rem 0.5rem;
      }
    }
    p{
      font-size: $font-size-h5 !important;
    }
  }

  .header-cell {
    font-family: $font-inter !important;
    font-weight: 500 !important;
    letter-spacing: 0.065rem;
    font-size: $font-size-h5 !important;
    padding-top: 0.98rem !important;

  }

  .list-cell {
    p {
      font-size: $font-size-h3 !important;
      font-weight: 500;
    }
  }

  .long {
    min-width: 18rem;
    justify-content: left;
    max-width: 34rem;
    align-items: center;

    p.permission-name {
      margin-left: 1.0625rem;
      margin-right: 0.94rem;
    }
  }

  &-row {
    border-bottom: 0.13rem solid $light-blue-border;
    height: 3.925rem;
  }

  &-list-table {
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;
  }

  &-tooltip {
    height: 1.4rem !important;
    width: 1.4rem !important;
    padding-left: .39rem;

  }

  .no-border {
    border-right: none !important;
  }

  .fx-disabled * {
    pointer-events: none;

  }

  .diabled-check-box {
    .MuiFormControlLabel-root.Mui-disabled {
      margin: 0;
    }

    .Mui-disabled {
      .MuiButtonBase-root {
        svg {
          background: $light-grey-color !important;
          transform: scale(.75);
          path{
            fill:none
          }
        }
        border: none !important;
      }

     
    }
  }
  .long{
    min-width: 18rem;
    justify-content: left;
  }
  &-row{
    border-bottom: 0.13rem solid $light-blue-border;
    height: 3.925rem;
  }
  &-list-table{
    overflow-x: auto;
    overflow-y: auto;
    width:100%;
  }
  &-tooltip{
    height:  1.2rem !important;
    padding-left: .29rem;

  }

  &-object-access-drawer{
    padding:2.6rem;
    min-width: 25vw;
    padding-right:0;
    h3{
      font-family: $font-inter-medium !important;
      font-weight: 600;
      font-size: 1.82rem;
      line-height: 2.21rem;
      color: #000000;
      margin-top:0.65rem;
      margin-bottom:0.65rem;
    }

    h4{
      font-family: $font;
      font-weight: 500;
      font-size: 1.105rem;
      line-height: 1.56rem;
      letter-spacing: 0.0325rem;
      color: rgba(16, 48, 70, 0.6);
      margin-bottom:0.78rem;
    }

    .title-with-bg {
      padding:0.325rem 0 0.325rem 0.325rem;
      border-top: 0.13rem solid $btn-def-background;
      background-color: $table-passport-th-background;
      margin-top:2.73rem;
    }
    .menu-items{
      border-bottom: 0.13rem solid $btn-def-background;
      padding:0.65rem 0 0.65rem 0.325rem;

    }

    .footer-action{
      position: fixed;
      bottom: 0.65rem;
      .fx-button{
        margin-left:0.65rem;
      }
    }

  }

  .custom-object-access {
    margin: 1.5rem 0 0 3.1rem;
    .chip-object {
      border-radius: 0.325rem;
      background: $chip-def-background;
      height: 1.66rem;
      padding: 0.33rem 0.5rem;
      margin: 0;

      .MuiChip-label {
        font-size: 0.9rem;
        padding: 0.33rem 0.5rem !important;
        font-family: $font-inter-medium !important;
        font-weight: 600;
        color: $chip-label-def-color;
      }

    }

    .chip-object-icon {
      padding: 0;
      margin-left: 1rem;
    }
  }
  &-dotted-menu ul{
    padding: 0 !important;
    li {
      text-transform: none;
      font-size: 1.0625rem;
      padding: 0.75rem 1.9rem;

      &::after {
        content: none !important;
      }

      &:last-child.Mui-disabled {
        border-top: 1px solid $border-color;
      }
    }
  }
  &-object-access-cancel-button{
    pointer-events: auto;
  }


}
/* permission related ends */

.fx-create-role {
  &-header {
    font-size: 1.416rem
  }

  .fx-divider-account{
    margin-top: 3.06rem;
    margin-bottom: 2.5rem;
  }
}

.fx-roles-permission-buttons {
  margin: 1rem 0 2.5rem 0 !important;
  display: flex;

  .fx-roles-permission-tabs {
    margin-right: 1.6rem;
    border: 1px solid $border-color;
    border-radius: 0.5625rem;
    display: flex;

    &-button-grid {
      @media (max-width: 600px) {
        padding-left: 8px;
      }
    }

    > div {
      padding: 0.4rem;
    }

    .fx-link {
      color: $label-color-secondary;
      opacity: 0.4
    }

    .fx-link,
    .fx-link-sel {
      line-height: 1rem;
      min-width: 1rem !important;
      padding: 0.625rem 0.78125rem !important;

      span {
        font-size: 0.875rem;
        font-family: $font;
      }
    }

    .fx-link-sel {
      color: $white;
      background-color: $theme-color;
    }

    .fx-link-sel:hover {
      opacity: 0.7;
    }
  }

  .filter-button{
    background: #FFF7F0;
    svg{
      fill: #8E8E8E;
    }
  }
}