/* autocomplete styles start */
.fx-autocomplete {
  .fx-autocomplete-div {
    border-radius: 16px;
    width: 260px;
    height: 32px;
    background-color: #ffffff;
    position: relative;
    display: flex;
    box-shadow: 0 1px 3px 0 #bbcbe5;

    .fx-auto-complete-lense-icon {
      width: 1em;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      padding: 10px;
    }

    .MuiFormControl-root {
      padding-left: 35px !important;
      width: 210px !important;
    }

    .MuiInput-underline:before {
      border-bottom: none !important;
    }

    .MuiInput-underline:after {
      border-bottom: none !important;
    }

    .MuiAutocomplete-input:first-child {
      padding-top: 8px;
      font-family: $font ;
      font-size: 1rem;
    }
  }
}

.fx-select {
  border-radius: 0.375rem;

  select {
    padding: 8px;
  }
}

.fx-auto-def {

  // [class*="-indicatorContainer"]{
  //   display: none ;
  // }
  // [class*="-indicatorSeparator"]{
  //   background-color: transparent;
  //  }
  [class*="-menu"] {
    background: $white;
    z-index: 9999
  }

  svg {
    // display:none;
  }

  [class*="-indicatorContainer"]:last-of-type {
    display: none;
  }
}
/* autocomplete styles end */


/* default option select styles start */
.fx-select-def {
  [class*="control"] {
    cursor: pointer;
    // min-height: 32px;
    /*do not add height as it will cause issue with multiple selects that extends more then one row*/
  }

  :first-child {
    top: -1px;

    :has(> input) {
      padding: 0.625rem 0.5rem;
    }

    [class*="-placeholder"] {
      padding-left: 0.5rem;
    }
  }

  :nth-child(2) {

    // height:32px;
    :has(> input) {
      //it will reflect if we are displaying right side select icon
      padding: 0.625rem 0.5rem;

      svg {
        color: $placeholder-color !important;
      }
    }
  }

  [class*="-control"] {
    min-height: 32px;
  }

  [class*="multiValue"] {
    background-color: $background-color;
    color: $white;

    div:nth-of-type(1) {
      color: $white;
    }
  }

  [class*="control"]:hover {
    border-color: #000;
  }

  .css-1pahdxg-control {
    border-color: $button-passport-border-color !important;
    // box-shadow: none!important;
  }

  [class*="-indicatorSeparator"] {
    margin: 2px 0 5px 0 !important;
  }
}
/* default option select styles end */


.fx-theme-select {
  color: $theme-color;
}

.fx-search-by-props {
  display: block;
  width: 150px;
  z-index: 1000;

  [class*="control"] {
    height: 31px !important;
    min-height: 31px !important;
    background-color: $grey !important;
    border-width: 0 0 1px 0 !important;
    border-radius: 0 4px 4px 0 !important;

  }

  [class*="placeholder"] {
    color: black !important;
  }

  [class*="-indicatorContainer"] {
    padding: 6px 8px 8px 8px !important;

    svg {
      path {
        fill: black !important;
      }
    }

    display: flex;
  }
}

/* default multi select styles start */
.fx-select-multi {
  [class*="control"] {
    height: auto !important;

  }

  [class*="multiValue"] {
    margin: 1px !important;
  }
}
/* default multi select styles end */


/* progress bar styles start */
.fx-progress {

  .MuiLinearProgress-root {
    display: inline-block;
    margin-right: 10px;
    width: 100%;
    background-color: $debit-background-second;
    border-radius: 1.67rem;
    height: 0.8rem;
  }
}

.fx-progress-green .MuiLinearProgress-barColorPrimary {
  background-color: #0dac0c;
}

.fx-progress-red .MuiLinearProgress-barColorPrimary {
  background-color: #e66a6a;
}

.fx-progress-yellow .MuiLinearProgress-barColorPrimary {
  background-color: #f7c88e;
}

.fx-progress-grey .MuiLinearProgress-barColorPrimary {
  background-color: $light-grey-color;
}

.fx-progress-blue .MuiLinearProgress-barColorPrimary {
  background-color: #4e75e2;
}
/* progress bar styles end */

/* status styles start */
.fx-status {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px !important;
  padding: 1px 2px;
  font-size: 13px;
}

.fx-select-input {
  .fx-placeholder {
    color: $disabled-gray-color;
    display: flex;
    align-items: center;
  }
  .fx-select-prefix {
    margin-right: 0.5rem;
    display: flex;
  }
  .fx-selected-view {
    display: flex;
    align-items: center;
  }
}

.fx-option {
  display: flex;
  align-items: center;

  .fx-option-prefix {
    display: inline-flex;
    align-items: center;
    margin-right: 0.5rem;

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}



//tooltip for status
.fx-status-tooltip {
  .MuiTooltip-tooltip {
    margin-left: 0.6rem !important;
    margin-top: 1rem !important;
  }
  .MuiTooltip-arrow {
    transform: translate3d(0.833rem, 0, 0) !important;
  }
}

@mixin info-tooltip {
  border-radius: 0.125rem !important;
  font-size: $font-size-h5;
  font-weight: 500;
}

//tooltip for available amount
.fx-amount-info-tooltip {
  .MuiTooltip-tooltip {
    @include info-tooltip;
    margin-left: 0.6rem !important;
    margin-top: 0 !important;
    max-width: 32rem !important;
  }
}

//tooltip for ppi
.fx-ppi-info-tooltip {
  .MuiTooltip-tooltip {
    padding: 0.83333rem;
    border-radius: 0.25rem !important;
    max-width: 12rem !important;
    font-size: $font-size-h4;
    font-weight: 500;
    color: $white !important;
  }
}

//tooltip for available faster funds
.fx-available-faster-funds-info-tooltip {
  .MuiTooltip-tooltip {
    @include info-tooltip;
    max-width: 20rem !important;
    padding: 0.5rem 0.833rem !important;
    margin-top: 0.6rem !important;
  }
}

.fx-status-border {
  background-color: #bbb;
  border-radius: 50%;
}

.fx-draft-edit {
  .fx-status-filled {
    position: relative;
    top: 6px;
  }

}

.fx-status-filled {
  padding: 1px 5px;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.25rem;
  text-transform: uppercase;

  &-no-color {
    border: 1px solid $label-color-4;
    color: $label-color-4;
    font-weight: 500 !important;
    display: inline-flex;
    font-size: 0.875rem;
    margin-right: 0.6rem;
  }
}

.fx-green-icon:before {
  display: inline-block;
  content: ' ';
  background-image: url('../../assets/svg/icon-good.svg');
  background-size: 6px 6px;
  height: 6px;
  width: 6px;
  padding: 0 3px 1px 2px;
  margin-left: 2px;
  background-repeat: no-repeat;
}

.fx-red-icon:before {
  display: inline-block;
  content: ' ';
  background-image: url('../../assets/svg/icon-bad.svg');
  background-size: 6px 6px;
  height: 6px;
  width: 6px;
  padding: 0 3px 1px 2px;
  margin-left: 2px;
  background-repeat: no-repeat;
}

.fx-ignore-icon:before {
  display: inline-block;
  content: ' ';
  background-image: url('../../assets/svg/icon-ignore.svg');
  background-size: 6px 6px;
  height: 6px;
  width: 6px;
  padding: 0 3px 1px 2px;
  margin-left: 2px;
  background-repeat: no-repeat;
}

.fx-warning-icon:before {
  display: inline-block;
  content: ' ';
  background-image: url('../../assets/svg/icon-warning.svg');
  height: 8.5px;
  width: 2.2px;
  padding: 0 3px 0 2px;
  margin-left: 2px;
  background-repeat: no-repeat;
}
/* status styles end */


/*Icon for phone and email popover button*/
.fx-icon-grey {
  width: 15px;
  height: 15px;
  border-radius: 1rem;
  box-shadow: 0 1px 3px 0 #bbcbe5;
  background-color: $btn-def-background;
  float: right;
  background-image: url("../../assets/svg/down-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 6px;
  margin-top: 2px;
}

.fx-phone {
  float: left;
}
/*Icon for phone and email popover button end here*/


/*fx-date-edit cal */
.fx-date-edit {
  /*overflow: hidden;
 .MuiSvgIcon-root{
   width:20px !important;
   height: 15px !important;
 }
 .MuiInputBase-formControl{
   padding-right: 0!important;
 }
 .MuiInputAdornment-positionEnd{
   height: 90% ;
   position: relative;
 }
 .MuiInputAdornment-positionEnd:after {
   position: absolute;
   content: '';
   left: 0px;
   top: 20%;
   height: 60%;
   border-left: 1px solid hsl(0,0%,90%);
 }
 .MuiOutlinedInput-input{
   padding-right: 18.5px 13px;
 }
 .MuiFormHelperText-filled  {
   color: #ae1326;
 }
 label[data-shrink="false"]{
   display: none!important;
 }*/
}

/*fx-date-edit cal ends here*/


/*textarea*/
.fx-textarea {
  width: 100% !important;
  border-color: $input-border-color;
  padding-right: 0;
  font-family: $font;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}


/*check box starts here */
.fx-group-checkbox {
  .MuiSvgIcon-root {
    height: 1.125rem !important;
    width: 1.125rem !important;
    color: $button-secondary-border-color !important;
  }

  .MuiTypography-root {
    font-size: 0.875rem !important;
    font-family: $font !important;
    font-weight: 400;
  }

  .fx-group-checkbox .MuiSvgIcon-root {
    color: $button-secondary-border-color !important;
  }

  .Mui-checked .MuiSvgIcon-root {
    color: $theme-color !important;
  }

}

.fx-group-checkbox-disabled {
  .MuiSvgIcon-root {
    height: 18px !important;
    width: 18px !important;
    color: #b5c2d4 !important;
  }

  .MuiTypography-root {
    font-size: 11px !important;
  }
}

/*check box ends here*/


/*fx code starts here*/
.fx-code {
  width: 100% !important;
  border-color: $input-border-color;
  padding: 0 8px;
  font-family: $font;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

/*fx code ends here*/


/*html view component*/
.fx-html {
  overflow: hidden;
  max-width: 50vw;

  p {
    white-space: break-spaces !important;
  }
}

/*html view component ends here*/


/*fx-autocomplete-lens-search-icon starts here */
.fx-autocomplete-lens-search-icon {
  width: 1em;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 8px;
}
/*fx-autocomplete-lens-search-icon ends here */


/* country select */
.fx-country-option-label {
  display: flex;
  align-items: center;

  svg {
    width: 24px;
    height: 25px;
    transform: none;
    margin-top: 0;
  }

  span {
    margin-left: 0.75rem;
  }
}

//fx-select-auto-search starts here
.fx-select-autosearch {
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
    padding: 0 !important;
  }
  svg {
    scale: 45%;
  }
  .MuiAutocomplete-option {
    background-color: $white !important;
    border-bottom:2px dotted rgba($label-sec, 0.05);
  }
  .MuiAutocomplete-option:last-child {
    border-bottom: none;
  }
  .MuiAutocomplete-endAdornment {
    display: flex;
    align-items: center;
    position: absolute;
    top: calc(50% - 2px);
    transform: translateY(-50%);
    >button {
      display: flex;
      align-items: center;
    }
  }
  .MuiAutocomplete-option[aria-selected="true"] {
    color: $theme-color !important;
    font-weight: 700 !important;
  }
}
//fx-select-auto-search ends here


.fx-label-top {
  position: relative;
  left: 0.75rem;
  top: 0.6rem;
  z-index: 1;
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: $label-color-secondary;
  background: $white;
  padding: 0 1rem 0 0.4rem;
}
.fx-label-top{
  &.MuiTypography-body1{
    font-weight: 300;
  }
}
.fx-label-kyc{
  &.MuiTypography-body1, .MuiTypography-body1{
    font-size: $font-size-h4 !important;
    font-weight: 500 !important;
  }
}


/*default class for input edit components start here */
.fx-input-edit {
  font-family: $font !important;
  font-size: 1rem !important;
  background: #fff;
  width: 100%;

  //overflow: hidden;
  input {
    font-family: $font !important;
    font-size: 1.15rem !important;
    font-weight: 500 !important;
  }
  .Mui-disabled{
    background-color: $disabled-color;
    color: $theme-input-color;
  }

  [class*="-indicatorSeparator"] {
    display: none;
  }

  [class*="-singleValue"] {
    color: $theme-input-color;
  }

  //hide calendar icon
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  input[type="month"]::-webkit-inner-spin-button,
  input[type="month"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  //select select arrow change and transitions
  [class*="-indicatorContainer"]:last-of-type {
    svg {
      height: 12px;
      width: 12px;
      margin-top: 5px;
      transform: translateY(-50%) scale(0.8);
      transition: all 0.3s ease;

      path {
        fill: $devider-border-color;
        d: path("M11.652 7.49243C13.4777 7.49243 16.1443 7.76757 18.1834 8.01957C19.7443 8.20986 20.7369 9.77586 20.1172 11.2184C18.618 14.7001 16.2986 17.9299 12.8014 20.0899C12.4561 20.3034 12.0581 20.4165 11.652 20.4165C11.246 20.4165 10.848 20.3034 10.5026 20.0899C7.00545 17.9299 4.68602 14.7001 3.18688 11.2184C2.56716 9.77586 3.55973 8.20986 5.11802 8.01957C7.15973 7.77015 9.8263 7.49243 11.652 7.49243Z");
      }
    }

  }

  [class*="-1gtu0rj-indicatorContainer"]:last-of-type {
    svg {
      // transform: rotate(180deg) translateY(50%);
      // margin-top:10px;

    }
  }


  .MuiInputLabel-outlined {
    //  margin-top:-5px;
    color: $label-color-secondary !important;
    font-size: 0.875rem !important;
    font-weight: 300 !important;
    font-family: $font !important;
  }

  .MuiInputLabel-shrink {
    margin-top: 0;
    transform: translate(0.875rem, -0.6rem) scale(1) !important;
    background: $white !important;
  }

  //adjust the notch padding right after focus
  .MuiInputBase-formControl {
    [class*="PrivateNotchedOutline-legendLabelled-"] {
      span {
        // font-size:0.7rem;
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $input-border-color ;
  }

  .MuiOutlinedInput-adornedStart {
    input {
      padding-left: 1px !important;
    }
  }
}

/*default class for input edit components ends here */

//phone edit
.fx-phone-edit {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }
  }

  >div {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid $input-border-color !important;
    border-radius: 4px;
  }

  .border-error-input {
    border-color: $red !important;
  }

  .fx-input-edit {
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent !important;
    }

    .MuiIconButton-root {
      margin-top: -4px;
      background: none !important;
    }
  }

  .MuiSelect-root,
  .MuiTextField-root {
    svg {
      height: 1.5rem;
      width: 1.5rem;
      transform: none;
      transition: all 0.3s ease;
      margin-top: 0.5rem;
      padding-left: 0.5rem;
    }
  }

  .MuiTextField-root {
    padding: 0 !important;

    .MuiOutlinedInput-adornedStart {
      padding-left: 0.25rem;
    }
  }

  .MuiSelect-root {
    padding: 0.33rem 0.5rem 0.33rem 0.75rem !important;
  }

  &-country {
    width: 4.2rem;
    margin: 0 0.625rem;

    .MuiInputBase-formControl {
      height: auto !important;
    }

    fieldset {
      background: $disabled-color !important;
      height: 100% !important;
    }

    svg {
      height: 1rem !important;
      width: 1.5rem !important;
    }

    svg.MuiSelect-icon{
      z-index: 1;
      right:0.55rem;
      height: auto !important;
      width: 0.75rem !important;
      top: calc(50% - 0.5rem);
    }

    .MuiSelect-select.MuiOutlinedInput-input {
      z-index: 1;
      display: flex;
      align-items: center;
    }
  }

  .MuiInputLabel-shrink {
    transform: translate(-4.85rem, -0.5rem) scale(1) !important;
    padding: 0 1.3rem 0 0.65rem;
  }

  p{
    font-size: 1.0625rem !important;
  }

}

/* FxAvatar starts here*/
.avatar-card {
  .MuiGrid-container {
    height: auto !important;
  }

  .MuiAvatar-root {
    width: 80px;
    height: 80px;
  }
}

/* FxAvatar ends here*/

/* FxRadio ends here*/
.fx-group-radio {
  .MuiButtonBase-root {
    .MuiSvgIcon-root {
      height: 14px !important;
      width: 14px !important;
    }

    .MuiRadio-root {
      color: $button-secondary-border-color;
    }
  }

  .Mui-checked {
    svg {
      color: $theme-color !important;
    }
  }

  .MuiTypography-root {
    font-size: 1rem !important;
    font-family: $font !important;
  }
}

/* File Upload button and filename when disabledrop is true*/
.fx-file-button {
  display: flex;

  .fx-filename-label {
    margin: 8px 0 0 15px !important;
    color: #0a73e9;
  }

  .fx-upload-loader-icon {
    margin: 8px 0 0 15px !important;

    .MuiCircularProgress-root {
      width: 17px !important;
      height: 17px !important;

      svg {
        width: 17px !important;
        height: 17px !important;
      }
    }
  }
}

.fx-address-group {
  .fx-address-item {

    display: flex;
    flex-wrap: wrap;

  }

  .fx-address-item>* {
    width: 48%;
    margin: 5px 5px 0 5px !important;
  }

}


.fx-select-box-icon {
  svg {
    height: 1rem;
    width: 1rem;
    top:calc(50% - 0.75rem);
    right: 1.25rem;
    transition: all 0.3s ease;

  }

  .MuiSelect-iconOpen {
    // transform: rotate(180deg) translateY(50%);
    // margin-top:10px;
  }
}

.fx-select-box {
  font-size: 12px !important;
  font-family: $font !important;
}


.fx-disabled {
  color: $disabled-placeholder-color !important;

  >svg {
    display: none;
  }

  fieldset {
    background: $disabled-color !important;
  }

  .MuiSelect-select {
    z-index: 1;
  }
}

.fx-input-disabled {
  color: $disabled-placeholder-color !important;

  svg {
    display: none;
  }

  input {
    background: $disabled-color !important;
  }
}

/*cc block**
*/
.fx-cc-grid {
  position: relative;
  &-issuer {
    position: absolute;
    top: 0.5rem;
    right: 0.83rem;

    svg {
      width: 45px;
      height: 30px;
    }
  }
}

/*otp input */
.fx-otp-edit {
  input {
    width: 3.25rem !important;
    height: 3.25rem;
    border-color: $input-border-color !important;
    border: 1px solid;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  input:focus-visible {
    outline: 1px solid $dark-gray-color !important;
  }

}

.fx-auto-complete-custom {
  &-MuiDivider{
    background-image: linear-gradient(to left, $devider-border-color 20%, rgba(255, 255, 255, 0) 0%);
    background-position: left;
    background-size: 0.375rem 1.5px;
    background-repeat: repeat-x;
    width: 100%;
    position: relative;
    bottom: 0;
    display: block;
    height: 1px;
    top: -3px;
    padding: 1px 0;
    border: none;
    background-color: $white;
  }
  &-FxButton {
    svg {
      width: 0.583rem !important;
      path {
        stroke: $white !important;
        stroke-width: 0.27rem;
      }
    }
  }
  &-removeCheckColor{
    li {
      background-color: $white;
      font-family: $font-regular !important;
      font-size: 1rem !important;
      cursor: pointer;
    }
    padding-left: 0;
  }
  &-roleList{
    background-color: $white;
    display: block;
  }
  &-confirm-button{
    float: right !important;
    margin: 0.625rem 0.3125rem;
    height: 2.5rem;
  }
  &-auto-complete-dropdown{
    @media (min-width: 900px) {
      display: grid;
      grid-template-columns: auto auto;
      height: auto;
      max-height: 15.625rem;
      column-gap: 0.625rem;
    }
  }
  &-MuiInputBaseroot{
    .MuiInputBase-root{
      height: auto !important;
      padding: 0 !important;
    }
  }
}

/*International Wire Form Spacing */
.fx-container-create-schdule-onetime{
  margin: 0.7rem 0;
}

.fx-authorized-user-select {
  .MuiInputBase-formControl{
    min-height: 3.5rem;
    height: 3.5rem !important;
  }
  .MuiOutlinedInput-input { 
    color: black !important;
  }
}
.fx-approval-button-icon{
  height: 3.5rem !important;
  min-width: 0rem !important;
  width: 3.5rem !important;
  background-color: transparent !important;
  border: 2px solid $border-color !important;
  .MuiButton-startIcon{
    margin-right: 0rem;
  }
  svg{
    height: 1.15rem !important;
    width: 1.15rem !important;
    path{
      stroke: $theme-color;
    }
  }
  
  &-disabled{
    svg{  
      path{
        stroke: $disabled-gray-color;
      }
    }
  }
  
}
.fx-auto-complete-custom-auto-complete-dropdown-display-block{
  display: block;
}

.fx-text-disabled-gray {
  color: $disabled-gray-color;
}

/* multiselect autocomplete styles */
.fx-multiselect-autocomplete {
  .MuiChip-deleteIcon {
    width: 1.2rem;
    height: 1.2rem;
    color: $white !important;
  }

  .MuiInputBase-root {
    padding: 0.4rem !important;
  }

  .MuiAutocomplete-popupIndicator {
    &:hover {
      background: none;
    }
    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  .MuiChip-root {
    border-radius: 0.3rem;
    background-color: $label-color;
    padding: 0;
    height: 1.7rem;
    margin: 0 0 0 0.7rem;

    .MuiChip-label {
      font-size: $font-size-h4;
      font-weight: 500;
      color: $white;
      text-transform: uppercase;
    }
  }

  .MuiIconButton-root{
    transition: all 0.3s ease;
  }

  .MuiAutocomplete-endAdornment{
    padding-right: 0.6rem;
  }

  .MuiPaper-rounded {
    border-radius: 0.375rem !important;
    box-shadow: 0 2px 6px 0  rgba(2, 32, 167, 0.12) !important;
  }

  &-dropdown-paper {
    svg {
      color: $theme-color !important;
    }
    li {
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      color: $text-color;

      &:not(:last-child):after {
        background-image: linear-gradient(to left, $devider-border-color 20%, $white 0%);
        position: relative;
        bottom: 0;
        display: block;
        content: '';
        height: 1px;
        width: 100%;
        background-position: left;
        background-size: 6px 1.5px;
        background-repeat: repeat-x;
        top: 4px;
        padding: 2px 0;
      }

      >div {
        width: 100%;
      }
    }

    .MuiAutocomplete-option.Mui-focused {
      background: none;
    }

    .MuiAutocomplete-option[aria-selected="true"] {
      background: none;
    }
  }
}

//tooltip for mark as read button
.fx-mark-as-read-tooltip {
  .MuiTooltip-tooltip {
    padding: 0rem 0.33333rem !important;
    max-height: 1.5rem !important;
    margin-left: 0.6rem !important;
    margin-top: 0rem !important;
    border-radius: 0.16667rem !important;
    max-width: 32rem !important;
    font-size: $font-size-h4;
    font-weight: 500;
  }
}


.fx-modal-title-h3 {
  font-size: $font-size-h3 !important;
  color: $text-color;
}

.fx-radio-label-tag {
  text-transform: uppercase;
  padding: 0.1rem 0.4rem;
  margin-left: 0.5rem;
  border: 1px solid $grey-text-color;
  border-radius: 0.25rem;
  font-size: $font-size-h5 !important;
  font-weight: 500;
  color: $grey-text-color;
}

.fx-carousel {
  position: fixed;
  width: 100%;
  height: 3.87rem;

  .slick-slider {
    width: 100% !important;

    .slick-list {
      .slick-track {
        display: inline-flex !important;

        .slick-slide {
          >div {
            .carousel-banner-item {
              width: inherit !important;
              position: sticky !important;
            }

            .fx-top-banner-content {
              align-items: center;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              font-weight: 600;
              font-size: $font-size-h5;
              line-height: 1.5rem;
            }
          }
        }
      }
    }
  }
}


.fx-currency {
  >div {
    flex-wrap: nowrap;
    white-space: nowrap;
  }
}

.fx-checkbox-tree{

  .MuiAccordionSummary-content{
    margin: 0;
    padding: 0
  }
  
  .MuiAccordionSummary-root.Mui-expanded{
    margin-top:0;
    padding: 0;
    min-height: 0;
  }

  .MuiAccordionSummary-root{
    margin:0;
    padding: 0;
  }

  .MuiAccordionSummary-content.Mui-expanded{
    margin-top: 0.625rem !important;
  }

  .MuiAccordionSummary-content .MuiAccordionSummary-contentGutters{
    margin: 0;
  }

  padding: 0.3rem !important;

  &-node-height{
    height: 3.31rem !important;
  }

  &-title{
    &-label{
      font-weight: 500 !important;
    }
    .Mui-checked svg {
      color: $theme-color !important;
    }
  }

  &-content{
    font-weight: 400 !important;
    border-right: 0.13rem solid $border-color;
    color: $menu-color;

    

  }

  .disabled-check-box {
    .MuiFormControlLabel-root.Mui-disabled {
        margin: 0;
    }

    .Mui-disabled {
      background-color: $white !important;
      svg{
        color: $light-grey-color !important;
      }
    }
  }



    .Mui-checked svg {
      color: $theme-color !important;
    }
}

.fx-webhook-action{
  padding: 1rem !important;
  .fx-card-header{
    border-bottom: none !important
  }
  &-title{
    font-size: $font-size-h2 !important;
    font-weight: 500 !important;
  }
  &-subtext{
    text-align:center;
    padding-bottom: 2.67rem;
    padding-left: 0.83rem;
    padding-right: 0.83rem;
  }
  &-note{
    text-align:center;
    padding: 1.75rem 0.83rem;
  }
  &-divider{
    padding: 0 0 0.75rem 0 !important;
  }
}

.fx-react-select-dropdown__option--is-selected{
  color: $theme-color !important;
  font-weight: 700 !important;
}