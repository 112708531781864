.fx-quick-invite {
    .MuiFormLabel-root {
      color: $label-sec !important;
    }
    &-header {
        border: 1px solid $border-color;
        background: $white;
        margin-bottom: 0rem;
        padding: 1.5rem 2.5rem;
      .fx-card-header {
        padding: 0 !important;
      }
    }

  .fx-group-radio {
    color: $text-color;
  }

  .fx-quick-invite-fill-info-button-container {
    padding: 0 !important;

    .fx-quick-invite-fill-info-button {
      padding: 0 !important;
      padding-left: 0.7rem !important;
      margin-top: -0.6rem !important;
      margin-bottom: 1.8rem;
      height: auto;
      width: auto;
      background: transparent !important;
      border: none !important;
      color: $theme-color !important;
      font-size: $font-size-h5 !important;

      svg {
        padding-top: 0 !important;
        transform: scale(0.9, 0.9);

        path {
          stroke: $theme-color;
        }
      }
    }
  }

  .fx-phone-edit-country {
    .fx-input-edit {
      .MuiInputBase-formControl {
        height: auto;
      }
    }
  }

  .fx-quick-invite-submit-button {
    border: solid 1px $theme-color !important;
    background-color: $theme-button-background;
    color: $white;
    width: 100%;
    height: 3.5rem;
    font-size: 1.16rem;
    &:hover {
      filter: none;
    }
  }
}

.fx-recent-invite-card{
    > div:nth-child(2){
      padding-top: 0.7rem;
    }
    .fx-row-divider{
        padding: 0.7rem 0;
    }
    &-icon-margin{
        margin-top: 0.6rem
    }
    &-no-icon-margin{
        margin-left: 3rem
    }
    &-list-headerText {
        background-color: $table-passport-th-background;
        border-radius: 0.4rem;
        font-weight: 500;
        text-transform: uppercase;
    }
}

.fx-rechart-piechart{
    .recharts-surface {
          .recharts-layer{
            .label-value{
                font-weight: 600;
                font-family: $font;
                font-size: $font-size-h2;
                line-height: 1.6;
                color: $text-color;
            }
            .label-text{
                font-family: $font;
                font-weight: 500;
                font-size: $font-size-h4;
                line-height: 1.6;
                color: $text-color;
            }
        }
    }
    .recharts-legend-wrapper{
        .LegendList {
            li{
                list-style: none;
        }}
    }
    h3{
        font-family: $font;
        font-weight: 400;
        font-size: $font-size-h4;
        line-height: 1.6;
        padding: 0.4rem;
        color: $text-color;
    }
    h4{
        font-family: $font;
        font-weight: 500;
        font-size: $font-size-h3;
        line-height: 1.6;
        padding: 0.4rem;
        padding-right: 0;
        color: $text-color;
    }
}

.fx-lead-insights {
  &-card-body {
    &-items {
      background: $grey-background;
      border-radius: 6px;
      padding-top: 1.3rem;
      padding-left: 3rem;
      padding-right: 5rem;
      color: $text-color;
      display: flex;
      align-items: center;
      height: 100%;

      &-title {
        font-weight: 500;
        font-size: $font-size-h5;
        text-transform: uppercase;
      }
      .fx-digest-grid {
        margin-top: 0.5rem;
        .fx-currency-value {
          font-size: 2rem !important;
        }
      }
      .fx-theme-color-currency {
        .fx-currency-value {
          color: $theme-color;
        }
      }
    }
  }
}


.fx-view-invite-status-card {
  .fx-card-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &-item {
    color: $text-color;

    &-label {
      font-weight: 500;
      font-size: $font-size-h5;
      opacity: 0.6;
      
      &-uppercase{
        text-transform: uppercase;
      }
    }
    

    &-info {
      font-weight: 500;
      font-size: $font-size-h3;
    }
  }
}

.fx-phone-edit-select-icon{
  svg {
    margin-left: 0.8rem !important;
  }
  .fx-select-box-icon {
    svg.MuiSelect-icon {
     top: calc(50% - 0.9rem) !important;
    }
  }   
}

.fx-partner-onboarding-layout {
  background: $white;
  color: $text-color;
  min-height: 100vh;

  &-left {
    border-right: 1px solid $border-color;
    background: $grey-background;

    h1 {
      font-size: $font-size-xxl;
      line-height: 3rem;
    }

    h1, h2, h3 {
      margin: 0;
    }

    &-header {
      padding-top: 2.33rem;
      padding-bottom: 2.33rem;
      padding-left: 3.5rem;
      background: $white;
      overflow-wrap: anywhere;
      height: max-content;

      .fx-logo-circle {
        margin-right: 1.25rem;

        svg {
          width: 3.5rem;
          height: 3.5rem;
          margin-top: 0.2rem;
        }
      }

      h2 {
        margin: 0;
        font-size: $font-size-h1;
      }

      .fx-username {
        font-size: $font-size-md;
      }
    }

    &-body {
      padding-left: 3.5rem;
      padding-top: 2.5rem;
      padding-bottom: 3.5rem;
      &-title {
        font-weight: 600;
        font-size:$font-size-xl;
        line-height: 4.17rem;
        font-family: $font;
      }

      &-image {
        margin-top: 3rem;
      }
     

      .fx-logo-name {
        margin-top: 1rem;
      }
    }


    &-footer {
      padding-bottom: 2.58rem;
      padding-left: 3.5rem;
      p {
        font-size: $font-size-h3;
        margin: 0 0 1rem 0;
        font-weight: 500;
      }

      hr {
        height: 0.2rem;
        width: 10%;
        border-radius: 10px;
        margin: 0;
        border: none;
        background: linear-gradient(45deg, $theme-color 0%, $theme-button-background-medium 100%);
        margin-bottom: 4rem;
      }
    }
    &-welcome {
      font-family: $font;
      font-weight: 600;
      font-size: $font-size-h1;
      line-height: 2.60rem;
      color:$label-sec;
    }
   
    &-username {
      font-family: $font;
      font-weight: 500;
      font-size: $font-size-h2;
      line-height: 2.5rem;
      color:$label-sec;
    }
  }

  &-right {
    padding: 2.33rem 3.5rem 2.58rem 3.5rem;
    background: $background-drawer;
    background-size: contain;
  }
}

.fx-partner {
  &-agreement-header {
    font-family: $font;
    font-size: $font-size-h2;
    background: $theme-color;
    color: $white !important;
    border-radius: 0.5rem;
    padding: 0.75rem 1.75rem;
    font-weight: 500;
  }

  &-agreement-card {
    background: $white;
    padding: 1.75rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 23px 0 $button-passport-background-color;
    .fx-submit-agree {
      margin-top: 1.25rem;
    }
    .fx-submit-btn {
      margin-top: 3rem;
    }
  }

  &-agreement-document {
    height: 42rem;
    overflow-y: auto;
    border: 1px solid $border-color;
    border-radius: 0.5rem;
  }

  &-congratulations-card {
    margin-top: 9.5rem;
  }
}

.fx-button-partner-invite-actions{
  max-width: 15rem !important;
  background-color: $grey-background !important;
  color: $label-sec;
  height: 2rem;
  font-size: $font-size-h5;
}