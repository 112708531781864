// MFA selection
.fx-mfa-selection {
  margin-top: 1rem;

  >div {
    padding: 0.75rem 1rem;
  }

  >div:hover {
    background: $button-lite-background;

    // color:$theme-color;
    svg {
      rect {
        fill: $white;
      }

      path:first-of-type {
        fill: $theme-color;
      }
    }

    svg:first-child path {
      // fill:$theme-color;
    }
  }

  a {
    cursor: pointer;
  }

  &-left-icon {
    svg {
      height: 3rem;
      width: 3rem;
    }
  }

  &-right-icon {
    align-items: center;
    display: flex;

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .MuiTypography-subtitle1 {
    font-size: 1rem;
    font-family: $font-inter-semibold;
    font-weight: 600;
  }

  &-resndbtn {
    color: $theme-color !important;
    font-size: 0.875rem !important;
    font-weight: 700 !important;
    font-family: $font-inter-bold !important;
    padding-right: 0;
    margin-left: 1.5rem;
    box-sizing: content-box;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      width: 1rem;
      padding-right: 0.5rem;

      path {
        fill: $theme-color !important;
      }
    }
  }

  &-btnfullwidth {
    width: 100%;
    height: 3.5rem !important;
    font-weight: 700 !important;
    font-size: 1.0625rem !important;
  }

  &-linktext {
    cursor: pointer;
    justify-content: space-around;
    margin-top: 1px;
    text-align: right;
    font-size: 0.875rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    font-family: $font-inter-bold;
    line-height: 1;
    letter-spacing: normal;
    text-decoration: none !important;
    color: $light-gray-color !important;

  }

}
