//transaction details page summery
.fx-transaction-summery{
  &-currency{
    .fx-currency *{
      font-size: 1.5rem;
      font-weight: 600;
      color: $label-color-secondary;
    }
    &-ref{
      font-family: $font;
    }
  }
  &-dir{
    border-top: 1.5px solid rgba($label-color-secondary, 0.1);
    border-bottom: 1.5px solid rgba($label-color-secondary, 0.1);
    padding : 1.5rem 0 1rem 0;
    font-family: $font;
    h5{
      color: rgba($label-color-secondary,0.6);
    }
    h4{
      color: $label-color-secondary;
      font-family: $font;
      font-size: 1.15rem;
      font-weight: 500;
    }
  }
  &-details{
    .fx-info-label-value{
      padding: 0.375rem 0.75rem;
    }
    .fx-info-label-value > div:nth-child(1){
      padding: 0;
    }
    .fx-info-label-value-container p{
      text-align: end;
    }
    .fx-info-label-value-container>div:nth-of-type(even) {
      background-color: $grey-background;
      border-radius: 6px;
    }
    &-title{
      font-size: $font-size-h3 !important;
      font-weight: 600 !important;
    }
  }

  &-progress-line {
    &::after {
      width: 0.6875rem;
      height: 6.75rem;
      content: $processing-line;
    }
  }
  //for transaction terminal status
  &-teminal-line {
    &::after {
      width: 0.6875rem;
      height: 6.75rem;
      content: $terminal-line;
    }
  }

  &-passport-logo {
    svg {
      width: 1.5rem;
      height: 1.56rem;
    }
  }
}

.fx-policy-detail{
  &-summary{
    // padding : 0.5rem 0 0.5rem 0;
    font-family: $font;
    h5{
      color: rgba($label-color-secondary,0.6);
    }
    h4{
      color: $label-color-secondary;
      font-family: $font;
      font-size: 1.15rem;
      font-weight: 500;
    }
  }
}

.fx-approval-confirmation{
  &-title{
    font-size: $font-size-h1 !important;
    font-weight: 600 !important;
  }

  &-description{
    padding-top:0.2rem !important;
    padding-bottom: 2rem !important;
    color: $light-grey;
  }

  &-reference-number {
    color: $theme-color;
    cursor: pointer;
  }

  &-popup-grid2{
    padding-bottom: 1rem;
  }
  
}

.fx-policy-confirmation{
  &-padding{
    padding-top: 3rem;
  }
  &-button-padding{
    padding-top: 3rem;
    padding-bottom: 2rem !important;
  }
}

.fx-policy-approval-popup {
  width: 15rem !important;
  .fx-approval-confirmation-description {
    color: $disabled-gray-color;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .fx-card-circle {
    padding-top: 0 !important;
  }

  .fx-info-card {
    padding: 0 !important;

  }

  .fx-card .balance-amount {
    margin-bottom: 0;
  }

  .fx-transaction-summery-details {
    &-title {
      font-size: $font-size-md !important;
    }

    .fx-info-label-value {
      >div:nth-child(1) {
        color: $text-color !important;
        font-weight: 400 !important;
        font-size: $font-size-h4 !important;
      }
      >div:nth-child(2) {
        font-size: $font-size-h4 !important;
      }
    }
  }

  .fx-footer {
    display: flex;
    justify-content: center;
    margin-right: 0 !important;
    padding: 0 !important;
  }
}

.fx-passport-debit-credit-circle-svg {
  svg {
    height: 8rem;
    width: 8rem;
    display: inline-block;
    vertical-align: middle;
  }

  justify-content: center;
  align-items: center;
  height: 8rem;
  width: 8rem;
  display: flex;
  border-radius: 0.375rem;
}

.fx-schedule-actions {
  @media (max-width: 600px) {
    position: fixed;
    justify-content: space-evenly;
    bottom: 0;
    left: 0;
    margin-top: 10rem;
    width: 100% !important;
    background-color: $white;
    z-index: 1;
  }
  .fx-button-action, .fx-button-action-no-border {
    @media (max-width: 600px) {
      margin: 0.5rem 0;
      padding: 0.7rem 0.7rem !important;
    }
  }
}
//transaction details page summery ends here

//check download
.fx-check-download-svg {
  text-align: end;

  svg {
    stroke: none !important;
    fill: $white !important;
  }
}

.fx-create-approval-policy {
  .fx-approval-policy-condition:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  .fx-condition-summary {
    background: $grey-background;
    .fx-condition-btn {
      background: $white !important;
    }
  }
}

.fx-approval-policy-detail {
  &-condition {
    h6, p {
      font-weight: 400;
      color: $text-color;
    }
    &-currency-view {
      .fx-currency-value {
        font-size: 1rem;
      }
    }

    &-operator {
      text-transform: none;
    }
    &-amount {
      text-transform: none;
      display: flex;
    }
  }
  &-chip {
    font-size: $font-size-h5 !important;
  }
}

.fx-policy-modal {
  &-title {
    font-weight: 400;
    font-size: $font-size-h2;
  }

  &-info {
    font-size: $font-size-h3;
    color: $disabled-gray-color;
  }
}

//Capture Listing Card in Transaction Detail
.fx-capture-list-card{
  &-header{
    background-color: $grey-background;
    padding: 0.375rem;
    border-radius: 0.375rem;
  }
  &-body{
    padding: 0.375rem;

    &-amount{
      font-weight: 500 !important;
    }

    &-date-text{
      color: $disabled-gray-color
    }
  }
  &-divider{
    svg {
      width: 28.5rem !important;
    }
  }
}

.fx-capture-listing-main-grid{
  overflow: auto;
  max-height: 18vh;
  scrollbar-width: none;
}