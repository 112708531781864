/* login page styles start */
.login-page {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: space-around;
  background-color: $login-background;

  .error-message {
    color: $red;
    height: 15px;
    padding-top: 1px;
    font-size: 11px;
    font-weight: 600;
  }

  .reaptcha-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;

  }

  &-content {
    // width: 36rem;
  }

  &-content,
  &-content-signup {
    margin-top: 2.875rem;

    .login-logo {
      padding-bottom: 0.5rem;
      display: flex;
      justify-content: center;

      svg {
        width: 13.75rem;
      }
    }

    .registration-head {
      display: flex;
      justify-content: center;
      font-family: $font;
      font-size: 18px !important;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: $menu-tab-color;
    }

    .registration-suntext {
      font-family: $font;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: center;
      color: $menu-tab-color;
    }

    h6 {
      font-family: $font-inter;
      color: $placeholder-color;
      font-size: 1.125rem;

      a {
        color: $theme-color;
      }
    }

    h3 {
      font-family: $font;
      font-weight: 600;
      font-size: 1.75rem;
      color: $label-color;
    }

    &-card {
      margin-top: 1.5rem;
      //height: 255px;
      border-radius: 0.6rem;
      box-shadow: $card-shadow;
      background-color: $paper-background;

      .border-error-input {
        .MuiOutlinedInput-notchedOutline {
          border-color: $red !important;
        }

        [class*="-control"] {
          border-color: $red !important;
        }
      }

      &-terms {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1rem;
        font-family: $font;
        color: rgba($label-color, 0.6) !important;

        a {
          color: $theme-color;
          text-decoration: none;
        }
      }

      &-content {
        //height: 80px;
        margin-bottom: 1px;

        p {
          margin-bottom: 7px;
          color: $label-dark-blue;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: $input-border-color ;
        }

        .MuiOutlinedInput-root {
          // height: 3.5rem!important;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: $input-border-focus-color ;
          border-width: 1px !important;
        }

        &-padding {
          margin: 1rem 0 1.5rem 0;
        }

        &-remained {
          font-weight: 500 !important;
        }

        &-resndbtn {
          color: $theme-color !important;
          font-size: 0.875rem !important;
          font-weight: 700 !important;
          font-family: $font-inter-bold !important;
          padding-right: 0;
          margin-left: 1.0rem;
          cursor: pointer;
          display: flex;
          align-items: center;

          svg {
            width: 1rem;
            padding-right: 0.5rem;

            path {
              fill: $theme-color !important;
            }
          }
        }
      }

      .having-trouble {
        cursor: pointer;
        justify-content: space-around;
        margin-top: 1px;
        text-align: right;
        font-size: 1.125rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: $blue;

      }

      .forgot-password {
        text-decoration: none;
        color: $dark-gray-color;
      }

      .forgot-password:hover {
        color: $theme-color;
      }

      &-small-text {
        font-weight: 400;
        font-size: 0.9375rem;
        color: $light-gray-color;
      }

      &-link {
        margin-top: 1rem;

        >div {
          padding: 0.75rem 1rem;
        }

        >div:hover {
          background: $button-lite-background;

          // color:$theme-color;
          svg {
            rect {
              fill: $white;
            }

            path:first-of-type {
              fill: $theme-color;
            }
          }

          svg:first-child path {
            // fill:$theme-color;
          }
        }

        a {
          cursor: pointer;
        }

        &-left-icon {
          svg {
            height: 3rem;
            width: 3rem;
          }
        }

        &-right-icon {
          align-items: center;
          display: flex;

          svg {
            height: 1.5rem;
            width: 1.5rem;
          }
        }

        .MuiTypography-subtitle1 {
          font-size: 1rem;
          font-family: $font-inter-semibold;
          font-weight: 600;
        }

        &-middle:not(:last-child)::after {
          background-image: linear-gradient(to left, $devider-border-color 20%, rgba(255, 255, 255, 0) 0%);
          position: relative;
          bottom: 0;
          display: block;
          content: '';
          height: 1px;
          width: 100%;
          background-position: left;
          background-size: 6px 1.5px;
          background-repeat: repeat-x;
          top: 4px;
          padding: 2px 0;
        }
      }

      //   &-linkm:before {
      //     position: absolute;
      //     content: "";
      //     height: calc(100% - 0.25rem);
      //     width: calc(100% + 2rem);
      //     top: 50%;
      //     left: 50%;
      //     -webkit-transform: translate(-50%,-50%);
      //     transform: translate(-50%,-50%);
      //     background-color: #fde6d2;
      //     border-radius: 0.375rem;
      //     z-index: -1;
      //     opacity: 0;
      // }

    }

  }

  &-content-signup {
    margin-top: 1rem;
    // width: 40rem;
    box-sizing: border-box;

    @media (max-width: 600px) {
      width: 100vw;

      .login-logo {
        margin-left: 1rem;
      }

      h5 {
        margin-right: 1rem;
      }

      .fx-card {
        box-sizing: border-box;
      }
    }

    h5 {
      font-family: $font;
      color: $label-color;
      font-weight: 400;
      font-size: 0.875rem;
      letter-spacing: 0.5px;

      a {
        color: $theme-color;
      }
    }

    .MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
      border-right: none;
    }

    .fx-button-group {
      width: 100% !important;

      .fx-button {
        width: 50% !important;
      }
    }
  }

  &-copyright {
    color: $devider-border-color;
    position: fixed;
    bottom: 20px;
    // width:360px;
    text-align: center;
    font-size: 0.8125rem;
    font-weight: 400;
  ;
  }

  &-bottomstatic {
    position: fixed;
    top: calc(100vh - 5rem);
  }

  &-bottom {
    margin-top: 2rem;
  }

  &-bottom,
  &-bottomstatic {
    color: $devider-border-color;
    text-align: center;
    font-size: 11px;

    &-forget-password * {
      font-family: $font;
      font-weight: 700;
      font-size: 0.875rem;
      color: rgba($label-color, 0.8);
      text-transform: uppercase;

      a:hover {
        text-decoration: none;
      }
    }

    &-bold {
      font-family: $font;
      font-weight: 500;
      font-size: $font-size-h4;
      color: $label-color;

      a {
        color: $theme-color;
      }
    }

    &-copyright {
      @media (max-width: 600px) {
        padding-top: 3.5rem !important;
      }
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    &-copyright,
    &-copyright>span {
      color: rgba($label-color, 0.6);
      font-size: $font-size-h6;
      font-weight: 400;

      svg {
        padding: 0 0.7rem;
      }

      a {
        color: rgba($label-color, 0.6);
        text-decoration: none;
      }
    }

    a:hover {
      text-decoration: none;
      color: $theme-color;
    }
  }

  .fx-login-text {
    padding: 0 4rem;
    font-family: $font;
    font-weight: 600;
    font-size: 1.75rem;
    letter-spacing: 1px;
    color: $label-color-large;
    text-align: center;

    &-media {
        @media(max-width:'600px') {
          padding: 0 0.5rem 0 0.5rem;
          font-size: 1.4rem;
        }
      }
  }
}

.login-page-content-card {
  background-color: #ffffff;
  padding: 2rem 2.5rem !important;

  .MuiInputLabel-shrink {
    transform: translate(0.875rem, -0.6rem) scale(1);
  }

  .MuiInputLabel-outlined {
    // margin-top: -4px !important;
  }

  .MuiInputLabel-formControl {
    font-family: $font;
    font-size: 12px;
  }

  .login-email {
    margin-top: 10px !important;
    width: 100%;
    height: auto;

    .MuiInputLabel-animated {
      color: $label-dark-blue;
    }
  }

  .forgot-password-text-input {}

  .form-label {
    font-family: $font;
    font-size: 13px;
    font-weight: 400;
  }

  .login-password {
    margin-top: 20px !important;
    width: 100%;
    height: auto;

    .MuiInputLabel-animated {
      color: $label-dark-blue;
    }

  }

  .login-signin {
    height: 3.5rem;
    width: 100%;
    padding: 11px;
    text-align: center;
    line-height: 1;
    color: $white;
    border-radius: 0.5rem;
    // box-shadow: 0 1px 3px 0 #bbcbe5;
    background-color: $theme-button-background;
  ;
    text-transform: none;
  }

  .fx-button-disabled {
    width: 100%;
  }

  .login-signin:hover {
    filter: contrast(120%);
  }

  .MuiOutlinedInput-input {
    font-family: $font;
    font-size: 1rem;
    padding: 16.5px 13px;
  }

  .login-buttonarea {
    padding-top: 19px;
    padding-bottom: 15px;
  }

  .login-buttonarea-terms {
    padding-top: 8px;
    padding-bottom: 5px;
  }
}

.login-terms-div {
  height: 40vh;
  overflow-y: scroll;
  border: 1px solid $button-border;
  border-radius: 8px;
  padding: 2rem;

}
/* login page styles end */

/* login promo page styles start */
.fx-login-promo-parent {
  background-color: $kyc-left-menu-background;
  position: relative;
  .fx-signup-left-bar-note {
    color: $text-color-secondary;
    font-size: $font-size-h4;
    font-style: italic;
    font-weight: 500;
    position: absolute;
    bottom: 7rem;
  }
}

.fx-login-promo {
  &-topmenu-mobile{
    padding-top: 1rem;
    background-color:$white;
    padding-left: 1rem;
  }
  &-list-box {
    background-color: $border-color; 
    border: .0833rem;
    border-radius: 0.41rem;
    margin: 0;
    width: 100%;
  }
  &-list-box-item {
    padding:0.55rem 0 0.55rem 1rem;
  }
  @media (min-width: 600px) {
    height: 100vh;
  }
 
  padding: 3.0rem 3.5rem 0 3.5rem;
  box-sizing: border-box;
  // background-image: url(../../assets/svg/signup-background-image.svg);
   background-color: $kyc-left-menu-background;

  .fx-theme-passport,
  .fx-card-general {
    &-mobile{
      background-color: $white;
    }
    background-color: $kyc-left-menu-background;
  }

  .fx-card-header {
    padding: 0 !important;
    height: auto !important;
    display: flex;

    .MuiDivider-root {
      padding: 2.5rem;
    }

    h2 {
      font-family: $font;
      font-size: $font-size-h1;
      line-height: 2.125rem;
      font-weight: 600;
      color: $white;
    }
  }


  .fx-card-body {
    margin-top: 2.5rem;
    padding: 0 !important;
    width: auto !important;

    h5 {
      color: $white;
      font-size: 1.105rem;
      font-family: $font;
      padding-bottom: 0.625rem;
      font-weight: 500;
      line-height: 1.56rem;
    }

    h6 {
      color: $white;
      font-size: 0.875rem;
      font-family: $font-inter;
      font-weight: 400;
      line-height: 1.25rem;
      padding: 0 !important;
    }

    .fx-selected-list {
      color: $theme-color;
    }
  }

  .login-promo-list-icon {
    svg {
      height: 1.5rem;
      width: 1.5rem;

      path {
        stroke: $theme-color;
        fill: $dark-gray-color;
      }
    }
  }

  .MuiDivider-root {
    padding: 2rem 0 1.5rem 0;
    background-image: linear-gradient(to left, $divider-color-gray 20%, rgba(255, 255, 255, 0) 0%);
    background-position: left;
    background-size: 6px 1.5px;
    background-repeat: repeat-x;
    left: 0;
    width: 100%;
    background-color: transparent;
    border: none;
  }

  .login-promo-grid {
    padding-left: 16px;
  }
}


/* forgot password styles start */
.forget-Label {
  margin-left: 30px !important;
  color: $menu-tab-color;
  font-family: $font !important;
  font-size: 18px !important;
}

.label-color {
  color: $label-color-secondary !important;
  font-family: $font !important;
  font-size: 0.93rem !important;
}
/* forgot password styles end */

/* reset password styles start */
.reset-Label {
  margin-left: -3% !important;
  color: $menu-tab-color;
  font-family: $font !important;
  font-size: 18px !important;
}
/* reset password styles end */


/* terms and condition styles start */
.fx-terms-conditions {
  h6 {
    margin: 0.5rem 0;
    font-family: $font-inter !important;
    color: $label-color;
  }

  p {
    font-family: $font-inter !important;
    text-align: justify;
  }
}

.fx-terms-condition {
  width: 47.66rem !important;
  margin-top: 2rem !important;

  .login-terms-footer {
    .MuiTypography-body1 {
      font-size: 1rem;
      font-family: $font-regular;
      margin-top: 0.5rem;
      color: $label-color;
    }

    // border-style: solid none none none;
    // border-color: $border-color;
    // border-width: thin;
    color: $theme-text-color ;
  }

  h3 {
    color: $menu-tab-color;
    font-family: $font-regular !important;
    font-size: 18px !important;
    width: 414px;
    margin-left: -21px !important;
  }

  &-copyright {
    color: $devider-border-color;
    margin-top: 20px;
    width: 100%;
    text-align: center;
    font-size: 1rem;

  }
}
/* terms and condition styles end */

//skelton for full page. showing after keycloak login
.fx-skelton-fullpage {
  width:100%;
  margin-bottom: 1rem;
  .MuiCardHeader-avatar{
    .MuiSkeleton-root{
      height:3rem;
      width: 3rem;
    }
  }
  .MuiCardHeader-content{
    .MuiCardHeader-title{
      margin-bottom: 1rem;
      .MuiSkeleton-wave{
        height: 1rem;
        width: 80%;
      }
    }
    .MuiCardHeader-subheader{
      .MuiSkeleton-wave{
        height: 1rem;
        width: 50%;
      }
    }
  }
  .MuiSkeleton-root{
    height: 50vh;
  }
  .MuiCardContent-root {
    .MuiSkeleton-root:first-child{
      height: 1rem;
      width: 70%;
      padding-bottom: 1rem;
    }
    .MuiSkeleton-root:last-child{
      height: 1rem;
      width: 50%;
      padding-bottom: 1rem;
    }
  }
}

.fx-contact-us-form-body{
  gap: 0.2rem !important;
  justify-content: center !important;
  margin-top: 1.9rem !important;
  margin-bottom: 1.9rem !important;
  > div{
    text-align: center !important;
    padding: 0rem !important;
  }
  &-items{
    height: 2rem !important;
    display: inline-flex !important;
    align-items: center !important;
    text-align: center !important;
    gap: 0.3rem !important
  }
  &-numbers{
    color: $theme-color !important;
  }
}
.fx-contact-us-main-text{
  color: $dark-grey!important;
  font-weight: 600 !important;
}
.fx-contact-us-note-text{
  text-align: center !important;
  justify-content: center !important;
  &-colored{
    color: $dark-grey !important;
  }
}