/* view profile styles start */
.profile-icon-account-details {
  margin-left: 79%;
  margin-top: -9%;
}

.fx-view-profile-links {
  position: fixed !important;
  margin-top: 45px;
  margin-left: -40px;
  padding: auto;

  .MuiPopover-paper {
    width: 120px;
  }

  .MuiMenuItem-root {
    font-size: 14px !important;
    padding: 12px;
    font-family: $font-regular !important;
    color: $input-value-color !important;
  }
}

.fx-view-Logout-icons {
  width: 25px !important;
  height: 20px !important;
  padding-left: 5px;
  margin-right: 10px;
}

.fx-view-profile-icons {
  width: 20px !important;
  padding-left: 5px;
  margin-right: 15px;

}

.fx-icon-width {
  width: 20px !important;
  height: 20px !important;
}

.fx-inline-flex {
  display: inline-flex;

  .MuiGrid-root {
    padding-top: 0;
  }
}

.fx-view-action {
  >div:not(:last-child) {
    padding-right: 1rem;
    @media (max-width: 600px) {
      padding-right: 0.7rem;
    }
  }
}
/* view profile styles end */

/* account cards styles start */
.fx-account-summary-card {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0 !important;
  .fx-card-header{
    margin-left: 0 !important;
  }
  &-balance-text{
    h6{
      font-family: $font;
      font-size: $font-size-h5;
    }
    &-grid{
      padding-top: 1rem;
    }

  }
  &-progress{
    .MuiLinearProgress-root {
      background-color: $debit-background-second;
      border-radius: 1.67rem;
      height: 0.8rem;
    }
    .MuiLinearProgress-barColorPrimary {
      background-color: $theme-color;
    }
    .MuiLinearProgress-colorPrimary {
      background-color: $dark-grey-background;
    }
  }
  &-balance-view{
    .fx-currency {
      * {
        font-weight: 500 !important;
        font-size: $font-size-h5 !important;
      }
      &:not(&.fx-color-red) {
        color: $text-color;
      }
      &-value{
        font-size: $font-size-h4 !important;
      }
    }

  }
  &-center {
    height: auto;

    &-theme-text {
      color: $theme-color;
      font-family: $font !important;
      font-weight: 600;
      line-height: 1.75rem;
      font-size: 1rem !important;
      svg{
        height: 0.3rem !important;
        width: 0.3rem !important;
      }

      &.fx-view-detail-text {
        font-weight: 500;
        line-height: 1rem;
        font-size: 1rem !important;
        text-transform: none;
        padding-right: 0.3rem;
      }
    }
    &-type-text {
      color: $label-color-secondary;
      font-family: $font !important;
      font-weight: 400;
      font-size: 1.5rem;
      svg {
        transform: translateY(-0.15rem);
        height: 0.3rem !important;
        width: 0.3rem !important;

        circle {
          fill: $label-color-secondary;
        }
      }
    }
    &-bal-text {
      color: $label-color-secondary;
      font-family: $font-inter !important;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.3125rem;
    }
    &-no-account-text {
      color: $label-color-secondary;
      font-family: $font !important;
      font-weight: 500;
      font-size: $font-size-h5;
      line-height: 1.3125rem;
    }
    &-account-logo {
      svg{
        height: 3rem;
        width: 3rem;
      }
    }
    &-account-info {
      h4,h5,h6{
        font-family: $font;
        font-size: 0.97rem !important;
      }
      @media (max-width: 900px) and (min-width: 601px) {
        h6{
          font-size: 1.1rem !important;
        }
        h4,h5{
          font-size: 1rem !important;
        }
      }
      h6{
        font-weight: 600;
      }
      h4{
        font-weight: 500;
        color:$label-color-secondary;
      }
      h3{
        font-weight: 400;
        color:$label-color-secondary;
      }
      .fx-link:hover{
        color: rgba($theme-color,0.5);
        h6{
          color: rgba($theme-color,0.5);
        }
      }
    }
    .fx-button-rounded{
      border-color: $border-color !important;
      svg{

      }
    }

    .fx-manage-ppi-summery {
      .fx-ppi-copy-to-clipboard {
        justify-content: flex-end;
      }
      h6{
        font-family: $font;
        font-size: 0.95rem !important;
        font-weight: 400;
        color: $label-color-4;
        @media (max-width: 900px) and (min-width: 601px) {
          font-size: 1rem !important;
        }
      }
      button,a{
        padding: 0;
      }
      .MuiIconButton-root:hover{
        background-color: transparent;
      }
      svg{
        height: 1rem !important;
        width: 1rem !important;
        path{
          stroke: $label-color-secondary !important;
        }
      }
    }
  }
  &-view-detail {
    padding: 0 !important;

    .fx-view-detail-arrow-icon {
      path {
        stroke: $theme-color;;
      }
    }
  }
  &-bal-card{
    padding:1.66rem 1.66rem 1.66rem 2rem;
    height: 19rem;
    background-color: $body-background;
    &-bal-text{
      font-size: 1rem;
      font-family: $font-inter !important;
      color: $label-color-secondary;
      display: flex;
      align-items: center;
      svg {
        margin-left: 0.4rem;
        margin-top: 0.4rem;
        width: 1rem;
        height: auto;
      }
    }
    .MuiDivider-root{
      background-color: $border-color;
    }
  }

  &-accountbalance{
    padding:1.75rem 1.75rem 0 1.75rem;

    background: linear-gradient(180deg, $grey-background 0%, rgba(248, 250, 251, 0) 100%);
    h5{
      font-weight: 500;
      color: $label-color-secondary;
      font-size: 1.15rem;
      font-family: $font;
      @media (max-width: 900px) and (min-width: 601px) {
        font-size: 1.2rem;
      }
    }
  }

  &-side{
    &-body{
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    &-header{
      justify-content: space-between;
      .fx-button-rounded{
        border-color: $border-color !important;
      }
    }
  }

  .fx-divider-account {
    margin: 0.4rem 0;
    background-color: $light-blue-border;
    height: 2px;
    left: 0;
    width: 100%;
  }

  .fx-arrow-icon-holder {
    margin-bottom: 16px;
  }

  .fx-fees-summary{
    align-items: center;
    margin-top: 1rem;
  }

  .balance-amount-green {
    color: $light-green-color;
    font-family: $font-nunito;
    font-weight: 700;
    font-size: 1.4375rem;
  }

  .balance-amount-red {
    color: $black-color !important;
    font-family: $font-nunito;
    font-weight: 700;
    font-size: 1.4375rem;
  }


  .fx-debit-card {
    border-radius: 6px;
    width: 3rem;
    @media (max-width: 900px) and (min-width: 601px) {
      width: 4rem;
    }
    svg{
      height: 2.4rem !important;
      width: 3rem !important;
      @media (max-width: 900px) and (min-width: 601px) {
        width: 4rem !important;
        height: auto !important;
      }
    }
  }


  .fx-debit-card-left {
    &-padding{
      padding: 0 0 0 12px;
    }
    h5{
      color: $label-color-secondary !important;
      font-family: $font;
      font-weight: 500;
      font-size: 0.875rem;
      @media (max-width: 900px) and (min-width: 601px) {
        font-size: 1rem;
      }
    }
    h4{
      color: $black-color !important;
      font-family: $font;
      font-weight: 500;
      font-size: 0.875rem;
    }
    h6{
      color: $label-color-5 !important;
      font-family: $font;
      font-weight: 300;
      font-size: 0.875rem;
    }
    svg{
      transform: translateY(-0.15rem);
      height: 0.3rem !important;
      width: 0.3rem !important;
      circle{
        fill: $label-color-secondary;
      }
    }

    &-issued-date{
      color: $label-color-5 !important;
      font-family: $font;
      font-weight: 300;
      font-size: 0.875rem;
    }
  }



  .fx-txn-arrow {
    background-repeat: no-repeat;
    //  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    //  background-color: $white;
    background-position: center;
    text-align: center;
    padding-right: 1rem;
    &-green {
      background-color: rgba($success-background, 0.05);
      border-radius: 0.375rem;
    }

    &-gray {
      background-color: $paper-background;
      border-radius: 0.375rem;
    }

    svg {
      height: 2.5rem;
      width: 2.5rem;
      g{
        transform: scale(0.7);
        transform-origin: center;
      }
    }
  }

  .hide-eye {
    border-radius: 100%;
    background: rgba(224, 224, 224, 0.5);
    margin-left: 0.4rem;

    svg {
      vertical-align: middle;
      height: 2rem;
      width: 2rem;
    }
    &-no-debit-card{
      margin-bottom: 1rem;
    }

  }

  .hide-eye-holder {
    // padding-left: 12px;
    display: flex;
  }

  &-icons {
    .MuiIconButton-root {
      padding: 0.3rem !important;

      svg {
        height: 1.25rem;
        width: 1.25rem;

        path {
          fill: $light-gray-color;
        }
      }
    }

    &-no-fill-wallet {
      svg path:not(:nth-of-type(2)) {
        fill: none !important
      }

      svg path:nth-of-type(3) {
        fill: $white !important
      }
    }
  }
  &-graph-label{
    .MuiTypography-subtitle1{
      font-weight: 500;
      font-size: 0.875rem;
      color: $label-color-secondary;
    }
    .fx-currency-value{
      margin-top: -0.5rem;
    }
  }

  &-accountgraph {
    height: 37rem;
    padding: 1.75rem !important;

    &-header{
      height: 2.5rem !important;
    }

    &-body {
      height: 29.5rem !important;
    }

    &-data {
      height: 18.75rem !important;
    }

  }
}
/* account cards styles end */

/* home page ledger list block styles start */
.fx-home-ledger-list {
  h5,
  h4 {
    font-family: $font;
    letter-spacing: 0.5px;
  }

  h6 {
    font-family: $font-inter;
    letter-spacing: 1px;
    font-size: 1rem;
    font-weight: 500;
    color: $label-color-secondary;

  }

  h4 {
    font-size: 1.15rem;
    font-weight: 500;
    color: $label-color-secondary;
  }

  h5 {
    font-size: 1rem;
    font-weight: 400;
    color: $label-color-secondary;
  }

  &-headerText {
    text-transform: uppercase;
    color: $light-gray-color;
    background-color: $table-passport-th-background;
    padding: 0.4rem 5.5rem;
    border-radius: 0.6rem;
  }
  hr {
      width: 99.5%;
      margin: auto;
  }
  &-column{
    border-left: 0.25rem solid transparent;
    border-radius: 0.4rem;
    padding: 0.7rem 1rem;
    position: relative;



    &:hover {
      background-color: $tr-hover;
      border-left: 0.25rem solid $theme-color;
      .fx-hover-card{
        display: inline;
      }
    }

    &-icon{
      padding-right: 1rem;
    }
    &-padding{
      padding: 0.1rem;
    }
    &-status-mode{
        .fx-status-filled{
          font-size: 0.875rem !important
        }
    }
    &-pointer-event{
      cursor: pointer;
    }
  }
  &-portfolio-text {
      font-size: $font-size-h5 !important;
      font-weight: 400;
      color: $label-color-secondary;
      font-family: $font;
  }

}
/* home page ledger list block styles end */


/* thank you page styles start */
.fx-thankyou-signup {
  background-color: $white;
  border-radius: 0.6rem;
  box-shadow: $card-shadow;
  padding-bottom: 4rem !important;
  padding-top: 2rem !important;

  h6{
    text-align: center;
  }
}

.fx-thankyou {
  padding: 0;

  &-message {
    padding-bottom: 1.5rem;
    font-size: 1.125rem !important;
    color: $label-color-secondary;
    font-weight: 500;
  }

  h3 {
    font-family: $font !important;
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    color: $label-color;
    padding-bottom: 1rem;
  }

  h5,
  h6 {
    font-family: $font-inter;
  }

  h5 {
    font-size: 1.5rem;
    color: $label-color;
    padding-bottom: 1rem;
  }

  h6 {
    font-size: 1.125rem;
    font-weight: 400;
  ;
    color: rgba($label-color, 0.8);
  }
}

.fx-link-thank-you {
  text-align: center;
  color: $theme-color;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9375rem;
  font-family: $font-inter;
  margin-left: 1rem;
  margin-right: 0.5rem;
  text-transform: uppercase;

}

.fx-thanks-arrow-right{
    path{
      stroke: $theme-color;
    }
}
.fx-thanks-ea{
  &-message{
      font-size: $font-size-h4 !important;
      color: $label-color-secondary;
      font-weight: 500;
  }
  &-image{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.fx-thank-you-page-button {
  .MuiButton-endIcon {
    padding-top: 0;
  }
}

/* thank you page styles end */

/* Help Center styles start */
.fx-help-center {
  h4 {
    font-weight: 600;
    font-size: 1.125rem;
    color: $dark-gray-color;
    font-family: $font-inter-semibold;
  }

  h5 {
    font-weight: 500;
    font-size: 0.875rem;
    color: $light-gray-color;
    font-family: $font-inter-medium;
    margin-bottom: 0.5rem;
  }

  h6 {
    font-weight: 400;
    font-size: 0.8125rem;
    color: $light-gray-color;
    font-family: $font-inter;
    margin-top: 0.75rem;
  }

  svg {
    height: 8rem;
    width: 8rem;
    margin-bottom: 1.5rem;
    defs{
      linearGradient {
        stop {
          stop-color: rgba($theme-color,1);
          stroke-opacity: 0.1;
        }
      }
    }
    path{
      fill:rgba($theme-color,0.1);
      stroke:$theme-color !important;
    }
  }
}

.fx-need-help{
  .fx-card-header {
    font-size: $font-size-h2;
  }
  .MuiTypography-h3 {
    font-size: $font-size-h3;
    font-family: $font;
    font-weight: 600;
    color: $label-color-secondary;
    padding-bottom: 1rem;
  }
  .MuiTypography-body1{
    font-weight: 300;
    color: $label-color-secondary;
    padding-left: 1rem;
  }
  &-text{
    padding-bottom: 1rem;
  }
}
/* Help Center styles end */

/* page not found styles start */
.fx-404 {
  margin-top: 25vh;

  h3,
  h2 {
    font-family: $font-nunito ;
    font-weight: 900;
  }

  h3 {
    font-size: 6rem;
    color: $black-color;
  }

  h2 {
    font-size: 7rem;
    font-family: $font ;
    color: $theme-color;
  }

  h5 {
    font-size: 1.5rem;
    font-family: $font ;
    font-weight: 600;
    color: $label-color;
  }
  h6 {
    font-size: 1.0rem;
    font-family: $font ;
    font-weight: 500;
    color: $label-color-secondary;

  }
  a{text-decoration: none;}
}
/* page not found styles end */


.fx-padding-header-width-changes{
  padding : 0.4rem 1.0rem !important;
  margin-left: 1rem;
}

.fx-stepper {
  padding: 2rem 0 0 0;
  @media (max-width: 999px)  {
    padding: 1rem 0  0 1.5rem!important;
    svg{
      width: 2.49rem;
      height: 2.49rem;
    }
  }
  .MuiStepper-root {
    @media (max-width: 600px)  {
      width: 29rem;
      height: 10.5rem;
      padding-left: 0;
    }
    flex-grow: 1;
    box-shadow: none;
    background-color: $grey-background;
    height: 11.5rem;
    padding: 3rem 1rem 1rem 1rem;
    border:$border-card !important;
  }

  .MuiStepConnector-lineHorizontal {
    @media (max-width: 600px)  {
      margin-top: 0.2rem;
      width: 2rem;
      transform: translateX(0.5rem);
    }
    margin-top: 1rem;
    border-top-style: dotted !important;
    border-color:$label-color-5;
  }
  .MuiStepLabel-label{
    @media (max-width: 600px)  {
      margin-top: 0.2rem;
      font-size: $font-size-h6;
    }
    color:$label-color-secondary;
    font-size: $font-size-h4;
    font-weight: 500;
    font-family: $font;
  }
  .MuiStepper-alternativeLabel{
    @media (max-width: 600px)  {
      border: 0.1rem;
      border-radius: 0.375rem;
    }
    
  }
  .MuiStepLabel-alternativeLabel{
    @media only screen and (min-width: 601px) and (max-width: 999px) {
     padding-top: 0.39rem;
     margin-top: 0;
    }
    
  }
}

/*permission denied css*/
.fx-permission-denied{
  &-link-color{
    color: $blue !important;
  }
}

//no access
.fx-no-access{
  padding-top: 2rem;
  &-title{
    font-family: $font ;
    font-weight: 400;
    font-size: 1.0rem;
    color: $label-color;
  }
  h3,
  h2 {
    font-family: $font-nunito ;
    font-weight: 900;
  }
  h3 {
    font-size: 6rem;
    color: $black-color;
  }
  h2 {
    font-size: 7rem;
    font-family: $font ;
    color: $theme-color;
  }
}
//quick transfer header issue
.fx-quick-transfer-header {
  padding: 0;
  &-text{
    padding: 0 !important;
    font-size:  $font-size-h2 !important;
    font-weight: 500 !important;
    font-family: $font;
    letter-spacing: normal;
  }

  .MuiAccordionSummary-root{
    padding: 0 1.75rem;
    height: 6rem;
  }

  .MuiAccordionSummary-content.Mui-expanded{
    padding: 0;
  }
  .MuiAccordionDetails-root{
    border-top: 1px solid $border-color;
    padding: 0 1.75rem;
  }

  &-summary{
    padding-top: 1rem !important;
  }
}

.clear-number-field-arrow{
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
  }
}

// quick transfer starts here
.fx-quick-transfer {
  .fx-input-edit [class*="-singleValue"] {
    padding-left: 0.8rem;
  }
  padding-bottom: 0rem !important;
  padding-top: 0rem !important;
  .fx-container-create-schdule-middle-scroll{
    padding: 1.5rem 0 0 0;
    overflow-x: hidden;
  }
  .fx-create-schedule-custom-title{
    margin-bottom: 0.625rem;
    display: block !important;
  }
  .fx-schedule-create-tab-container{
    margin: 0 0 0 0 !important;
    .fx-link-sel {
      background-color: $white;
      color: $theme-color;
    }
    .MuiGrid-root{
      position: relative;
      width: 5.375rem;
      text-align: center;
    }
    .MuiGrid-root:after{
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $theme-color;
      width: 2.83rem;
      height: 0.25rem;
      margin: auto;
      border-radius: 1rem 1rem 0 0;
    }
  }
  &-submit-button{
    width: 100%;
  }
  .fx-card-header{
    padding: 0 0 0.5rem 0;
  }
  .fx-button{
    height: 3.5rem;
    font-size: 1.16rem;
  }
  .fx-account-summary-card-center-theme-text{
    font-size: 1rem;
    svg{
      transform: translateY(-0.15rem);
      height: 0.3rem !important;
      width: 0.3rem !important;
      circle{
        fill: $theme-color;
      }
    }
  }
  &-link{
    .fx-link {
      color: $light-gray-color !important;
      min-width: 2rem;
      margin-right: 1rem;
      font-weight: 500;
      font-size: 1rem;
    }
    .fx-link-sel {
      color: $theme-button-background !important;
      padding-bottom: 0.55rem;
      border-bottom: 0.25rem solid $theme-button-background ;
      border-bottom-width: 0.2rem;
      border-radius: 1rem 1rem 0 0;
    }
    .fx-link-sel:after{
      position: relative;
    }
  }
  &-divider{
    .MuiDivider-root{
      margin-top: 0.65rem;
    }
  }
  .fx-create-schedule-subheading{
    font-weight: 500;
  }
  &-autocomplete-group{
    position: relative;
    padding-top: 0rem;

    .fx-label-top{
      font-size:$font-size-h5 !important;
      position: absolute;
      top: -0.8rem;
    }

  }
  .MuiDivider-root{
    background-color: $border-color;
  }
  .fx-container-create-schdule-middle-scroll > div{
    padding-bottom: 2rem;
  }
}
// quick transfer ends here


//Account select color starts here
.fx-account-list-menu{
  color: $white;
}
.fx-account-list-menu:hover{
  color: $theme-color;
}
.fx-account-list-menu-sel{
  color: $theme-color !important;
  font-size: $font-size-md;
  font-weight: 600;
  line-height: 1.95rem;
}
//Account select color ends here

//digest-amount
.digest-amount {
  svg {
    height: 25px;
    max-width: 147px;
    width: 100%;
    min-width: 128px;
  }
}

.digest-amount-insight {
  svg {
    height: 20px;
    margin-left: -40px
  }
}

//graph start here
.fx-account-insight {
  box-sizing: border-box;
  margin-bottom: 2rem;

  &-info {
    height:27rem !important;
    padding-right: 2rem;
    @media (max-width: 900px) {
      padding-right: 0;
      margin-bottom: 2rem;
    }
  }

  .fx-account-summary-card-bal-card {
    .digest-amount {
      height: 100%;
      align-items: center;
      display: flex;
    }

    .fx-debit-card-link svg {
      @media (max-width: 900px) and (min-width: 601px) {
        width: 0.7rem;
        height: auto;
      }
    }

  }

  &-graph {
    height:27rem !important;
    &-dashboard{
      padding: 0 1rem 0 0;
    }
    &-container {
      position: relative;
      width: 100%;
      .recharts-surface {
        .recharts-area {
          .recharts-layer {
            path:first-of-type {
              // fill: $theme-button-background;
            }

            path:last-of-type {
              stroke: $theme-color;
            }
          }
          .recharts-area-dots{
            circle{
              stroke:$theme-color !important;
            }
          }
        }
        .recharts-active-dot{
          circle{
            fill:$theme-color !important;
          }
        }

        linearGradient {
          stop {
            stop-color: rgba($theme-button-background,0.18);
          }
        }
      }

      .recharts-legend-item-text{
        color: $theme-color !important;
      }
      .recharts-legend-item{
        .recharts-surface{
          border-radius: 2px;
          path{
            fill: $theme-color;
          }
        }
      }
      .recharts-tooltip-cursor{
        stroke: $border-color;
      }
      .recharts-cartesian-axis-tick {
        text {
          color: $label-sec;
          fill: $label-sec;
          font-size: 0.875rem;
          font-weight: 400;
        }
        line{
          stroke: $border-color;
        }
      }
      .recharts-cartesian-axis{
        line{
          stroke: $border-color;
        }
      }
      .recharts-reference-line{
        line{
          stroke: $theme-color;
        }
      }
    }

    &-link {
      .fx-link {
        color: $label-sec !important;
        min-width: 2rem;
        margin-right: 1rem;
        font-weight: 400;
        font-size: 0.875rem;
      }
      .fx-link-sel {
        font-size: 0.875rem;
        color: $theme-button-background !important;
        padding-bottom:0.2rem;
        min-width: 2rem;
        margin-right: 1rem;
        border-bottom: 2px solid $theme-button-background ;
        border-radius: 0.1rem;
      }

    }

    &-no-data,
    &-data {
      height:23.5rem !important;
      padding-top: 1rem;
    }

    .fx-area-chart-tooltip {
      background-color: $dark-gray-color;
      border-radius: 6px;
      color: $white;
      padding: 0.5rem 1rem;
      line-height: 0.25rem;

      :first-child {
        font-family: $font-nunito;
        font-weight: 700;
        font-size: 1.125rem;
      }

      p:nth-of-type(2) {
        font-size: 1rem;
        font-weight: 400;
      }
    }

    .recharts-legend-item {
      // position: absolute;
      transform: translate(0.4rem, 1.25rem);

      svg {
        height: 1rem;
        width: 1rem;
        scale: 0.8;
      }
    }
    .recharts-cartesian-grid-horizontal{
      line{
        stroke: $border-color;
      }
    }

  }

  .recharts-default-legend{
    transform: translateY(-0.5rem);
  }

  &-title{
      h3{
          font-size: $font-size-h2;
      }
  }
  &-ac-statement{
    padding-left: 0.6rem;
    font-size: $font-size-h5;
  } 
  &-ac-statement-btn{
    background-color:$white;
    border: solid 1px $theme-color;
    border-radius: 0.416rem;
    color: $theme-color;
    height:2.5rem !important;
    box-shadow: none;
    padding: 1rem;
    svg {
      path{
        fill: $white;
        stroke: $theme-color;
      }
      width: 1rem;
      height: 1rem;
    }
    &-dropdown-fill-color{
      path{
        fill:$white !important;
      }
    }

  }
}
//graph ends here

//daily transaction summary card
.fx-daily-transaction-summary {
  color: $text-color;
  &-card-body {
    padding: 1.8rem 0 1.3rem 0;
  }
  .fx-card-header {
    padding: 0;
  }
  &-day-select {
    max-width: 31%;
    .MuiInputBase-formControl {
      height: 2.9rem;
    }
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px !important;
    }
  }
  .fx-header-text {
    background-color: $table-passport-th-background;
    border-radius: 0.4rem;
    font-weight: 500;
  }
  &-date {
    padding: 0.3rem 0.5rem;
    font-size: 1rem;
    letter-spacing: 0.25px;
    width: fit-content;
  }

  &-list-headerText {
      text-transform: uppercase;
      padding: 0.4rem 0.8rem;
  }

  &-list-item {
    margin-bottom: 1.3rem;

    &:last-child{
      margin-bottom: 0;
    }
    > div {
      padding: 0.8rem;
      padding-bottom: 0;
    }

    &-content {
      p {
        text-transform: uppercase;
        font-weight: 300;
        display: flex;
        span {
          display: inline-block;
        }
      }
    }
  }

  &-card-footer {
    p {
      font-size: 0.75rem;
      padding-bottom: 2rem !important;
    }
    .fx-link {
      color: $theme-color;
    }
  }

  .fx-no-data {
    padding: 4rem;
    text-align: center;
  }
}

.fx-deposit-funds-title{
  font-size: $font-size-h3;
  font-weight: 500;
}
.fx-grey-background{
  background-color: $body-background;
}

.fx-padding-header{
  padding : 0.4rem 1.0rem !important;
}
.fx-user-info{
    text-transform: uppercase;
    background-color: $table-passport-th-background;
    padding: 0.5rem 1rem;
    border-radius: 0.6rem;
    font-size: $font-size-h5 !important;
}
.ppi-spacing{
  margin-top: 0.5rem !important;
}
.debit-card-spacing{
  margin-bottom: 1.5rem !important;
}
.fx-accountinfo {

  &-typography-sub-section{
    padding-bottom: 0.4rem !important;

    &-currency{
      margin-left: 0.5rem !important;
    }
  }

  &-account-text{
    font-size: $font-size-md;
    font-weight: 600;
    line-height: 1.95rem;
    margin-top: 0.5rem !important;
  }
  &-logo{
    padding: 0.5rem 0;
    cursor: pointer;
    svg{
      width: 2.66667rem;
      height: 2.66667rem;
    }
  }
  &-details{
    padding-left: 0.75rem;
  }

  &-section-space {
    margin-bottom: 2.17rem;
  }

  &-account{
    margin: 1.75rem 0rem 1rem 0rem;
    padding: 0rem 1.75rem;
  }

  &-rect-color{
    svg path{
      fill: $theme-color
    }

    &-debit{
      p {
      font-size: $font-size-h5 !important;
      }
      svg {
        height: 1rem !important;     
      }
    }
  }
  &-balance-text{
    max-width: max-content;
  }
  
  &-amount-value{
    margin-top: 1.6rem;
  }

  &-title{
    padding: 0.5rem 0rem 0.5rem 0.5rem;
  }

  &-note{
    font-size: $font-size-h5;
    font-weight: 500;
    line-height: 1.3125rem; 
    color: $disabled-button-color;
    padding: 0.5rem 0.5rem 0.5rem 2.25rem !important;
  }

  &-balance-title {
    font-size: $font-size-h3 !important;
    font-weight: 500;
    line-height: 1.725rem;
  }
  &-balance-subtitle {
    color: $disabled-button-color;
    font-weight: 400;
    font-size: $font-size-h4 !important;
    display: flex !important;
    align-items: center !important;
  }

  &-available-balance {
    padding: 0;
  }

  &-available-balance-text-space {
    padding: 1.75rem !important;
    height: 11rem;
    background: $white;
    min-width: fit-content;
    border-left: 0.25rem solid $theme-color;  
  }

  &-available-balance-text {
    p{
      font-weight: 500;
      text-wrap: nowrap;
      white-space: pre;
    }
  }

  &-balance-details-text {
    line-height: 1.5rem !important;
  }

  &-balance-space{
    margin-bottom: 3.58rem;
  }

  &-info-icon{
    margin-top: 0.3rem;
  }

  &-divider {
    margin-bottom: 0.7rem !important;
  }

  &-modal {    
    padding: 1.75rem;
    &-section {
      &-main {
        font-size:  $font-size-h2 !important;
        font-weight: 500 !important;
        line-height: 2.25rem !important;
      }

      &-header{
        margin-bottom: 1rem;
        padding-bottom: 0 !important;
      }
      &-title{
        padding: 0.5rem;
      }
      &-title-space {
      margin-bottom: 0.3rem;
      border-radius: 0.25rem;
      }
      &-subtitle {
        height: 2.75rem !important;
        padding: 0.5rem;
      }
      &-tooltips{
        padding-left: 0.59rem !important;
      }
    }
  }

  &-hold-split-link {
    > span {
      display: flex;
    }
    &:hover * {
      color: $theme-color !important;
    }
  }

  &-balance-details{
    padding: 1.75rem !important;
    height: 1.5rem;
    margin-top: -2rem !important;

    &-avail-section{
      display: flex;
      justify-content: flex-end;
      
      &-button{
        border-radius: 0.3rem !important;
        white-space: nowrap;
        padding: 0.125rem 0.625rem !important;
      }
      
    }
  }

  &-account-value{
    margin-left: 1.167rem;
  }

  &-inactive-account{
    font-size: $font-size-h5;
    font-weight: 500;
    line-height: 1.5rem;
    color: $text-color-secondary;
  }
  &-active-account{
    h3{
      font-size: $font-size-h4;
      font-weight: 500;
      line-height: 1.3125rem;
      color: $text-color-secondary;
    }
  }

  &-balance-account{
    > div {
      padding:  0 !important;
    }
  }

  &-balance-inactive{
    padding-top: 0.75rem;
  }
}

.fx-dashboard-toggle-icon {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.82rem 1.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid $border-color;
  background: $grey-background;
  svg {
    color: $border-color !important;
    flex-shrink: 0;
  }
}

.fx-available-balance-ledgers-icon{
  margin-left: -1.2rem;
}

.fx-faster-funds-section{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: $body-background;
  gap: 1.3125rem;
  font-weight: 500;
}

.fx-available-faster-funds {
  &-title {
    margin-right: 0.2rem;
  }
  .fx-dark-grey-icon {
    margin-left: 0 !important;
    height: 1.2rem !important;
    width: 1.4rem !important;
  }
  &-account-info {
    &-title {
      margin-right: 0.3rem;
    }
    .fx-dark-grey-icon {
      margin-right: 0.3rem !important;
      height: auto !important;
      width: auto !important;
    }
  }
}

.fx-thunder-icon-svg{
  gap: 0.625rem;
  
  svg {
    path{
      stroke: none !important;
    }
  }
}

.fx-ledger-summary-action-card{
  margin-left: -0.375rem;
  display: flex;
  align-items: center;

  &-buttons{
    display: flex;
    gap: 0.75rem;
  }
}

.fx-ledger-summary-balance-text{
  font-weight: 500 !important;
}

.fx-account-activity-summary-header{
  justify-content: space-between;
  align-items: center;
  background: $body-background;
  border-left: 0.35rem solid $theme-color;  
  padding: 1rem;
}

.fx-metadata-icon{
  display: inline-flex;
  margin-top: 2px;
  svg{
    g{
      path{
        fill: $theme-color !important;
      }
    }
  }
}

.fx-account-info-debit-card-section{
  padding: 0.75rem 1.75rem 1.75rem 1.75rem !important;
  margin-top: -2rem !important;
}

.fx-debit-card-section-divider{
  margin-bottom: 1rem !important;
}
.fx-account-info-header-title{
  padding-bottom: 0.4rem !important;
}

.fx-quick-transfer-card {
  form {
    width: 100%;
  }
  .fx-card {
    padding: 0;
  }
  .fx-card-header {
    padding: 1.75rem;
  }

  .fx-card-body {
    padding: 1.75rem 1.75rem 0 1.75rem;
  }

  .fx-card-footer {
    padding: 0 1.75rem 1.75rem 1.75rem;
  }

  .fx-button {
    height: 3.5rem;
    font-size: 1.16rem;
    margin-top: 2rem;
  }

  .fx-card-divider {
   border-bottom: 1px solid $border-color;
  }
}

.fx-deposit-plus-account-info-card {
  .fx-account-summary-card-accountgraph-body {
    height: auto !important;
    .fx-account-summary-card-center, .fx-account-summary-card {
      height: 100% !important;
    }
  }
  .fx-deposit-account-info{
    &-account-card {
      padding: 1.75rem 0;
    }

    &-account{
      padding: 0 1.75rem;
      margin-bottom: 3rem;
    }

    &-available-balance-text{
      p{
        font-weight: 500;
        font-size: $font-size-h4;
        white-space: pre;
      }
      &-space {
        padding: 1rem 0rem 1rem 1.75rem !important;
        background: $white;
        min-width: fit-content;
        border-left: 0.25rem solid $theme-color;
      }
    }

    &-bottom-section{
      padding: 1.5rem 1.5rem 0 1.5rem;
      margin-top: 3rem;
      &-logo{
        border-radius: 0.33333rem;
        border: 1px solid $border-color;
        padding: 0.75rem;
        background-color: $white;
      }
      &-text{
        font-size: $font-size-h5;
        font-weight: 400;
        text-transform: uppercase;
      }
      &-value{
        .fx-currency-value{
          font-size: $font-size-md !important;
        }
      }
    }
  }
}

.fx-return-tooltip .MuiTooltip-tooltip {
  margin-left: 0rem !important;
  margin-top: 0.66rem !important; 
}