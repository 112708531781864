/* fx drawer left layout common class */
.fx-drawer-left-layout {
  height: 100%;

  >div {
    padding: 3rem 3.3rem 0 3.3rem;
    align-content: space-between;

    @media (max-width: 800px) {
      padding: 1rem 1rem 0 1rem;
    }

    >div {
      height: fit-content;

      &:not(:last-child) {
        margin-bottom: 1.8rem;
      }
    }
  }

  h1 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  h1,h2,h3 {
    margin: 0;
  }

  color: $text-color;

  &-footer-prenote{
    font-size: $font-size-sm;
    font-style: italic;
  }
}

/* fx drawer base layout styles */
.fx-drawer-base-layout {
  background: $grey-background;

  >div {
    height: 80%;
  }

  &-info-item {

    h3 {
      font-weight: 500;
      font-size: 1.14rem;
    }

    .fx-info-item-icon {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 0.5rem;

      svg {
        min-height: 2.5rem;
        min-width: 2.5rem;
      }
      .fdic-icon-theme{
        path:nth-of-type(2),
        path:nth-of-type(3),
        path:nth-of-type(6){
          fill: $theme-color !important;
        }
      }
      .fast-fund-icon-theme{
        path:nth-of-type(2),
        path:nth-of-type(3),
        path:nth-of-type(4),
        path:nth-of-type(5) {
          fill: $theme-color !important;
          stroke: $theme-color !important;
        }
      }
      .debit-card-icon-theme{
        path:nth-of-type(1),
        path:nth-of-type(2),
        path:nth-of-type(3),
        path:nth-of-type(4),
        path:nth-of-type(5),
        path:nth-of-type(6),
        path:nth-of-type(7),
        path:nth-of-type(8),
        path:nth-of-type(11) {
          fill: $dark-theme-color !important;
        }
      }
      .vendor-icon-theme{
        path:nth-of-type(2),
        path:nth-of-type(3),
        path:nth-of-type(4){
          fill: $dark-theme-color !important;
        }
      }
      .collect-store-icon-theme{
        path:nth-of-type(3),
        path:nth-of-type(4){
          fill: $theme-color !important;
        }
        path:nth-of-type(4){
          stroke: $theme-color !important;
        }
      }
    }

    p {
      width: 21rem;
      color: $paragraph-color;
      font-size: 1.167rem;
      margin: 0;
      margin-top: 0.58rem;
      line-height: 1.67rem;
      font-weight: 400;
    }
  }

  .fx-left-layout-header {
    color: $theme-color;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow-wrap: anywhere;
    margin-bottom: 1.5rem;

    h2 {
      font-size: 2rem;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 1px;
    }

    .fx-logo {
      margin-right: 0.3rem;

      svg {
        min-width: 3.9rem;
        min-height: 3.9rem;
        margin-top: 0.2rem;
      }
    }

  }

  hr {
    color: $divider-color;
    height: 0.125rem;
  }

  .fx-left-layout-passport {
    h2 {
      font-size: 1.667rem;
      line-height: normal;
      font-weight: 600;
    }

    >div {
      display: flex;
      align-items: center;

      svg {
        width: 8.25rem;
        height: 1.97rem;
      }
    }
  }

  .fx-simple-pricing {
    display: flex;
    align-items: center;
    height: 4.5rem;
    margin: 0.7rem 0;

    &-content {
      background: $light-blue;
      border-radius: 0.625rem;
      padding: 1rem 0.625rem;
      width: 100%;
      margin-left: -1.5rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        margin: 0;
        font-weight: 500;
        font-size: 1.125rem;
      }
    }

    &::before {
      content: "";
      background-color: $theme-color;
      height: 4.5rem;
      width: 0.4rem;
      border-radius: 6.25rem;
      display: block;
      position: relative;
      left: -4rem;
    }
  }
}

.fx-drawer-right-content {
  
  background: $background-drawer;
  background-size: contain;

  .fx-congratulation-card {
    margin-top: 2rem;
    .fx-dialog-card-title {
      color: $text-color;
      font-size: $font-size-lg;
    }
    &-text1 {
      font-size: $font-size-h3 !important;
      color: $text-color;
    }
    &-text2 {
      width: 34rem;
      font-size: $font-size-h5 !important;
      text-align: center;
      color: $disabled-gray-color;
    }
  }

  .fx-faster-funding-card {
    margin-top: 5rem;
  }

}


.fx-accept-button {
  border-radius: 0.67rem;
  height: 2.5rem;
  padding: 1.5rem 1.334rem !important;
  letter-spacing: 0.04rem;
}

/* fx account activation left layout styles */
.fx-account-activation-left-layout {
  border-right: 1px solid $border-color;

  @media (max-width: 600px) {
    border-right: 0;
  }

  >div {
    height: 85%;

    >div {
      padding-left: 0;
    }
  }

  .fx-left-layout-header {
    border-radius: 0.625rem;
    padding: 0.625rem 2rem;
    background: $grey-background;
    overflow-wrap: anywhere;

    h2 {
      margin: 0;
      font-size: 1.7rem;
    }
  }

  .fx-left-layout-image {
    padding: 0;
    margin-left: -1.1rem;

    svg {
      width: 17.5rem;
      height: auto;

      @media (max-width: 600px) {
        width: 16rem;
      }
    }
  }

  .fx-left-layout-passport {
    svg {
      width: 11.2rem;
      height: 3rem;
      margin-top: 0.5rem;
      margin-left: 0.2rem;
    }

    h1:last-child {
      margin-top: -0.5rem;
    }

    h1 {
      font-size: 2.5rem;
      font-weight: 500;
    }
  }

  .fx-left-layout-gradient-header {
    @media (max-width: 800px) {
      width: 100%;
    }

    h1 {
      background: $main-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 2.5rem;
      font-weight: 700;
    }

    hr {
      background: $black-color;
      height: 0.2rem;
      width: 15%;
      margin: 0;
      margin-top: 0.5rem;
    }
  }

  .fx-left-layout-footer {
    p {
      font-size: $font-size-h3;
      margin: 0 0 0.5rem 0;
      font-weight: 600;
    }

  }
}

.fx-drawer-left-content {
  max-width: 31.34rem;

  @media (max-width: 600px) {
    max-width: 100%;
  }
}

.fx-drawer-left-content-grey-background{
  background: $grey-background;
}

/* fx account activation styles */
.fx-account-activation {

  .fx-drawer-right-content {
    >div {
      padding: 3rem 5rem 0 4.7rem;
      height: 85%;
      align-content: space-between;

      @media (max-width: 800px) {
        padding: 1rem 1rem 0 1rem;
      }

      >div:not(:last-child) {
        margin-bottom: 1.3rem;
      }
    }

    .fx-congratulation-card {
      padding: 0;
    }

    hr {
      border-color: $border-color;
    }
  }

  .fx-account-activation-fee-details-list {
    .fx-fee-details-list-header {
      font-family: $font;
      font-size: $font-size-h3;
      background: $main-gradient;
      border-radius: 0.625rem;
      color: $white !important;
      padding: 0.9rem 1.25rem;
      font-weight: 700;
    }

    .fx-fee-details-list {
      &-section:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      sup{
        font-size: $font-size-xs;
      }

      h5,
      h4 {
        font-family: $font;
        letter-spacing: 0.5px;
        padding: 0.5rem;
      }

      h6 {
        font-family: $font-inter;
        letter-spacing: 1px;
        font-size: 0.875rem;
        font-weight: 600;
        color: $label-color-secondary;
        padding: 0.5rem;

      }

      h4 {
        font-size: 1rem;
        font-weight: 400;
        color: $label-color-secondary;
      }

      h5 {
        font-size: 0.875rem;
        font-weight: 300;
        color: $label-color-secondary;
      }

      &-headerText {
        text-transform: uppercase;
        background-color: $dark-grey-background;
        border-radius: 0.3125rem;
      }

      &-column {
        padding: 0.5rem;

        &-icon {
          padding-right: 1rem;
          padding-left: 1rem;
        }
      }
    }

    .even-row {
      background-color: $grey-background;
      border-radius: 0.3125rem;
    }

    .fx-currency-view {
      font-family: $font !important;
      color: $paragraph-color;
      display: flex;
      align-items: center;

      .fx-currency-value {
        font-size: 1rem;
      }
    }
  }

  .fx-fee-agreement {
    background: $grey-background;
    padding: 0.9rem 1.25rem;
    border-radius: 0.625rem;
    sup{
      font-size: $font-size-xs;
    }
  }

  .fx-fee-activation {
    color: $text-color;

    .fx-fee-agreement-link {
      text-decoration: none;

      span {
        color: $theme-color;
        font-weight: 600;
      }
      .normal-font{
        font-weight: 400 !important;
      }
    }
  }
}




.fx-submit-agree {
  color: $text-color;

  >div {
    background: $grey-background;
    padding: 1.67rem;
    border-radius: 0.625rem;

    .fx-privacy-text label {
      align-items: flex-start;

      >span {
        font-size: 0.75rem;

        @media (max-width: 1280px) {
          font-size: 1rem;
        }

        &:first-child {
          padding-top: 0;
        }

        >span{
          .sup {
            vertical-align: super !important;
            font-size: $font-size-xs !important;
          }
        }
        
      }
    }
  }

}

.fx-submit-agree-color {
  color: $theme-color;

  &:hover {
    color: $theme-color;
  }
}

.fx-agreement-divider {
  hr {
    border-color: $border-color;
  }
}

/* fx apply debit card styles */
.fx-apply-debit-card {
  .border-error-input {
    .MuiOutlinedInput-notchedOutline {
      border-color: $red !important;
    }
  }

  &-main-content {
    padding: 3rem 9rem 0 5.8rem !important;
    height: 65%;
    align-content: space-between;

    >form {
      height: 100%;

      >div {
        height: 100%;
      }
    }

    h6 {
      background-color: $dark-grey-background;
      padding: 0.625rem 0.9rem;
      border-radius: 0.3rem;
      font-weight: 700;
      font-family: $font;
      font-size: 0.875rem;
    }
  }

  .fx-card-agreement-pdf {
    height: 18.75rem;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 1rem;

    >div {
      margin-top: -4rem;
    }
  }

  .fx-input-edit {
    .MuiInputLabel-shrink {
      padding: 0 0.5rem;
    }

    .MuiInputBase-formControl {
      height: 2.75rem;
      border-radius: 0.5rem;
    }

    &-text-layout {
      padding-left: 0.2rem;
    }
  }

  &-main-content-mobile {
    padding: 2rem 1rem !important;
    height: 80%;
    align-content: space-between;

    >form {
      height: 100%;

      >div {
        height: 100%;
      }
    }

    h6 {
      background-color: $dark-grey-background;
      padding: 0.625rem 0.9rem;
      border-radius: 0.3rem;
      font-weight: 700;
      font-family: $font;
      font-size: 0.875rem;
    }
  }

  &-main-content-individual {
    padding: 3rem 9rem 0 5.8rem !important;
    height: 80%;
    align-content: space-between;

    &-shipping-info{
      padding-left: 2.6rem;
      padding-right: 1.5rem;
      &-text{
        color: $disabled-gray-color;
      }
    }
    >form {
      height: 100%;

      >div {
        height: 100%;
      }
    }
    h6 {
      background-color: $dark-grey-background;
      padding: 0.625rem 0.9rem;
      border-radius: 0.3rem;
      font-weight: 500;
      font-family: $font;
      font-size: $font-size-h3;
    }
  }
}

// styles for treasure review agreement will refactor it accordingly
.fx-treasure-review-agreement-list {
  .fx-review-agreement-list-header {
    font-family: $font;
    font-size: $font-size-h3;
    background: $main-gradient;
    color: $white !important;
    border-radius: 0.625rem;
    padding: 0.9rem 1.25rem;
    font-weight: 700;
  }

  .fx-treasure-card-message {
    color: $theme-color;
    margin-left: 3rem;
  }

  .fx-review-agreement-list {
    &-section:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    h5, h4 {
      font-family: $font;
      color: $label-color-secondary;
      padding: 0.5rem;
    }

    h6 {
      font-family: $font;
      font-size: $font-size-h4;
      padding: 0.5rem;
      font-weight: 400;
      color: $text-color-secondary;
    }

    h4 {
      font-size: $font-size-h3;
      font-weight: 500;
    }

    &-headerText {
      background-color: $dark-grey-background;
      text-transform: uppercase;
      border-radius: 0.3125rem;
      font-weight: 500 !important;
      font-size: $font-size-h3 !important;
      color: $text-color !important;
    }

    &-column {
      padding: 0.5rem;

      &-icon {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }
  }

  .fx-review-agreement-section {
    padding: 1.5rem 1.5rem 0rem 1.5rem;
  }
}

// styles for treasure will refactor it accordingly
.fx-treasure-banner-text {
  font-size: $font-size-h3;
}

.fx-treasure-passport-icon {
  svg {
    margin-top: 0.5rem;
  }
}

.fx-treasure-card {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-wrapper {
    margin-top: 3.8rem;

    &-subText {
      margin-top: 1rem;
      color: $text-color-secondary;
      &-text {
        font-size: $font-size-md;
      }
    }

    &-welcome {
      font-size: $font-size-lg;
      color: $text-color;
      font-weight: 500;
      font-family: $font;
    }
  }

  &-manage {
    margin-top: 4.1rem;
    color: $text-color;

    h6 {
      font-size: $font-size-md;
      font-family: $font;
    }

    p {
      font-size: $font-size-h3;
    }

    &-text {
      display: flex;
      background-color: $grey-background;
      width: 53rem;
      border: 1.5px solid rgba($light-gray-color, 0.08);
      border-radius: 0.75rem;
      margin-top: 1rem;
      padding: 1.3rem 0;


      &-logo {
        padding-left: 1.8rem;

        rect {
          fill: $button-lite-background;
        }

        path {
          stroke: $theme-color;
        }       
      }

      &-no-path-strock{
        path{
          stroke: none;
          fill: $theme-color;
        }        
      }

    }

    &-signup {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.fx-treasure-details-info-card {
  .fx-info-label-value div:nth-child(1) {
    font-weight: 300;
  }
}

.fx-treasure-drawer-left-content{
  background: $grey-background;
}

.fx-treasure-left-layout-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow-wrap: anywhere;
  padding: 2.5rem;
  border-right: 1.5px solid $grey-background;
  background: $white;
  color: $text-color;

  &-grey {
    background: $grey-background;
    .fx-username {
      color: $text-color-secondary;
    }
    .fx-welcome {

    }
  }

  h2 {
    line-height: normal;
    font-size: $font-size-h1;
    font-weight: 700;
  }

  h3 {
    font-size: $font-size-h3;
    line-height: normal;
    font-weight: 600;
  }


  .fx-logo {
    margin-right: 0.3rem;

    svg {
      width: 3.5rem;
      height: 3.5rem;
      margin-top: 0.2rem;
    }
  }
}

.fx-drawer-left-logo{
  svg{
    width: 14.64192rem;
    height: 3.5rem;
  }
}

.fx-treasure-drawer-left-layout {
  color: $text-color;

  h1 {
    font-size: $font-size-lg;
    line-height: 3rem;
  }

  h1, h2, h3 {
    margin: 0;
  }

  .fx-treasure-left-layout-header-icon {
    background: $grey-background;
    align-content: space-between;
    padding: 1.5rem 2.5rem 0 2.5rem;
    padding-bottom: 1.8rem;
    height: 53rem;

    hr {
      border: 1px solid $theme-color;
      width: 3.3rem;
      margin: 0;
      margin-top: 1rem;
    }
  }

  .fx-treasure-earn-text {
    font-size: $font-size-h1;
    color: $theme-color;
    font-weight: 600;
  }

  .fx-treasure-banner {
    svg {
      width: 26.16rem;
      height: 17.5rem;
      padding-left: -1.6rem;
    }
  }

  .fx-treasure-banner-text {
    font-size: $font-size-h3;
    margin-top: 1rem;

  }
}

// For Treasure singup left menu
.fx-treasure-onboard-signup-left-menu {
  padding: 1.5rem 0 2.5rem 0;
  .fx-signup-stage {
    padding: 0 2.5rem 0 2.5rem;
    ul {
      margin: 0;
      padding: 1rem 0;
    }
  }

  .fx-signup-stage-title {
    color: $label-sec;
    font-size: $font-size-h2;
    line-height: 1.75rem;
    padding: 0 1rem;
  }

  .fx-signup-stage-description {
    color: $label-sec;
    font-size: $font-size-h4;
    line-height: 1.75rem;
    padding: 0 1rem;

  }

  .fx-investment-account-selected {
    background: $white;
    box-shadow: $menu-item-box-shadow;
    
  }
}

// for Treasure Business Info
.fx-business-info-card {
  .border-error-input {
    .MuiOutlinedInput-notchedOutline {
      border-color: $red !important;
    }
  }

  &-main-content {
    margin-top: 3rem;
    padding: 0 2rem;

    >form {
      >div {
        height: 100%;
      }
    }

    h6 {
      background-color: $dark-grey-background;
      font-family: $font;
      padding: 0.325rem 0.9rem;
      border-radius: 0.3rem;
      font-weight: 500;
      font-size: $font-size-h3;
      color: $text-color;
    }

    .fx-privacy-text {
      color: $text-color;
    }

    &-additional-info {
      padding-right: 1.3rem;
      margin: 0;
    }
  }

  .fx-input-edit {
    .MuiInputLabel-shrink {
      padding: 0 0.5rem;
    }

    .MuiInputBase-formControl {
      height: 2.75rem;
      border-radius: 0.5rem;
    }

    &-text-layout {
      padding-left: 0.2rem;
    }
  }
}

// For Treasure Auth Signatory Detail
.fx-review-auth-signatory-details {
  margin-top: 3rem;
  padding: 0 2rem;

  .fx-owner-name {
    font-size: $font-size-md;
    line-height: 2rem;
    font-weight: 600;
    color: $text-color;
  }

  .fx-owner-title,
  .fx-owner-email {
    font-size: $font-size-h3;
    line-height: 1.7rem;
    font-weight: 500;
    color: $text-color;
  }

  .fx-owner-signatory-card {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    border: 1px solid $chip-border-color;
    padding: 0.25rem 0.7rem;
    border-radius: 1rem;
    color: $text-color;
    p {
      font-size: $font-size-h3;
    }
    svg {
      margin-right: 0.5rem;
    }
  }

  .fx-owner-signatory-note {
    margin-bottom: 1rem;
    color: $text-color;
    font-size: $font-size-h3;
  }

  h6 {
    background-color: $dark-grey-background;
    font-family: $font;
    padding: 0.325rem 0.9rem;
    border-radius: 0.3rem;
    font-weight: 500;
    font-size: $font-size-h3;
    color: $text-color;
  }

  .fx-privacy-text {
    color: $text-color;
  }
}

.fx-passport-card {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-wrapper {
    margin-top: 3.8rem;

    &-subText {
      margin-top: 1rem;

      &-text {
        font-size: $font-size-h2;
      }
    }

    &-welcome {
      font-size: $font-size-lg;
    }
  }

  &-manage {
    margin-top: 4.1rem;

    &-text {
      display: flex;
      background-color: $disabled-color;
      width: 85.8rem;
      border: 1.5px solid rgba(130, 130, 130, 0.08);
      border-radius: 0.7rem;
      margin-top: 0;
      padding: 1.7rem;
      height: 9.1rem;
      margin-left: 1.7rem;

      &-logo {
        padding-left: 1.8rem;
      }

    }

    &-signup {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;
    }
  }


}

.fx-investment-account {
  padding: 3rem 3rem 0rem 3rem;

  .fx-investment-account-header-card {
    border-radius: 0.7rem;
    background: $disabled-color;

    h6 {
      font-weight: 500 !important;
      font-size: $font-size-h4 !important;
    }
  }

  .fx-investment-account-button-card {
    background: transparent;
    padding-top: 1rem !important;
    padding-left: 0rem !important;
    padding-bottom: 0rem !important;


    .fx-card-header {
      padding-bottom: 0 !important;
    }

    .icon {
      padding: 0rem 1rem;

      svg {
        .fx-theme-path-fill {
          fill: $theme-color !important;
        }

        .fx-theme-path-stroke {
          stroke: $theme-color !important;
        }

      }
    }
  }

  .fx-investment-account-offer-card {
    background: $disabled-color;
    padding: 1rem !important;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }

  h2, h3, h4, h5, h6 {
    font-family: $font !important;
    color: $label-sec !important;
  }

  .fx-investment-account-right-logo {
    svg {
      width: 13.6rem !important;
      height: 14rem !important;
      opacity: 0.2;
      transform: rotate(10deg);
    }
  }

  h5 {
    font-size: $font-size-h3 !important;
    font-weight: 500 !important;
  }

  h4 {
    font-size: $font-size-h3 !important;
    font-weight: 600 !important;
  }

  h2 {
    font-size: $font-size-lg !important;
    font-weight: 600 !important;
  }

  .fx-investment-account-earn {
    font-style: italic;
    color: $theme-color !important;
  }

  .fx-investment-account-video {
    padding: 0rem 1rem !important;
    background: transparent;

    video {
      border: 0.4rem solid $theme-color;
      border-radius: 0.5rem;
    }
  }
  .fx-investment-drawer-logo{
    svg{
      width: 12.205rem;
      height: 2.91667rem;
    }
  }
}

.fx-investment-account-agreement-content {
  border: 1px solid $border-color;
  border-radius: 0.5rem;
  height: 22rem;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 1rem 1.5rem 2rem 1.5rem;
}

.fx-investment-account-agreement-terms {
  margin: 0rem 1.5rem 2rem 1.5rem;
  &-text{
    background: $body-background;
    border-radius: 0.83333rem;
    padding: 0.85rem 0.5rem;
  }
}

.fx-investment-account-agreement {

  .fx-drawer-right-content {
    >div {
      align-content: space-between;
      padding: 3rem 5rem 0 4.7rem;
      height: 85%;

      @media (max-width: 800px) {
        padding: 1rem 1rem 0 1rem;
      }

      >div:not(:last-child) {
        margin-bottom: 1.3rem;
      }
    }

    .fx-congratulation-card {
      padding: 0;
    }

    hr {
      border-color: $border-color;
    }
  }

  .fx-investment-account-details-list {
    .fx-investment-account-details-list-header {
      font-family: $font;
      font-size: $font-size-h3;
      background: $main-gradient;
      color: $white !important;
      border-radius: 0.625rem;
      padding: 0.9rem 1.25rem;
      font-weight: 700;
    }

    .fx-investment-account-details-list {
      &-section:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      h5, h4 {
        font-family: $font;
        padding: 0.5rem;
      }

      h6 {
        font-family: $font-inter;
        font-size: $font-size-h5;
        font-weight: 600;
        color: $label-color-secondary;
        padding: 0.5rem;

      }

      h4 {
        font-size: $font-size-h4;
        font-weight: 400;
        color: $label-color-secondary;
      }

      h5 {
        font-size: $font-size-h5;
        font-weight: 300;
        color: $label-color-secondary;
      }

      &-headerText {
        text-transform: uppercase;
        background-color: $dark-grey-background;
        border-radius: 0.3125rem;
      }

      &-column {
        padding: 0.5rem;

        &-icon {
          padding-right: 1rem;
          padding-left: 1rem;
        }
      }
    }

    .even-row {
      background-color: $grey-background;
      border-radius: 0.3125rem;
    }

    .fx-currency-view {
      font-family: $font !important;
      color: $paragraph-color;
      display: flex;
      align-items: center;

      .fx-currency-value {
        font-size: $font-size-h4;
      }
    }
  }

  &-header{
    padding: 0.5rem 1.75rem;
    border-radius: 0.5rem;
    background: $body-background;
    margin: 2rem 1.5rem 0rem 1.5rem;
    p{
      font-weight: 500 !important;
      text-transform: uppercase;
    }
  }

  &-divider{
    padding: 0 3rem;
  }

}

// For charts square dot
.sqaure-dot {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 2.5px;

  &.medium {
    width: 1rem;
    height: 1rem;
  }

  &.theme-color {
    background-color: $theme-color;
  }

  &.manage-income-color {
    background-color: $manage-income-color;
  }

  &.manage-treasure-color {
    background-color: $manage-treasure-color;
  }

  &.unallocated-color {
    background-color: $primary-blue;
  }
}

// Investment Allocation Card
.fx-investment-allocation-card { 

  .fx-pi-chart {
    .fx-chart-label {
      display: flex;
      align-items: center;

      .fx-label-percentage-value {
        font-size: $font-size-h5;
        line-height: 1rem;
        font-weight: 700;
        letter-spacing: 0.4px;
      }

      .fx-label-text {
        font-size: $font-size-h5;
        line-height: 0.8rem;
        font-weight: 400;
      }
    }

    .managedMoneyMarket {
      fill: $theme-color;
    }
  
    .managedIncome {
      fill: $manage-income-color;
    }
  
    .managedTreasuries {
      fill: $manage-treasure-color;
    }

    .unallocated {
      fill: $primary-blue;
    }
  }

  .fx-investment-allocation-section {
    display: flex;
    padding: 1rem 0.5rem;
    color: $text-color;

    .fx-section-heading {
      font-size: $font-size-h3;
      line-height: 1.75rem;
      font-weight: 400;
    }

    .fx-section-sub-heading {
      font-size: $font-size-h5;
      line-height: 1.3rem;

      .fx-section-sub-heading-amount {
        margin-left: 0.8rem;
        font-weight: 500;
      }
    }

    .fx-section-percentage-value {
      font-size: $font-size-h5;
      line-height: 1.3rem;
      padding: 0rem 0.5rem;
      border-radius: 0.7rem;
      background-color: $grey-background;
    }
  }
  .fx-edit-btn-text{
    font-size: $font-size-h5;
    font-weight: 500;
    margin-left: 0.5rem;
  }
}

// Edit Card
.fx-investment-allocation-edit-header {
  background-color: $grey-background;
  padding: 0.2rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.fx-investment-allocation-total {
  display: block;
  font-size: $font-size-h3;
  line-height: 1.7rem;
}

.fx-investment-allocation-total-target {
  color: $disabled-gray-color;
  font-size: 0.9rem;
  line-height: 0.9rem;
}

.fx-application-banner {
  background-color: $theme-color;
  color: $white;
  padding: 0.83333rem 1.66667rem;

  .MuiAlert-message {
    font-size: $font-size-h2;
    font-weight: 500;
    padding: 0 !important;
  }
}

// Treasure Review agreement
.fx-review-agreement {
  padding: 0 2rem;
  margin-top: 3rem;

  .fx-review-agreement-check {
    padding: 0rem 1.5rem 1.5rem 1.5rem;
  }
}

.fx-treasure-dashbaord {
  .fx-card-header{
    padding: 0rem !important;  
  }

  .fx-title {
    font-size: $font-size-h2;   
    font-weight: 500;   
    font-family: $font !important;
    color: $text-color;
  }

  .fx-description {
    text-transform: uppercase;
    font-size: $font-size-h5;
    line-height: 1.25rem;
    font-weight: 500;
    color: $text-color;
    display: inline-flex;
    background-color: $grey-background;
    padding: 0.25rem 0.4rem;
    border-radius: 0.25rem;
  }

  .fx-investment-account-summary-card {
    .fx-theme-passport{
      padding: 1.72rem;
      margin-bottom: 0rem;
    }

    .fx-account-summary-card-header-text {
      font-size: $font-size-h4;
      font-weight: 400;
      font-family: $font !important;
      color: $text-color;
      margin-top: 0;
    }

    &-account-number {
      font-weight: 400 !important;
    }

    svg{
      circle{
        fill: #103046;
      }
    }

    .fx-investment-account-summary-card-theme-text{
      color: $theme-color;
      font-size: $font-size-h4;
      font-weight: 600;
    }

    .fx-account-summary-card-bal-card{
      padding: 1.29rem;
      margin-top: 1.29rem;
      height: auto;
      border-radius: 0.7rem;      
    }    

    .fx-investment-account-transfer-money{
      .fx-button-theme-disabled{
        svg {
          path{
            fill: rgba($theme-color, 0) !important;        
          }         
        }        
      }

      .fx-button-theme{
        svg {
          path{
            fill: $theme-color;          
          }         
        }
      }

      svg {
        path{         
          stroke: $white;
        }
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    .fx-download-icon {
      border: 1px solid $border-color !important;
    }
  } 

  .fx-investment-insturation-card{
    .fx-home-ledger-list-headerText{
      padding: 0.4rem 1rem;
    }
  }
}

.fx-investment-account-transfer-modal-body{
  .fx-header-title {
    background: none;
    color: $disabled-gray-color !important;
  }

  .MuiFormHelperText-root{
    display: flex !important;
    color: $text-color-secondary;
    font-size: 1rem !important;
    .fx-currency * {
      font-size: 1rem !important;
    }
  }

  .MuiOutlinedInput-input {
    .fx-select-prefix, .fx-selected-view  {
      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

// for passport logo in debit card request
.fx-passport-text-logo {
  svg{
    width: 12.205rem;
    height: 2.91667rem;
  }
}

// for pdf height in and border in debit card request
.fx-debit-card-agreement-pdf {
  border: 1px solid $border-color;
  border-radius: 0.5rem;
  height: 25rem;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 1rem;
}

.fx-dashboard-ledger-list{
  padding: 1.5rem 2.5rem 2.5rem 2.5rem;
}

/**
Merchand Pipe Onboarding Success Modal Styling
*/
.fx-merchant-congrats{

  &-icon{
    padding: 0.8rem !important;
  }

  &-header{
    &-title{
      margin-top: 1rem;
    }
  }

  &-body{

    &-description{
      > p {
      text-align: center;
      font-size: $font-size-h4 !important;
      font-weight: 500;
      color: $disabled-button-color;
      }
    }

  }

  &-footer{

    &-divider{
      padding: 0 0 0.75rem 0 !important;
      width: inherit !important;
        svg{
          width: 25rem !important;
        }
    }

    &-icon{
      margin-right: 0.3rem;
    }

    &-sub-desc{  
        font-size: $font-size-h3 !important;
        font-weight: 500 !important;
        color: $disabled-button-color;
    }
  }


}


//for Merchant Creation Drawer
.fx-merchant-creation-drawer-layout {
  align-content: baseline !important;

  background: $grey-background;
  border-right: 1px solid $border-color;

  @media (max-width: 600px) {
    border-right: 0;
  }

  >div {
    height: 85%;

    >div {
      padding-left: 0;
    }
  }

  .fx-left-layout-header {
    display: flex;
    border-radius: 0.625rem;
    padding: 0.625rem 0rem;
    overflow-wrap: anywhere;

    h2 {
      margin: 0;
      font-size: 1.7rem;
      font-weight: 700;
    }

    h3{
      color: $disabled-button-color !important;
    }

    svg {
      width: 4.5rem;
      height: auto;

      @media (max-width: 600px) {
        width: 4.5rem;
      }
    }

  }

  .fx-left-layout-image {
    padding: 0;
    margin-left: -1.1rem;

    svg {
      width: 27.33333rem;
      height: 21.25rem;

      path{
        color: $theme-color !important;
      }

      @media (max-width: 600px) {
        width: 27.33333rem;
      }
    }
  }

  .fx-left-layout-passport {

    h2 {
      font-size: $font-size-xl;
      font-weight: 700;
    }

    >div {
      margin-top: 0.6rem;
      svg {
        width: 13.16667rem;
        height: 3.14733rem;
      }
    }
  }

  .fx-left-layout-gradient-header {
    @media (max-width: 800px) {
      width: 100%;
    }

    h1 {
      background: $main-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .fx-left-layout-footer {
    p {
      font-size: $font-size-h2;
      margin: 0 0 0.5rem 0;
      font-weight: 400;
    }

    hr {
      border: 1px solid $theme-color;
      width: 3.3rem;
      margin: 0;
      margin-top: 1rem;
    }
  }
}

.fx-merchant-creation-drawer-layout-grid{
  align-content: baseline !important;
  }