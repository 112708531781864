/* table generic styles start */
.fx-table-generic {
  background: #fff !important;

  .MuiTableCell-stickyHeader {
    background-color: $grey-table-background;
  }

  td {
    padding: 1rem .5rem;
    font-family: $font-regular !important;
    overflow: hidden;

    p,
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }

    div {
      span {
        display: inline-flex;
      }
    }

    span.fx-status-mark {
      display: inline-flex;
    }
  }

  th {
    color: $label-sec;
    text-transform: uppercase;
    font-family: $font-regular;
    padding: 0.5rem;
  }

  td,
  td p {
    color: $label-sec;
    font-size: 1.15rem;
  }

  td p,td span {
    font-family: $font-regular;
    font-size: 1.15rem;
  }

  th,
  th p,
  th span {
    font-size: 1rem;
    line-height: 1rem;
    font-family: $font-regular;
  }
  td *,th *{
    letter-spacing: normal;
    font-weight: 500;
  }

  .MuiAvatar-root {
    color: $theme-color !important;
    background-color: $grey-table-background !important;
    height: 1.625rem !important;
    width: 1.625rem !important;
    font-family: $font !important;
    font-size: 0.625rem !important;
  }

  .MuiTableCell-alignCenter {
    text-align: -webkit-center !important;
  }

  .MuiSvgIcon-root {
    height: 0.75rem;
    width: 0.75rem;
  }

  .MuiTableCell-root {
    border-bottom: none;
  }

  .MuiTableCell-head {
    .MuiTableSortLabel-root {
      font-size: 0.625rem !important;
    }
  }

  td:first-child,
  th:first-child {
    padding-left: 0.75rem;
  }

  th:first-child {
    padding-left: 0.75rem;
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
  }

  th:last-child {
    padding-right: 0.75rem;
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
  }

  .fx-button-hover {
    visibility: hidden;
  }

  tr:hover .fx-button-hover {
    visibility: visible;
  }

  .MuiTableRow-hover:hover {
    background-color: #f7f9fb;
  }

  .MuiTableCell-paddingCheckbox {
    .fx-th-avatar {
      //avtar card
      position: absolute;
      top: 1rem;
    }
  }

  .fx-no-data {
    text-align: center;
    color: $label-sec;
    border-bottom: none;
  }
}


.fx-table-pagination {
  width: 100%;

  select,
  option {
    font-size: 1rem;
    font-family: $font;
    font-weight: 500;
  }

  select {
    margin-top: -4px;
  }

  td {
    border-bottom: none;
    padding-left: 0 !important;
  }

  td > * {
    vertical-align: middle;
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
  }

  .MuiInput-underline:after {
    border-bottom: none !important;
  }

  .MuiNativeSelect-icon {
    left: calc(88% - 10px) !important;
    top: 1rem !important;
  }

  .fx-pagination-select {
    width: auto;

    svg {
      z-index: 1;
      right: 0.75rem;
      transform: scale(0.75);
      path {
        // fill: $black-color !important;
      }
    }

    fieldset {
      border: none;
      background: $pagination-select;
      padding-left: 2px !important;
    }

    .MuiSelect-select {
      z-index: 1;
      padding-right: 2rem;
      font-size: 1.15rem;
    }
  }

  .MuiButton-root {
    margin-right: 0.625rem !important;
    min-width: 2.0625rem !important;
  }

  .pagination-text {
    font-size: 1.15rem;
    text-align: left;
    color: $label-color-secondary;
    padding-left: 1rem;
    font-weight: 500;
  }

  .page-count-label {
    font-family: $font-regular;
    font-size: 1.15rem;
    text-align: left;
    color: $label-color-secondary;
    font-weight: 500;
  }

  .fx-pagination-button {
    height: 2rem;
    border: none;
    background-color: $btn-fill-background;
    box-shadow: none !important;
    border-radius: 0.5rem;
    color: $theme-color !important;

    svg {
      width: 0.5rem;
      height: 0.5rem;
      path {
        fill: none !important;
        stroke: $theme-color !important;
      }

    }
  }

  fieldset {
    height: 3rem !important;
    top: 0 !important;
  }

}

/* table generic styles end */

/* fx verify  styles start */
.fx-verify {
  display: flex;
  align-items: center;
  padding-top: 0.75rem;

  .fx-button {
    background-color: $disabled-color;
    color: $label-color-secondary;
    border-radius: 0.75rem;
    text-transform: none;
    white-space: nowrap;
  }
}

/* fx verify  styles end */


/*fx-popover menu styles start */
.fx-popover {
  li,
  span,
  p {
    font-family: $font !important;
    font-size: 13px;
    color: $input-value-color;
  }

}

/*fx-popover menu styles end */


/* FxNoData styles start */
.no-data {
  text-align: center;
  height: auto;

  p {
    font-family: $font !important;
    font-size: 0.8125rem !important;
  }

  padding-top: 1.875rem;
  max-height: 3.375rem;
  min-height: 3.125rem;
}

/* FxNoData styles end */

/* FxModal styles start */
.modal-title-btn {
  float: right;
  margin-top: -1.5625rem !important;
}

.modal-minimizable {
  .icon {
    float: right;
    font-size: 2rem;
    color: rgb(5, 0, 0);
    cursor: pointer;
    margin-top: -0.625rem;
  }

  .minimize-link {
    background-image: url('../../assets/svg/minimize-icon.svg');
    background-repeat: no-repeat;
    width: 1.25rem;
    height: 1.25rem;
    float: right;
    margin: 0.9375rem -3.25rem 0 0;
    cursor: pointer;
  }

  .minimize-link:hover {
    border: 0.25rem solid rgba(0, 189, 245, 0);
    border-radius: 0.375rem;
    background-color: rgba(0, 18, 70, 0.4);
    margin: 0.5625rem -3.5rem 0 0;
  }

}

/* FxModal styles end */

/* pdf styles start */
.pdf-view {
  height: 100vh;
  overflow-y: auto;
}

.react-pdf__Document {
  .react-pdf__Page {
    .react-pdf__Page__canvas {
      width: calc(100% - 10px) !important;
      padding-right: 10px;
      height: 100% !important;
    }
  }
}

/* pdf styles end */

// toolbar select .fx-feather-icon-box
.select-toolbar,
.fx-select-balance,
.select-toolbar-list {
  margin-top: -0.125rem;
  min-width: 7.5rem;
  width: inherit;
}

.fx-select-balance {
  margin-top: 0.375rem;
  width: 6.375rem;

  [class*="-control"] {
    background: transparent;
  }

  [class*="-indicatorSeparator"] {
    display: none;
  }

  [class*="-singleValue"] {
    color: $balance-color;
  }

  [class*="-menu"] {
    color: $input-value-color;
  }

  [class*="-indicatorContainer"] :hover {
    color: hsl(0, 0%, 60%) !important;
  }

  [class*="-indicatorContainer"] {
    color: $balance-color !important;
  }

  [class*="-indicatorContainer"] svg {
    color: inherit !important;
  }

  [class*="-ValueContainer"] {
    padding-right: 0;
  }
}

.select-toolbar-list {
  margin-top: -0.75px;
  margin-right: 0.625rem;
}

.select-large-width {
  width: 10.625rem;
}


/* fx filter popover styles start */
.fx-filter-popover,
.select-toolbar,
.fx-select-balance,
.select-toolbar-list {
  [class*="control"] {
    min-height: 32px;
    /*do not add height as it will cause issue with multiple selects that extends more then one row*/
  }

  [class*="-indicatorsContainer"] {
    height: 30px;
  }

  [class*="-indicatorContainer"] {
    padding: 2px;
  }

  [class*="-menu"] {
    z-index: 999;
  }

  [class*="-ValueContainer"] {
    padding: 0 2px 0 8px;
  }
}

.fx-filter-popover {
  top: 0.625rem !important;
  .fx-button{
    font-size: 1.15rem !important;
  }
  .MuiPopover-paper {
    padding: 2.5rem;
    border-radius: 0.375rem;
    box-shadow: 0 0.25rem 1.5rem 0 $popover-box-shadow;
    overflow-y: scroll;
  }

  .fx-input-edit {
    width: 11.6rem;
    height: 30px;
  }

  .fx-dropdown-input-edit {
    width: 100%;
    height: 1.875rem;
  }

  .filter-popover-label {
    margin-top: 0.625rem !important;
    padding-right: 1rem;
    width: 9.3rem;
    height: 0.875rem;
    text-transform: uppercase;
    color: $label-color-secondary;
    font-family: $font-inter;

    @media (max-width: 600px) {
      padding-bottom: 1rem;
      width: auto;
      display: inline-table;
    }
  }

  .fx-input-edit,
  .filter-popover-label {
    font-family: $font-regular !important;
    font-size: 1.15rem !important;
    font-weight: 500 !important;
  }

  .filter-popover-label-second {
    text-align: center !important;
    width: 1rem;
  }

  .MuiOutlinedInput-input {
    padding: 8px 0px 8px 8px;
    border-color: $btn-def-background;
  }

  .check-edit,
  .radio-edit{
    .MuiTypography-root {
    font-weight: 500;
    font-size: 1.15rem;
  }}

  .filter-divider {
    padding: 0 0 1.625rem 0;
    margin-bottom: 1.625rem;
    background-image: linear-gradient(to left, $devider-border-color 20%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 6px 1.5px;
    background-repeat: repeat-x;
  }

  .fx-group-checkbox .MuiSvgIcon-root {
    color: $button-secondary-border-color !important;
  }

  .fx-group-checkbox .Mui-checked .MuiSvgIcon-root {
    color: $theme-color !important;
  }

  .popover-button-primary {
    height: 32px !important;
    border-radius: 0.375rem;
    background-color: $balance_background;
    font-family: $font !important;
    font-size: 14px;
    color: $white;

  }

  .popover-button-primary:hover {
    background-color: $theme-color;
  }

  .MuiSvgIcon-root {
    font-size: 1.2rem !important;
  }

  .fx-popover-control-grid {
    padding-top: 10px;
  }

  .fx-button {
    height: 2.5rem !important;
    border-radius: 0.5rem !important;
    border: none !important;

    svg {
      width: 1rem;
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $input-border-focus-color;
    border-width: 1px !important;
  }
}

.fx-menu-popover {
  .MuiPopover-paper {
    margin-left: 1.1rem;
    padding: 2.5rem;
    border-radius: 0.7rem;
    background: $label-color-secondary;
    overflow: visible;

    > div {
      margin: -0.5rem;
      > div {
        padding: 0.5rem;
      }
    }

  }

  &-expand {
    .MuiPopover-paper {
      @media (max-width: 600px) {
        width: 80%;
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }

  &-collapse {
    .MuiPopover-paper {
      &:after{
        content: " ";
        position: absolute;
        right: calc(100% - 2px);
        top: 50%;
        transform: translateY(-50%);
        border-top: 1.2rem solid transparent;
        border-right: 1.2rem solid $label-color-secondary;
        border-left: none;
        border-bottom: 1.2rem solid transparent;
      }
    }
  }
}

.filter-date-field {
  .MuiOutlinedInput-root {
    height: 2.5rem !important;
  }
}

/* fx filter popover styles end */


/* fx modal styles start */
.fx-modal {
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: $border-card-radius;
  color: $text-color;
  margin-top: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .fx-modal-header {
    background-color: $modal-background;
    padding: 10px 20px !important;
    border-bottom: 1px solid $divider-color;

    p {
      font-size: 20px;
      font-weight: bold;
      font-family: $font-bold;
      color: $input-value-color;
    }
  }

  .fx-info-card {
    margin-top: 2px;
  }

  .fx-modal-footer {
    width: 100%;
    padding: 1rem 0 0 0 !important
  }

  .modal-content .modal-body {
    box-shadow: none;
    background-color: $modal-background;
    border-radius: 0.375rem;
    color: $text-color;

    .fx-card,
    .fx-card-body,
    .fx-card-footer {
      box-sizing: border-box;
    }

    .fx-input-edit {
      [class*="-control"] {
        border-color: $border-color;
        border-width: 2px;
      }
    }

    .fx-modal-form {
      color: $text-color;
      > div {
        padding: 10px;
      }

      .MuiInputLabel-formControl {
        font-family: $font;
        font-size: 12px;
      }

      .form-label {
        font-size: 13px;
        font-family: $font-regular;
        text-align: left;
        color: $input-animated-label-color;
      }

      .MuiOutlinedInput-input {
        font-family: $font;
        font-size: 13px;
        padding: 10px 13px !important;
      }

      .border-error-input {
        .MuiOutlinedInput-notchedOutline {
          border-color: $red !important;
          border-width:1px !important;
        }

        [class*="-control"] {
          border-color: $red;
        }
      }
    }

    h3 {
      font-family: $font;
      font-size: $font-size-h3;
      font-weight: 500;
      text-align: center;
    }

    .tex-left {
      text-align: left !important;
    }

    h5 {
      font-family: $font-inter;
      font-size: $font-size-h5;
      font-weight: 400;
      color: $disabled-gray-color;
    }

    .fx-info-text {
      color: $text-color-secondary;
    }

  }

  .fx-modal-delete-icon {
    width: 72px;
    height: 72px;
    border-radius: 36px;
    border: 1px solid $border-color;

    svg {
      height: 36px;
      width: 36px;
    }
  }

  //close icon
  .fx-modal-close-icon {
    text-align: right !important;

    .MuiIconButton-root {
      padding: 1px !important;
    }
  }

  .fx-modal-title-h1 {
    color: $text-color;
    font-size:  $font-size-h1 !important;
  }

  .fx-modal-title-h4 {
    font-size: $font-size-h4 !important;
    color: $grey-text-color;
  }

  .fx-modal-title-md {
    color: $text-color;
    font-size:  $font-size-md !important;
  }

  .fx-modal-title-h3 {
    font-size: $font-size-h3 !important;
    color: $text-color;
  }

  .fx-modal-generic-title {
    color: $text-color;
    font-size: $font-size-h3;
    font-weight: 600;
    font-family: $font;
  }

}

.fx-modal-large {
  .modal-body {
    width: 80vw !important;

    @media (max-width: 600px) {
      width: 95vw !important;
    }
  }
}

.fx-modal-submedium {
  .modal-body {
    width: 50vw !important;
    @media (max-width: 600px) {
      width: 95vw !important;
    }
  }
}

.fx-modal-medium {
  .modal-body {
    width: 60vw !important;
    @media (max-width: 600px) {
      width: 95vw !important;
    }
  }
}

.fx-modal-small {
  .modal-body {
    width: 25vw !important;

    @media (max-width: 600px) {
      width: 95vw !important;
    }

  }

  .fx-card-header {
    border-bottom: none !important;
    height: 30px !important;
  }

  .fx-info-card,
  .fx-footer {
    padding: 0 16px !important;
  }

  .fx-delete-info {

    .fx-delete-title,
    .fx-delete-description {
      padding: 4px 10px !important;
      text-align: center;
    }
  }
}

.fx-modal-small2medium {
  .modal-body {
    width: 30vw !important;

    @media (max-width: 600px) {
      width: 95vw !important;
    }

  }

  .fx-card-header {
    border-bottom: none !important;
  }

  .fx-info-card {
  }

  .fx-footer {
    padding: 0 !important;
  }

  .fx-delete-info {

    .fx-delete-title,
    .fx-delete-description {
      padding: 4px 10px !important;
    }
  }

  .MuiDivider-root {
    background-image: linear-gradient(to left, $devider-border-color 20%, rgba(255, 255, 255, 0) 0%);
    background-position: left;
    background-size: 6px 1.5px;
    background-repeat: repeat-x;
    left: 0;
    width: 100%;
    background-color: transparent;
  }

  .fx-group-checkbox .Mui-checked .MuiSvgIcon-root {
    color: $theme-color !important;
  }

}

.fx-modal-medium2large {
  .modal-body {
    width: 24vw !important;

    @media (max-width: 600px) {
      width: 95vw !important;
    }

  }

  .fx-card-header {
    border-bottom: none !important;
  }

  .fx-info-card {
  }

  .fx-footer {
    padding: 0 !important;
  }

  .fx-delete-info {

    .fx-delete-title,
    .fx-delete-description {
      padding: 0.25rem 0.625rem !important;
    }
  }

  .MuiDivider-root {
    background-image: linear-gradient(to left, $devider-border-color 20%, $black-color 0%);
    border-color: $border-color !important;
    background-position: left;
    background-repeat: repeat-x;
    left: 0;
    width: 100%;
    background-color: transparent;
  }

  .fx-group-checkbox .Mui-checked .MuiSvgIcon-root {
    color: $theme-color !important;
  }

}

/* fx modal styles end */

/* fx primary filter styles start */
.fx-primary-filter {
  border-radius: 0.375rem;
  border: 1.5px $border-color solid;

  .MuiTextField-root {
    width: 10rem;
  }

  .MuiOutlinedInput-adornedStart {
    padding-left: 8px;
  }

  .MuiOutlinedInput-input {
    padding-left: 2px !important;
  }

  .fx-input-edit {
    .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }

    .MuiInputBase-inputAdornedStart {
      margin-top: -2px !important;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;

    }

    .MuiInputAdornment-positionStart {
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .fx-primary-select {
    overflow: hidden;
    background: $body-background;
    padding: 0 0.75rem 0 0.25rem;

    .MuiSelect-root,
    .MuiMenuItem-root {
      font-family: $font;
      font-weight: 400;
      font-size: 0.875rem;
    }

    .MuiSelect-outlined {
      padding-right: 1rem !important;
    }

    .MuiSelect-select {
      background: transparent;
      min-width: 0.75rem;
    }

    .MuiSelect-iconOutlined {
      right: -0.25rem;
    }

  }

  .MuiIconButton-root {
    border-radius: 30px !important;
    padding: 0.4rem !important;
  }

  .MuiInputBase-formControl {
    height: 2.35rem !important;
  }
}

/* fx primary filter styles start */


/* fx dotted menu styles start */
.fx-menu-dotted {
  ul {
    padding: 0.75rem 1.25rem;

    .MuiListItem-button:hover {
      background: $white;
    }

    li {
      font-family: $font;
      font-size: 0.935rem !important;
      font-weight: 500;
      color: $dark-gray-color;
      text-transform: uppercase;
      padding: 0.75rem 0 0.75rem 0;
      display: flex;
      align-items: center;

      div {
        display: flex;
        align-items: center;
      }

      .document-list{
        margin-left: -1rem !important;
        margin-top: -0.33rem !important;
        text-transform: none !important;
      }

      svg:not(.permission-svg) {
        width: 1.25rem;
        height: 1.25rem;
      }

      .MuiListItemIcon-root {
        min-width: 2rem;
      }
    }

    > li:not(:last-child):after {
      // border-bottom: 1px dotted;
      background-image: linear-gradient(to left, $summary-border-color 100%, rgba(255, 255, 255, 0) 0%);
      position: absolute;
      top: 2.5rem;
      display: block;
      content: '';
      height: 0.5625rem;
      width: 100%;
      background-position: left;
      background-size: 0.375rem 0.0625rem;
      background-repeat: repeat-x;
      padding: 2px 0;
      @media (max-width: 600px) {
        top: 3.8rem;
      }
    }
  }
}

.fx-menu-no-shadow {
  .MuiPaper-rounded {
    box-shadow: 0 2px 0.5rem 0 $border-shadow-color;
  }
}

.fx-menu-linked-document {
  .MuiPaper-rounded {
    box-shadow: 0 2px 0.5rem 0 $border-color !important;
    border-radius: 0.375rem !important;
  }
}

.fx-list-action-menu {
  .MuiPaper-rounded {
    box-shadow: 0 2px 0.5rem 0 $border-color !important;
  }
}

/* fx dotted menu styles start */

/* success message */
.fx-message-box {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: rgba($success-background, 0.05);
  border-radius: 0.5rem;

  h5 {
    font-weight: 500;
    font-size: 1.125rem;
    color: $label-color;
    font-family: $font;
  }
}

.message {
  margin-left: 2.375rem;
  margin-top: 0.125rem;
  padding-bottom: 0.75rem;
}

.fx-no-data {
  text-align: center;
  color: $label-color-secondary;
  border-bottom: none;
  font-family: $font;
  font-weight: 500;
}

/* fx check availability */
.fx-button-available {
  background-color: $border-buttom-view-all;
  border-radius: 0.375rem;
  margin: 0.25rem;

  .MuiTypography-root {
    margin: -0.9375rem 0;
    padding: 0 0.3125rem;
  }

  .fx-link {
    color: $label-color-secondary;
    cursor: pointer;
    padding: 0.01rem 0.5rem !important;
  }
}

/* common styles for buttons with right arrow */
.fx-arrow-button {
  font-family: $font;
  border-radius: 0.416rem;
  height: 3.5rem !important;
  display: flex;
  justify-content: space-around;
  text-transform: none;

  .fx-arrow-icon {
    width: auto;
    height: 0.9rem;
  }
}
.fx-back-arrow-icon path{
  fill: $black-color;
}

.fx-right-arrow-icon {
 transform: rotate(180deg);
  path{
    fill: $black-color;
  }
}



/* fx back to mxm button */
.fx-mxm-back-button {
  padding-left: 0 !important;
  & {
      background: $grey-background;
      border: solid 1px $border-color;
      width: 15.5rem;
      margin-right: 0.8rem;

    p {
      display: flex;
      flex-direction: column;
      text-align: start;
      color: $label-color-secondary;
      span:first-of-type{
        font-size: $font-size-sm;
        font-weight: 500;
        line-height: normal;
      }
      span:last-of-type {
        font-size: $font-size-xs;
        font-weight: 400;
        text-align: start;
        overflow: hidden;
        line-height: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

  }

  .fx-mxm-logo {
    padding-left: 0.25rem;
    width: 1.7rem;
    height: 1.7rem;
  }

  @media (max-width: 600px) {
    background: none;
    border:none;
  
    div {
      justify-content: flex-start;
    }
  
    div:hover {
      p { 
        span {
        color: $theme-color !important;
        }
      }
    }
  
    p { 
      margin-left: 1rem;
      span {
        color: white;
        font-size: 0.875rem !important;
      }
      }
    }
}

/* fx apply for debit card button */
.fx-apply-for-debit-card-button {
  & {
    background: $button-dark-blue;
    border: none;
    width: 100%;
    justify-content: space-between;
    height: 3rem !important;

    >div {
      display: flex;
      align-items: center;
      p {
        display: flex;
        flex-direction: column;
        color: $white;
        align-items: flex-start;
        span {
          font-size: 0.85rem;
          line-height: 0.75rem;
          letter-spacing: 0.5px;
        }
        span:first-of-type{
          font-weight: 500;
          margin-bottom: 0.25rem;
        }
        span:last-of-type {
          font-weight: 300;
        }
      }

      .fx-visa-logo {
        width: 3rem;
        height: auto;
        margin-right: 0.75rem;
      }

    }

  }
  &.disabled {
    background: $disabled-button-color;
    cursor: auto;
  }
  .fx-arrow-icon {
    path {
      stroke: $white;
    }
  }
}

/* fx congratulation card */
.fx-faster-funding-card {
  .fx-dialog-card-title {
    margin: 0;
  }

  .fx-dialog-card-icon {
    padding: 1rem;

    svg {
        path:nth-of-type(41),
        path:nth-of-type(73),
        path:nth-of-type(74),
        path:nth-of-type(75),
        path:nth-of-type(76),
        path:nth-of-type(82),
        path:nth-of-type(93),
        path:nth-of-type(103),
        path:nth-of-type(121),
        path:nth-of-type(151),
        path:nth-of-type(155){
          fill: $theme-color-svg-icon !important;
        }
        path:nth-of-type(12),
        path:nth-of-type(69),
        path:nth-of-type(71),
        path:nth-of-type(79),
        path:nth-of-type(80),
        path:nth-of-type(84),
        path:nth-of-type(89),
        path:nth-of-type(91),
        path:nth-of-type(95),
        path:nth-of-type(101),
        path:nth-of-type(104),
        path:nth-of-type(106),
        path:nth-of-type(109),
        path:nth-of-type(112),
        path:nth-of-type(114),
        path:nth-of-type(115),
        path:nth-of-type(117),
        path:nth-of-type(119){
          fill: $theme-color-lite-svg-icon !important;
        }
      
      max-width: 100%;
      height: auto;
    }
  }

  .fx-dialog-card-message {

    > div:first-child {
      align-items: flex-start;
    }

    p {
      text-align: center;
    }
  }

  &-checkbox {
    padding: 0;
  }
}


/* fx balance card */
.fx-balance-card{
  display: flex;
  flex-direction: column;
  border: 1px solid $border-color;
  border-radius: 0  0.375rem  0.375rem 0;
  border-left: none;
  box-shadow: inset 4px 0 0 $theme-color;
  background: $white;
  padding: 1rem 1.5rem;
  width: 31.5%;
  color: $text-color;
  .fx-currency * {
    font-weight: 500;
  }
  .amount-block {
    svg {
      width: 0.4rem;
      height: auto;
    }
  }
  &:hover {
    background: $hover-color;
    cursor: pointer;
  }
  > p:first-child {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    svg {
      margin-left: 0.3rem;
      width: 0.8rem;
      height: auto;
      path {
        fill: $theme-color;
      }
    }
  }
}

.fx-view-balance-detail {
  .fx-theme-passport {
    .fx-card-header {
      .fx-header-title {
        font-family: $font;
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-weight: 700;
        letter-spacing: 0.25px;
      }

      .fx-header-secondary-text {
        color: $text-color-secondary;
      }
    }

    .fx-card-body {
      .fx-settle-icon, .fx-quick-settle-icon, .fx-currency {
        margin-right: 0.5rem;
      }

      .fx-external-transfer-info {
        font-family: $font;
        font-size: $font-size-h6;
        text-align: right;
        margin-top: 0.3rem;
        color: $label-color-secondary;
      }

      .fx-amount-info {
        color: $label-color-secondary;
      }

      .fx-light-theme-progress {
        & > span {
          background: rgba($theme-color, 0.5);
        }
      }
    }
  }
}

.fx-text-2-line {
  white-space: inherit;
  -webkit-line-clamp: 2;
}

.fx-invest-now-card {
  .fx-theme-passport {
    .fx-card-header {
      .fx-header-title {
        font-family: $font;
        font-size: $font-size-h2;
        line-height: 1.6rem;
        font-weight: 500;
      }

      .fx-header-secondary-text {
        color: $text-color-secondary;
      }
      h6{
        margin-top: 0.75rem;
      }
    }

    .fx-card-body {

      .fx-investment-amount{
        margin-top: 1rem;
        .MuiFormHelperText-root{
          margin-left: 0px;
          font-style: italic;
          font-size: $font-size-h5;
          display: flex;
          .fx-currency *, .fx-currency-value{
            font-size: $font-size-h5;
          }
        }
      }

      .fx-external-transfer-info {
        font-family: $font;
        font-size: $font-size-h6;
        text-align: right;
        margin-top: 0.3rem;
        color: $label-color-secondary;
      }

      .fx-amount-info {
        color: $label-color-secondary;
      }

      .fx-light-theme-progress {
        & > span {
          background: rgba($theme-color, 0.5);
        }
      }
      .fx-allowed-investment-section{
        padding: 0.33rem 0.83rem !important;
        h3{
          text-align: start;  
        }
      }
    }
  }
}

.fx-percentage-textbox{
  display: flex;
  max-width: 8.3rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
}

.fx-success-modal {
  .fx-currency *{
    font-weight: 600;
  }
  .fx-card-header {
    height: unset !important;
    padding: 0 !important;
  }
  .fx-modal-header-content {
    margin-top: 1rem;
  }
  .modal-body {
    .fx-card {
      padding: 2rem;
    }

    .fx-modal-subtitle {
      font-size: $font-size-h3 !important;
      font-weight: 600;
      color: $text-color;
    }

    .fx-modal-subheader {
      font-size: $font-size-h2 !important;
      color: $text-color;
      margin-bottom: 1rem;
    }

    .fx-modal-title {
      color: $text-color;
      font-weight: 600;
      font-size:  $font-size-h1 !important;
      margin-top: -2rem;
    }

    .fx-modal-description {
      font-family: $font;
      font-size: $font-size-h3;
      color: $text-color-secondary;
      text-align: center;
      margin-bottom: 2rem;
      margin-top: 1rem;
    }
  }
}

.fx-redeem-now-card {
  .fx-info-text {
    color:$text-color-secondary;
  }

  h2 {
    font-size: $font-size-h2;
    font-weight: 400;
    line-height: 1.6rem;
  }

  .MuiFormHelperText-root{
    display: flex !important;
    color: $text-color-secondary;
    font-size: $font-size-h4  !important;
    font-style: italic;
    .fx-currency * {
      font-size: $font-size-h4  !important;
    }
  }
}

.fx-sidenav {

  &-collapse {
    width: 5.41667rem;
    padding: 1.66667rem 0;
    position: absolute;
    bottom: 0;

    .fx-link:hover {
      * {
        color: $theme-color;
      }

      svg {
        path {
          stroke: $theme-color;
        }
      }
    }

    &-close-popover {
      height: 4.16667rem;
      padding: 0.66667rem 0rem;
      gap: 1.66667rem;

      &-welcome {
        width: 3.5rem;
        height: 3.5rem;
        padding: 0.66667rem;
        gap: 0.83333rem;
        border-radius: 0.41667rem;
        background: $welcome-menu-background;
        font-size: 1.16667rem;
      }
      &-switch-customer {
        width: 3.5rem;
        height: 3.5rem;
        padding: 0.66667rem;
        gap: 0.83333rem;
        border-radius: 0.41667rem;
        font-size: 1.16667rem;
      }
      &-help {
        width: 3.33333rem;
        height: 3.33333rem;
        padding: 0.66667rem;
        gap: 0.83333rem;
        border-radius: 0.33333rem;
        margin-left: 5rem;

        svg {
          width: 1.625rem;
          height: 1.625rem;
          flex-shrink: 0;
        }
      }
    } 
    &-welcome-menu,&-portal-switch-menu{
      margin-left: 1rem;

      .fx-welcome-menu-username {
        color: $white;
      }
 
     .MuiPopover-paper {
       overflow: visible;
       width: 14.58333rem;
       flex-direction: column;
       background: $welcome-menu-background;
 
       &:after{
         content: " ";
         position: absolute;
         right: calc(100% - 0.125rem);
         bottom: 0%;
         transform: translateY(-50%);
         border-top: 0.7rem solid transparent;
         border-right: 0.7rem solid $label-color-secondary;
         border-left: none;
         border-bottom: 0.7rem solid transparent;
       }
 
       li {
         width: 100%;
         height: 3.33333rem;
         padding: 0rem 1.25rem;
         gap: 0.83333rem;
 
         span {
           color: $white;
           font-family: $font;
           font-size: $font-size-h4;
           font-style: normal;
           font-weight: 500;
           line-height: 1.5rem;
         }
       }
 
       li:not(:last-child){
         span {
           opacity: 0.8;
         }
       }
 
       li:hover {
         background: $welcome-menu-background-hover !important;      
       }
 
       li:last-child {
        height: 3.73333rem;

         &.fx-sidenav-smalltext-user{
           padding-bottom: 0.5rem;
         }
         span {
           width: 9.5rem;
           color: $border-color;
           font-size:  $font-size-h5;
           line-height: 1.3125rem; 
         }
 
         div:first-of-type>span {
           opacity: 0.6;

         }
       }
     }
   }
   &-portal-switch-menu{
    li:last-child {
       span {
        width: auto !important;
        opacity: 0.8 !important;
        font-size:  $font-size-h4 !important;
        color: $white !important;
      }
    }
   }
       
  }



  &-bottommenu {
    overflow: hidden;
    display: flex;
    width: 20.5rem;
    padding: 1.25rem 0rem 1.66667rem 0rem;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1 0 0;
    bottom: 0;
    position: absolute;

    .fx-link {
      text-decoration: none;
      color: $white;
    }

    .fx-link:hover {
      * {
        color: $theme-color;
      }

      svg{
        path {
          stroke: $theme-color;
        }
      }
    }

    &-item {
      width: 20.5rem;
      height: 4.16667rem;
      padding: 0.5rem 1rem;
      border-top: 0.063rem solid rgba($white, 0.1);

      .MuiAvatar-root {
        background-color: rgba($white, 0.05);
        border-radius: 0.268rem;
      }
    }

    &-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;

      &-left {
        width: 3.33333rem;
        height: 3.33333rem;
        gap: 0.83333rem;
      }

      &-right {
        width: 3.33333rem;
        height: 3.33333rem;
        gap: 0.83333rem;
          svg {
            path {
              stroke: $white !important;
              color: $white !important;
              fill: $white !important;
            }
        }
      }
    }

  }

  &-expanded {

    &-theme-sel {
        a > div {
          border-radius: 0 0  0.41667rem  0.41667rem !important;
          background-color: $welcome-menu-background !important;
        }
    }
  
    &-theme-not-sel {
      background: $label-color-secondary;
    }

    &-avatar-theme {
      background: none !important;
    }
  
    &-avatar-not-theme {
      background: $left-menu-head-background;
    }

    &-popup {

      .MuiPopover-paper {
        width: 18.4333rem;
        display: flex;
        padding: 0 0 0.9rem 0rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.25rem;
        align-self: stretch;
        opacity: 0.8;
        background-color: $welcome-menu-background;
        margin-left: -0.3rem;
        margin-top: 0.99rem;

        li:hover {
          background: $welcome-menu-background-hover !important;      
        }

        li:last-child {
          border-radius: 0 !important;
          border-bottom: 0.063rem solid rgba($white, 0.1);
        }

        li {
          width: 100%;
          display: flex;
          height: 3.33333rem;
          padding: 0rem 1rem 0rem 3.33333rem;
          align-items: center;
          gap: 0.83333rem;
          align-self: stretch;
          span {
            color: $white;
            line-height: 1.5rem;
          }
        }
      }
    }
  }

  &-account-sel {
    width: 15.625rem;
    padding: 0.3rem 1.25rem !important;
    gap: 0.625rem;

    &-sel {
      display: flex;
      width: 13.125rem;
      align-items: center;
      padding: 0.625rem 0.2375rem;
      gap: 0.625rem;

      &-lefticon{
        width: 1.125rem;
        height: 1.125rem;
        flex-shrink: 0;
      }
      &-righticon{
        width: 1.125rem;
        height: 1.125rem;
        padding: 0rem 0.0625rem;
        gap: 0.625rem;
        flex-shrink: 0;

        svg {
          width: 1.125rem !important;
          height: 1.125rem  !important;
          circle,
          path {
            fill: $white;
            stroke: $white;
          }
      }
      }
      &-middle-nick{
        height: 1.3125rem;
        flex: 1 0 0;
        color: $white;
      }
    }
  }

  &-account-expand {

    .MuiPopover-paper {
      width: 15.625rem;
      background: $welcome-menu-background;
      color: $white;
      margin-top: 0.1rem;

      svg {
        width: 1.125rem !important;
        height: 1.125rem  !important;
      }

      > div:hover {
        background: $welcome-menu-background-hover;
      }
    }

    &-sel{
      width: 11.375rem;
      padding: 0.9375rem 0.625rem;
      gap: 0.625rem;
    }
  }

  &-account-collapse {
    .MuiPopover-paper {
      overflow: visible;
      &:after{
        content: " ";
        position: absolute;
        right: calc(100% - 0.125rem);
        top: 15%;
        transform: translateY(-50%);
        border-top: 0.7rem solid transparent;
        border-right: 0.7rem solid $label-color-secondary;
        border-left: none;
        border-bottom: 0.7rem solid transparent;
      }

      width: 14.58rem;
      background: $welcome-menu-background;
      color: $white;
      margin-top: -0.7rem;
      margin-left: 0.9rem;

      > div {
        padding: 0.83333rem 1.25rem;
      }

      > div:hover {
        background: $welcome-menu-background-hover;
      }
    }

    &-sel-acc {
      padding: 0.625rem 0.9375rem;
      gap: 0.625rem;
      align-self: stretch;

      > div {
        width: 100%;
      }
    }
  }

  &-fx-welcome-popup {
    width: 100%;
    gap: 0.625rem;
  }

  &-tooltip {
    .MuiTooltip-tooltip {
      background-color: $white !important;
      color: $text-color !important;
      border: 0.05rem solid $black-color !important;
      border-radius: 0 !important;
      font-family: $font !important;
    }
  }
}

//For the bottom padding of the Schedule Actions buttons in mobile views
.fx-schedule-action-buttons{
  @media (max-width: 600px) {
    > div {
      padding-bottom: 1rem;
    }
  }
}
.fx-label-with-copy {
  display: flex;
  align-items: center;
  border: 1px solid $border-color;
  border-radius: 3px;

  button:hover {
    background: none;
  }

  div {
    flex: 1;
  }
  &-label{
    cursor:pointer;
    margin-left:1rem;
    display: flex;
    align-items: center;
  }
  &-passwod{
    flex: 1;
    color: $text-color;
    margin-left: 1.5rem;
    font-size: $font-size-h3 !important;
    text-align: center;
  }

  &-copied {
     color: $light-green-color;
     margin-top: 1rem;

    svg {
        width: 1rem;
        height: 1rem;
        margin-right: 0.4rem;
    }
  }

  .fx-password-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .fx-copy-clipboard {
    svg {
        width: auto;
        height: 1.25rem;
    }
  }
}



.fx-dialog-modal {
  color: $text-color;

  .modal-body {
    padding: 1.75rem;
  }

  &-icon {
    margin-top: 1.5rem;
  }

  &-title {
    font-size: $font-size-md;
    font-weight: 600;
    width: 60%;
  }

  &-info {
    font-size: $font-size-h4;
    color: $text-color-secondary;
    margin-bottom: 1.5rem;
  }
}

.fx-digital-first-card-modal{
  padding: 1rem !important;
  &-text{
    color: $text-color !important;
  }
}

.fx-enable-physical-card-modal{
  .fx-card-header{
    border-bottom: none !important
  }
  &-title{
    font-size: $font-size-h1 !important;
    font-weight: 600 !important;
  }
}

.fx-replace-debit-card-modal,
.fx-reissue-debit-card-modal,
.fx-unfreeze-debit-card-modal,
.fx-freeze-debit-card-modal{
  padding: 1rem !important;
  .fx-card-header{
    border-bottom: none !important
  }
  &-title{
    font-size: $font-size-h1 !important;
    font-weight: 600 !important;
  }
  &-subtext{
    text-align:center;
    padding-bottom: 2.67rem;
    padding-left: 0.83rem;
    padding-right: 0.83rem;
  }
  &-note{
    text-align:center;
    padding: 1.75rem 0.83rem;
  }
  &-divider{
    padding: 0 0 0.75rem 0 !important;
  }
}

.fx-manage-card-usage-modal{
  padding: 1rem !important;
  .fx-card-header{
    border-bottom: none !important
  }
  &-title{
    font-size: $font-size-h1 !important;
    font-weight: 600 !important;
  }
  &-subtext{
    text-align:center;
    padding-bottom: 2.67rem;
    padding-left: 0.83rem;
    padding-right: 0.83rem;
  }
  &-note{
    font-size: $font-size-h4 !important;
    text-align:left;
    padding: 1.75rem 0.83rem;
    color: $text-color-secondary !important;
  }
  &-divider{
    padding: 0 0 0.75rem 0 !important;
  }
}

.settings-nav{
  .fx-sidenav-collapse-portal-switch-menu{
      left: 4.4rem !important;
    }
}

.fx-manage-menu-popup {
  .MuiListItemIcon-root {
    min-width: 0;
    padding-right: 1rem
  }

  svg {
    width: 1.125rem;
    height: 1.125rem;
  }

  li.fx-manage-menu-item {
    border-bottom: none;
    gap: 0;
  }

  &.fx-sidenav-expanded-popup {
    .MuiPopover-paper {
      margin-top: 0;
      padding-bottom: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      width: 20.5rem;
      left:0 !important;
      margin-left: 0 !important;
    }

    li.fx-manage-menu-item {
      padding: 0 1rem;
      &:first-child {
        border-top: 1px solid rgba($white, 0.1);
      }

      &:last-child {
        border: none;
      }

    }
  }
}

.fx-manage-menu {

  &-arrow-icon {
    position: absolute;
    right: 1rem;
  }
  &-link {
    &:hover .fx-manage-menu-arrow-icon {
      right: 0;
    }
  }
}
//partner portal split funding start

.fx-partner-split-funding{
  &-label-checkbox {
   label {
      margin-right: 0.3rem !important;
   }
  }

  &-tooltip{
    margin-top: 0.95rem;
  }
}

//partner portal split funding end

.fx-consent-text {
  color: $disabled-gray-color;
  font-size: $font-size-h4;
  font-weight: 400;
  line-height: 1.5rem;
}

.fx-deposit-plus-success-card{
  padding: 1.75rem !important;
  &-header{
    h2{
      font-size: $font-size-h2;
      font-weight: 600;
    }
    margin-bottom: 0.5rem;
  }
  &-amount{
    .fx-currency {
      * {
        font-size: $font-size-md !important;
        font-weight: 600 !important;
      }
      &-value {
        font-size: $font-size-lg !important;
        font-weight: 600 !important;
      }
    }
  }
  &-icon{
    margin: 3rem 0rem;
  }
  &-title{
    h5{
      font-size: $font-size-h1;
      font-weight: 600;
    }
    margin-bottom: 1rem;
  }
  &-description{
    padding: 0rem 3rem;
    h5{
      font-size: $font-size-h3;
      font-weight: 400;
      color: $text-color-secondary;
      text-align: center;
    }
    margin-bottom: 1rem;
  }
}
.fx-download-doc {
  &-color {
    color: $theme-color!important;
  }
}

.fx-agreement-link-card {
  border-radius: 0.5rem;
  border: 1px solid $border-color;
  padding: 1.75rem;

  p {
    font-size: $font-size-h3 !important;
    color: $text-color;

    a {
      color: $theme-color;
    }
  }

}

//Target Balance rule css start
.fx-target-balance-modal {
  .modal-body {
    width: 40vw !important;

    @media (max-width: 600px) {
      width: 95vw !important;
    }

  }

  &-title{
    font-size: $font-size-h2 !important;
    font-weight: 500 !important;
    margin-bottom: 1rem;
  }
  &-sub-title{
    font-size: $font-size-h4;
    font-weight: 500;
    color: $text-color-secondary;
  }
  &-acc-container{
    max-height: 16rem !important;
    overflow-y: auto;  /* Enable vertical scrolling */
  }

&-divider{
    background-color: $border-color!important;
    height: 0.08rem !important;
    border: 0.008rem solid $border-color;
  }
&-sub-divider{
  margin-top: 1rem;
  svg {
    width: 3rem !important;
    height: 1rem !important;

    path {
      fill: none !important;
      stroke: $label-color-secondary !important;
    }
  }
}

.MuiDivider-root {
  background-image: linear-gradient(to left, $devider-border-color 20%, rgba(255, 255, 255, 0) 0%);
  background-position: left;
  background-size: 6px 1.5px;
  background-repeat: repeat-x;
  left: 0;
  width: 100%;
  background-color: transparent;
}

.fx-group-checkbox .Mui-checked .MuiSvgIcon-root {
  color: $theme-color !important;
}

&-tbr-header{
  padding: 0 1.5rem 1.5rem 1.5rem!important;
}
&-align-lshape{
  padding-left: 2.2rem !important;
}

&-dropdown{
  .MuiSelect-select{
    opacity: 0.5 !important;;
  }
}

&-vertical-divider{
  height: 1.0625rem !important;
  color: $text-color-secondary;
  margin: 0.33rem 0.833rem;
  width: 0.16rem;
}

}
//Target Balance rule css end