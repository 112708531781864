.fx-margin-left-10 {
  margin-left: 0.625rem;
}

.fx-margin-left-4 {
  margin-left: 0.25rem;
}

.fx-margin-top-10 {
  margin-top: 0.625rem;
}

.fx-margin-top-50 {
  margin-top: 3.125rem ;
}

.fx-margin-bottom-16 {
  margin-bottom: 1rem;
}

.fx-margin-right-32 {
  margin-right: 2rem;
}

.fx-margin-bottom-3 {
  margin-bottom: 0.187rem;
}

.fx-margin-left-12 {
  margin-left: 0.75rem;
}

.fx-padding-top-bottom-16 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.fx-padding-left-0 {
  padding-left: 0 !important;
}

.fx-padding-left-right-16 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.fx-padding-right-15 {
  padding-right: 0.94rem !important;
}

.fx-padding-right-16 {
  padding-right: 1rem;
}

.fx-margin-top-2 {
  margin-top: 2rem !important;
}

.fx-margin-bottom-2 {
  margin-bottom: 2rem !important;
}

.fx-margin-top-1 {
  margin-top: 1rem !important;
}

.fx-padding-top-0 {
  padding-top: 0 !important;
}

.fx-padding-bottom-0 {
  padding-bottom: 0 !important;
}

.fx-margin-bottom-1 {
  margin-bottom: 1rem !important;
}

.fx-margin-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.fx-width-100 {
  width: 100% !important;
}

.fx-transparent {
  background: transparent !important;
}

.fx-disabled-generic {
  background: $disabled-color !important;
}

.fx-hidden {
  display: none !important;
}

.fx-flex-grow {
  flex-grow: 1;
}

.fx-gap {
  padding: 0 0 0 0
}

.fx-no-box-shadow {
  box-shadow: none !important;
}

.fx-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fx-no-wrap {
  white-space: nowrap !important;
}

.fx-max-100 {
  max-width: 100%;
}

.error-message {
  color: $red;
  padding-top: 1px;
  font-size: 11px;
  font-weight: 600;
}

.fx-newline {
  white-space: pre-line;
}

.fx-break-word {
  display: inline-grid;
}

.fx-blur {
  pointer-events: none;
  filter: opacity(0.5);
}

.fx-small-text {
  font-size: 0.875rem !important;
}

.fx-no-shadow {
  box-shadow: none !important;
  border: none !important;
}

.fx-uppercase {
 text-transform: uppercase;
}

.fx-py-4 {
  padding: 1.5rem 0 !important;
}

.fx-pb-4 {
  padding-bottom: 1.5rem !important;
}

.fx-no-padding {
  padding: 0 !important;
}

.fx-no-bottom-padding {
  padding-bottom: 0 !important;
}

.fx-gray-background {
  background-color: rgba($light-gray-color, 0.08);
  border-radius: 6px;
  box-shadow: none !important;
}

.fx-theme-color {
  color: $theme-color !important;
}

.fx-border-gray {
  border: 1.5px solid rgba($light-gray-color, 0.08);
  border-radius: 8px;
}

.fx-cursor-pointer {
  cursor: pointer !important;
}

.fx-hover-cursor-pointer:hover {
  cursor: pointer !important;
}

.fx-padding-32 {
  padding: 2rem;
}

.fx-margin-top-reducer {
  margin-top: 2px !important;

  &-media{
    @media (max-width: 600px) {
      padding-left: 1rem;
      padding-right: 0.325rem;
    }
  }
}

.fx-margin-bottom-04 {
  margin-bottom: 0.4rem;
}


/* color classes */
.fx-color-grey {
  color: #7a86a8 !important;
}

.fx-color-blue {
  color: #4e75e2 !important;
}

.fx-color-green {
  color: #0dac0c !important;
}

.fx-color-yellow {
  color: #ffff00 !important;
}

.fx-color-maroon {
  color: #950712 !important;
}

.fx-color-red {
  color: #f12826 !important;
}

.fx-color-maroon-red {
  color: #cf323f !important;
}
.fx-color-light-orange {
  background-color: #FEFBF8 !important;
}

.fx-color-dark-yellow {
  color: #f0a71a !important;
}

.fx-color-white {
  background-color: $white !important;
}

.fx-padding-16-24 {
  padding: 1rem 1.5rem;
}

.fx-margin-top-left-16 {
  margin-top: 1rem;
  margin-left: 1rem;
}

.fx-form > div{
  padding-bottom: 2rem;
}
.fx-svg-color{
  svg{
    path{
      stroke: $theme-color;
    }
  }
}
fx-margin-top-2 {
  margin-top: 2rem !important;
}
.fx-margin-top-1 {
  margin-top: 1rem !important;
}
.fx-padding-top-0 {
  padding-top: 0 !important;
}
.fx-margin-bottom-1 {
  margin-bottom: 1rem !important;
}

.fx-padding-top-bottom-45 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.fx-padding-top-bottom-80 {
  padding-top: 6.6rem;
  padding-bottom: 6.6rem;
}

.fx-margin-left-right-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.fx-font-size{
   font-size: 1.2rem;
}