@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap");


// $font-array:["arial","Verdana","Tahoma",Open Sans,neue-montreal,neue-montreal-medium,DM Sans,Libre Franklin,Mukta,Noto Sans,Nunito,Raleway,Roboto,proximanova,proximanovamedium,AvenirNextLTPro];

  $font:    'Open Sans', sans-serif;
  $font-header : $font;

  $font-regular : $font;
  $font-bold : $font;

  $font-inter : $font;
  $font-inter-medium:$font;
  $font-inter-light:$font;
  $font-inter-semibold:$font;
  $font-inter-bold:$font;
  $font-inter-thin: $font;
  $font-inter-extra-light: $font;
  $font-inter-black: $font;

  $font-nunito : $font;

  $font-manrope:   $font;

  //font size
  $font-size-h1 :1.75rem;
  $font-size-h2 :1.5rem;
  $font-size-h3 :1.15rem; //13.8px
  $font-size-h4 :1.0rem;
  $font-size-h5 :0.875rem;
  $font-size-h6 :0.75rem;

  $font-size-xxl :3rem;
  $font-size-xl :2.5rem;
  $font-size-lg :2.33rem;
  $font-size-md :1.3rem; //15.6px
  $font-size-sm: 0.833rem;
  $font-size-xs: 0.6675rem;

html {
    font-size: 12px;
    @media (min-width: 1900px) {
      font-size:14px
    }
}
@media only screen and (max-width: 1280px) {
  html {
    font-size: 12px;
  }
}
 html[data-theme="passport"]{
    @import "orange";
    @import "main";
}
html[data-theme="mxm"]{
    @import "mxm";
    @import "main";
}
